import React, { useEffect } from "react";
import styled from "styled-components";
import media from "styles/media";
import gsap from "gsap";

const EnhancedInsights: React.FC<{}> = () => {
  useEffect(() => {
    const tl = gsap.timeline({ repeat: -1, repeatDelay: 1 });
    gsap.set([".insights__target-dot", ".insights__square-dot"], {
      opacity: 0,
    });
    gsap.set([".insights__lines"], {
      drawSVG: "0 0",
    });
    gsap.set([".insights__target", ".insights__outer-box"], {
      scale: 0.5,
      opacity: 0,
      transformOrigin: "50% 50%",
    });

    tl.to(
      [".insights__target", ".insights__outer-box"],
      { scale: 1, duration: 2 },
      0
    )
      .to(
        [".insights__target", ".insights__outer-box"],
        { opacity: 1, duration: 1, transformOrigin: "50% 50%" },
        0
      )
      .to(
        ".insights__target-dot",
        { opacity: 1, duration: 0.5, stagger: 0.1 },
        1
      )
      .to(".insights__square-dot", { opacity: 1, duration: 1 }, 1)
      .to(".insights__lines", { drawSVG: "160  0", duration: 0.7 }, 1.5)
      .to(".il1", { morphSVG: "M123.5 69L50.5 220.5", duration: 1 }, 2)
      .to(".il2", { morphSVG: "M141.5 69.5L93 208", duration: 1 }, 2)
      .to(".il3", { morphSVG: "M157 69.5L130 189.5", duration: 1 }, 2)
      .to(".il4", { morphSVG: "M174 69.5L201 190", duration: 1 }, 2)
      .to(".il5", { morphSVG: "M190.5 69L242.5 208.5", duration: 1 }, 2)
      .to(
        ".il6",
        { morphSVG: "M208 69.5C228 110 273.5 198 286 221.5", duration: 1 },
        2
      )
      .to(".sd6", { x: "-=18.5", duration: 1 }, 2)
      .to(".sd5", { x: "+=18.5", duration: 1 }, 2)
      .to(".sd4", { x: "-=22", duration: 1 }, 2)
      .to(".sd3", { x: "+=26", duration: 1 }, 2)
      .to(".sd2", { x: "-=30", duration: 1 }, 2)
      .to(".sd1", { x: "+=34", duration: 1 }, 2)
      .to(".insights__wrapper", { opacity: 0, duration: 1 }, 3);
  }, []);

  return (
    <Insights className="insights__wrapper">
      <svg
        viewBox="0 0 332 248"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="insights__outer-box"
          d="M73.8864 134.576L258.302 134.576L330.622 246.672L1.56641 246.672L73.8864 134.576Z"
          stroke="#F09C4E"
        />
        <circle
          className="insights__square-dot sd1"
          cx="252.5"
          cy="223.5"
          r="3.5"
          fill="#F09C4E"
        />
        <circle
          className="insights__square-dot sd2"
          cx="78.5"
          cy="223.5"
          r="3.5"
          fill="#F09C4E"
        />
        <circle
          className="insights__square-dot sd3"
          cx="216.5"
          cy="210.5"
          r="3.5"
          fill="#F09C4E"
        />
        <circle
          className="insights__square-dot sd4"
          cx="114.5"
          cy="210.5"
          r="3.5"
          fill="#F09C4E"
        />
        <circle
          className="insights__square-dot sd5"
          cx="183.5"
          cy="190.5"
          r="3.5"
          fill="#F09C4E"
        />
        <circle
          className="insights__square-dot sd6"
          cx="147.5"
          cy="190.5"
          r="3.5"
          fill="#F09C4E"
        />
        <path
          className="insights__lines il1"
          d="M123.5 69L78.5 223.5"
          stroke="#FEFEFE"
        />
        <path
          className="insights__lines il2"
          d="M141.5 69.5L115 211"
          stroke="#FEFEFE"
        />
        <path
          className="insights__lines il3"
          d="M157 69.5L148 191.5"
          stroke="#FEFEFE"
        />
        <path
          className="insights__lines il4"
          d="M174 69.5L183 191"
          stroke="#FEFEFE"
        />
        <path
          className="insights__lines il5"
          d="M190.5 69L217 211.5"
          stroke="#FEFEFE"
        />
        <path
          className="insights__lines il6"
          d="M208 69.5C208 70.7 238 172.333 253 223"
          stroke="#FEFEFE"
        />

        <circle
          className="insights__target-dot"
          cx="165"
          cy="39"
          r="3"
          fill="#F09C4E"
        />
        <circle
          className="insights__target-dot"
          cx="183"
          cy="32"
          r="3"
          fill="#F09C4E"
        />

        <circle
          className="insights__target-dot"
          cx="148"
          cy="32"
          r="3"
          fill="#F09C4E"
        />
        <path
          className="insights__target"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M201 1V0H200H183V0.999999L200 1V18H201V1ZM131 50V49V32H132L132 49H149V50H132H131ZM201 50H200H183V49L200 49V32H201V49V50ZM131 0H132H149V1L132 0.999999V18H131V0.999999V0Z"
          fill="#A0AFBB"
        />
        <circle
          className="insights__target-dot"
          cx="183"
          cy="19"
          r="3"
          fill="#F09C4E"
        />
        <circle
          className="insights__target-dot"
          cx="148"
          cy="19"
          r="3"
          fill="#F09C4E"
        />
        <circle
          className="insights__target-dot"
          cx="165"
          cy="12"
          r="3"
          fill="#F09C4E"
        />
      </svg>
    </Insights>
  );
};

const Insights = styled.div`
  margin-bottom: 4.9vw;
  width: 23.1vw;
  height: 17.2vw;
  svg {
    width: 100%;
    height: auto;
  }

  ${media.mobile} {
    width: 60vw;
    height: auto;
    margin-bottom: 5vw;
  }
`;

export default EnhancedInsights;
