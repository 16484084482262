import React from "react";
import styled from "styled-components";
import media from "styles/media";

const BecomeProactive: React.FC<{}> = () => {
  return (
    <BecomeProactiveImage>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 192.39 250.76">
            <path id="Path_448" style={{ fill: "none", stroke: "#FFFFFF", strokeWidth:2, strokeMiterlimit:10 }} d="M191.38,34.85L157.53,1.01H1.01
                v248.74h190.37V34.85z"/>
            <g className="overview-icon2-check">
                <path id="Path_452" style={{ fill: "#E39F5C" }} d="M38.13,78.06c-0.81-0.01-1.6-0.23-2.3-0.65L25.07,66.64
                    c-0.51-0.51-0.66-2.16,0.17-3.64c0.39-0.21,0.82-0.3,1.26-0.28c0.81,0.01,1.6,0.23,2.3,0.65l9.44,9.45l24.9-24.9
                    c0.41-0.31,0.92-0.46,1.44-0.42c0.77,0.01,1.53,0.21,2.2,0.59c0.14,0.26,0.21,0.55,0.2,0.85c-0.06,0.95-0.48,1.83-1.18,2.47
                    L39.56,77.64C39.15,77.95,38.64,78.1,38.13,78.06"/>
                <path id="Path_457" style={{ fill: "#FFFFFF" }} d="M85.62,77.63c-1.11-0.18-1.99-1.05-2.17-2.17c0.18-1.12,1.06-1.99,2.17-2.17h68.53
                    c1.12,0.18,1.99,1.06,2.17,2.17c-0.18,1.11-1.06,1.99-2.17,2.17H85.62z"/>
                <path id="Path_449" style={{ fill: "#FFFFFF" }} d="M85.62,56.47c-1.11-0.18-1.99-1.05-2.17-2.16c-0.1-1.1,0.7-2.07,1.8-2.17
                    c0.13-0.01,0.25-0.01,0.38,0h32.15c1.11,0.18,1.99,1.06,2.17,2.17c-0.18,1.11-1.05,1.98-2.17,2.16H85.62z"/>
            </g>
            <g className="overview-icon2-check">
                <path id="Path_451" style={{ fill: "#E39F5C" }} d="M38.13,145.74c-0.81-0.01-1.6-0.23-2.3-0.65l-10.77-10.77
                    c-0.54-0.54-0.67-2.39,0.36-3.95c0.11-0.17,0.2-0.31,0.67-0.31c0.96,0.09,1.89,0.42,2.68,0.97l9.45,9.46l24.9-24.9
                    c0.41-0.31,0.92-0.46,1.44-0.42c0.69,0.01,1.38,0.17,2,0.49c0.33,0.63,0.46,1.34,0.36,2.04c-0.07,0.42-0.32,0.79-0.68,1.02
                    L66,118.89l-26.43,26.44C39.16,145.64,38.65,145.79,38.13,145.74"/>
                <path id="Path_455" style={{ fill: "#FFFFFF" }} d="M85.62,146.16c-1.2,0-2.17-0.97-2.17-2.17c0-1.2,0.97-2.17,2.17-2.17c0,0,0,0,0,0
                    h68.53c1.2,0,2.17,0.97,2.17,2.17c0,1.2-0.97,2.17-2.17,2.17c0,0,0,0,0,0H85.62z"/>
                <path id="Path_456" style={{ fill: "#FFFFFF" }} d="M83.77,124.33c-0.3,0-1.17-0.8-1.17-2.33c0.18-1.11,1.05-1.99,2.16-2.17h32.16
                    c1.2,0,2.17,0.97,2.17,2.17c0,1.2-0.97,2.17-2.17,2.17H84.29l-0.45,0.15L83.77,124.33z"/>
            </g>
            <g className="overview-icon2-check">
                <path id="Path_450" style={{ fill: "#E39F5C" }} d="M38.11,214.69c-0.33-0.02-0.49-0.13-1.19-0.83l-11.01-11
                    c-0.55-0.55-0.68-2.37,0.37-3.95c0.1-0.16,0.2-0.3,0.67-0.3c0.96,0.09,1.89,0.42,2.69,0.97l9.45,9.46l24.9-24.9
                    c0.41-0.31,0.92-0.46,1.43-0.42c0.56,0.01,1.11,0.11,1.63,0.32c0.2,1.17,0.15,2.6-0.81,3.24l-0.24,0.16l-26.43,26.43
                    C39.13,214.22,38.64,214.51,38.11,214.69"/>                
                <path id="Path_453" style={{ fill: "#FFFFFF" }} d="M85.62,214.69c-1.2,0-2.17-0.97-2.17-2.17c0-1.2,0.97-2.17,2.17-2.17c0,0,0,0,0,0
                    h68.53c1.2,0,2.17,0.97,2.17,2.17c0,1.2-0.97,2.17-2.17,2.17c0,0,0,0,0,0H85.62z"/>
                <path id="Path_454" style={{ fill: "#FFFFFF" }} d="M84.77,193.54c-1.2,0-2.17-0.97-2.17-2.17c0-1.2,0.97-2.17,2.17-2.17h32.16
                    c1.2,0,2.17,0.97,2.17,2.17c0,1.2-0.97,2.17-2.17,2.17H84.77z"/>
            </g>
        </svg>
    </BecomeProactiveImage>
  );
};

const BecomeProactiveImage = styled.div`
    margin-bottom: 3.2vw;
    width: 9.1vw;
    height: 11.8vw;
    svg {
        width: 100%;
        height: auto;
    }

    ${media.mobile} {
        width: 25vw;
        height: auto;
        margin-bottom: 5vw;
    }
`;

export default BecomeProactive;
