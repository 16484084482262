import React, { useEffect, useState } from "react";
import delay from "lodash.delay";
import styled from "styled-components";
import Colors from "styles/Colors";
import media from "styles/media";
import HeadingText from "components/HeadingText";
import SectionAccent from "components/SectionAccent";
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  BodyCopy1,
  mobile,
} from "components/Text";
import { ButtonLargeExternal } from "components/Buttons";
import { ReactComponent as MapFadedSVG } from "assets/svg/MapFaded.svg";
import WhiteArrow from "assets/svg/WhiteArrow.svg";

const Company: React.FC<{}> = () => {
  const [currentArea, setCurrentArea] = useState(0);

  useEffect(() => {
    let timer = delay(() => {
      setCurrentArea((currentArea + 1) % 4);
    }, 1500);
    return () => clearTimeout(timer);
  }, [currentArea]);

  return (
    <Wrapper id="company">
      <MapFaded />
      <SectionAccent fill={Colors.cadetGray60}>Company</SectionAccent>
      <HeadingOne>
        <HeadingText
          headingColor={Colors.boneLight}
          headingSize={0}
          headingText={"Our Mission"}
        />
      </HeadingOne>
      <HeadingFour>
      Delivering actionable intelligence on household assets with shockingly low friction. We know data is valuable, but the real magic happens for our clients because we make it incredibly easy to access and to use.
      </HeadingFour>
      <StartedIn>
        <HeadingThree>Started in</HeadingThree>
        <HeadingOneAlt>Louisville, Kentucky</HeadingOneAlt>
      </StartedIn>
      <HeadingTwo>Working for Source7</HeadingTwo>
      <BodyCopy>
        Getting product information shouldn’t be hard. We’re on a mission to
        provide real time actionable product information to our customers and
        partners of all fields. At Source7, we are not just driven by a shared
        set of values and principles but real, tangible attributes that drive
        how we work together and how we show up for our customers and partners.
        We believe it’s about you being more successful – we’re just here to
        help.
      </BodyCopy>
      <LookingHeading>
        We are always looking for talented individuals to help us revolutionize
        <LookingArea visible={currentArea === 0}> insurance</LookingArea>
        <LookingArea visible={currentArea === 1}> real estate</LookingArea>
        <LookingArea visible={currentArea === 2}>
          {" "}
          property management{" "}
        </LookingArea>
        <LookingArea visible={currentArea === 3}> home warranties</LookingArea>
      </LookingHeading>

      <Button
        // target="_blank"
        // rel="noopener noreferrer"
        visible={0}
        href="https://jobs.source7.com"
      >
        View Job Opportunities
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: ${Colors.cadetGrayDark};
  padding: 6.7vw 3.5vw 25vw 24.4vw;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  > * {
    position: relative;
  }

  ${media.mobile} {
    padding: 15vw 2vw 0vw 23vw;
  }
`;

const HeadingOne = styled.div`
  color: ${Colors.boneLight};

  ${media.mobile} {
    margin-top: 15vw;
  }
`;

const HeadingOneAlt = styled(Heading1)`
  color: ${Colors.boneLight};

  ${media.mobile} {
    ${mobile.H4};
    margin-top: 1vw;
  }
`;

const HeadingTwo = styled(Heading2)`
  color: ${Colors.boneLight};
  display: inline-block;
  margin-right: 1.5vw;
  vertical-align: top;
  width: 30vw;

  ${media.mobile} {
    width: 90%;
    margin-right: 0vw;
    margin-bottom: 5vw;
  }
`;

const HeadingThree = styled(Heading3)`
  color: ${Colors.boneLight};
  margin-bottom: 1vw;
`;

const HeadingFour = styled(Heading4)`
  color: ${Colors.bone100};
  margin: 2.4vw 0 9.9vw;
  width: 41.7vw;

  ${media.mobile} {
    width: 90%;
    margin-top: 6vw;
    margin-bottom: 15vw;
  }
`;

const BodyCopy = styled(BodyCopy1)`
  color: ${Colors.bone100};
  display: inline-block;
  width: 30vw;

  ${media.mobile} {
    width: 85%;
  }
`;

const Button = styled(ButtonLargeExternal)`
  color: ${Colors.cadetGray60};
  display: block;
  margin-left: auto;
  padding-bottom: 24.5px;
  width: 16.7vw;
  ::before {
    background: repeating-linear-gradient(
      to right,
      ${Colors.bone100},
      ${Colors.bone100} 33%,
      transparent 33%,
      transparent 67%,
      ${Colors.bone100} 67%,
      ${Colors.bone100} 100%
    );
    background-size: 300% 100%;
  }
  ::after {
    background: url(${WhiteArrow}) center/contain no-repeat;
  }
  ${media.tablet} {
    font-size: 2.2vw;
    width: 24.4vw;

    ::after {
      width: 1.4vw;
      height: 1.4vw;
    }
  }

  ${media.mobile} {
    width: 44vw;
    font-size: 4vw;
    padding-bottom: 2vw;
    margin-bottom: 20vw;

    ::after {
      width: 3vw;
      height: 3vw;
      top: 0vw;
      right: 0vw;
    }
  }
`;

const MapFaded = styled(MapFadedSVG)`
  position: absolute;
  top: 2.5vw;
  right: 0;
  width: 100%;
  height: 101vw;

  ${media.mobile} {
    height: auto;
    width: 94vw;
    top: 65vw;
    right: -1vw;
  }
`;

const StartedIn = styled.div`
  margin-left: 10.6vw;
  margin-bottom: 32.8vw;

  ${media.mobile} {
    margin-left: 20vw;
    margin-bottom: 40vw;

    ${HeadingOne} {
      margin-top: 0vw;
      font-size: 5.5vw;
    }

    ${HeadingThree} {
      font-size: 5vw;
      margin-bottom: 2vw;
    }
  }
`;

const LookingHeading = styled(Heading1)`
  margin-top: 11.9vw;
  margin-bottom: 9.6vw;
  width: 61.7vw;
  color: ${Colors.boneLight};
  ${media.mobile} {
    width: 90%;
    font-size: 7.5vw;
    margin-bottom: 20vw;
    margin-top: 20vw;
  }
`;

const LookingArea = styled.span<{ visible: boolean }>`
  ${media.mobile} {
    font-size: 7.5vw;
    top: unset;
    bottom: -8vw;
    left: 23vw;
    width: 80vw;
    left: 0;
  }

  font: 5.6vw Roobert;
  line-height: 110%;
  letter-spacing: -0.02em;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  color: ${Colors.industryOrange100};
  position: absolute;
  top: 19vw;
  left: 0;
  transition: opacity 500ms ease;
  transition-delay: ${(props) => (props.visible ? "500ms" : "0")};
`;

export default Company;
