import React, {useEffect, useRef, useContext} from 'react'
import { MobileContext } from 'App'
import styled from 'styled-components'
import Colors from 'styles/Colors'
import gsap from 'gsap'
import media from 'styles/media'
import {Heading1, BodyCopy1} from 'components/Text'
import { ReactComponent as LogoSVG } from 'assets/svg/Logo.svg'

const SuccessView: React.FC<{visible: boolean}> = ({visible}) => {
  const wrapperRef = useRef(null)
  const mobile = useContext(MobileContext)

  useEffect(() => {
    if (visible) {
      gsap.to(wrapperRef.current, {
        duration: 0,
        zIndex: 998,
        opacity: 1
      })
    } else {
      gsap.to(wrapperRef.current, {
        duration: 1,
        zIndex: -1,
        opacity: 0
      })
    }
  }, [visible])

  return (
    <Wrapper ref={wrapperRef}>
      <BorderContainer>
        {mobile && <Logo/>}
        <Heading>You won't regret it, we promise!</Heading>
        <Text>We will be in touch soon!</Text>
      </BorderContainer>
    </Wrapper>
  )
}

export default SuccessView

const Wrapper = styled.div`
  background-color: ${Colors.gunMetal100};
  position: fixed;
  top: 0vw;
  right: 0vw;
  height: 100vh;
  width: calc(100vw - 12vw);
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4vw 4vw 8vw 10vw;
  box-sizing: border-box;
  opacity: 0;
  right: 0vw;

  ${media.tablet} {
    width: 90vw;
    padding-top: 10vw;
  }

  ${media.mobile} {
    width: calc(100vw - 18vw);
    overflow-y: scroll;
    padding-left: 5vw;
  }
`

const BorderContainer = styled.div`
  width: 100%;
  height: 100%;
  border-top: 1px solid ${Colors.gunMetal80};
  display: flex;
  flex-direction: column;
  padding-top: 5vw;
  box-sizing: border-box;

  ${media.tablet} {
    flex-direction: column;
    justify-content: unset;
    border-top: none;
    padding-bottom: 5vw;
    padding-top: 0vw;
  }

  ${media.mobile} {
    flex-direction: column;
    border-top: none;
  }
`

const Heading = styled(Heading1)`
  color: ${Colors.bone100};
  font-size: 5vw;
  margin-bottom: 3vw;
  width: 50%;

  ${media.tablet} {
    font-size: 6vw;
    width: 56%;
  }

  ${media.mobile} {
    width: 80%;
    font-size: 10vw;
  }
`

const Text = styled(BodyCopy1)`
  color: ${Colors.bone100};
  width: 60%;
  font-size: 1.3vw;

  ${media.tablet} {
    font-size: 2.6vw;
  }

  ${media.mobile} {
    font-size: 4vw;
    width: 100%;
    font-weight: 100;
  }
`

const Logo = styled(LogoSVG)`
  overflow: unset;
  width: 33vw;
  height: 12vw;
  margin-bottom: 40vw;
  
`