import React from "react";
import styled from "styled-components";
import media from "styles/media";

const BecomeProactive: React.FC<{}> = () => {
  return (
    <BecomeProactiveImage>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 192.39 239.03">
            <path className="overview-icon3-shield" id="Path_48" style={{ fill: "#FFFFFF" }} d="M165.32,2.19c1.07,13.29,11.6,23.84,24.88,24.93v122.55
                c0.05,18.86-9.6,36.42-25.55,46.49l-60.27,38.29c-5,3.17-11.38,3.17-16.37,0l-60.27-38.29c-15.95-10.07-25.6-27.63-25.55-46.49
                V27.12c13.28-1.09,23.82-11.65,24.88-24.93H165.32z M167.42,0H24.97v0.05c0,13.79-11.18,24.97-24.97,24.97c0,0,0,0,0,0v124.65
                c0,19.6,10.02,37.83,26.56,48.34l60.27,38.29c5.71,3.63,13.01,3.63,18.72,0l60.27-38.29c16.54-10.51,26.56-28.75,26.56-48.34V25.02
                c-13.79,0-24.97-11.18-24.97-24.97V0z"/>
            <g className="overview-icon3-symbol">
                <path id="Path_49" style={{ fill: "#E59F5D" }} d="M49.03,153.17c-2.83,0.02-5.45-1.5-6.85-3.95c-1.43-2.44-1.43-5.46,0-7.9L91.6,55.73
                    c0.35-0.6,0.77-1.15,1.25-1.64c1.11-1.12,2.53-1.87,4.08-2.17c0.17-0.03,0.35-0.06,0.53-0.08c0.33-0.04,0.66-0.06,0.99-0.06
                    c2.83-0.02,5.45,1.49,6.85,3.95l49.41,85.58c1.43,2.44,1.43,5.46,0,7.9c-1.4,2.46-4.02,3.97-6.85,3.95L49.03,153.17z M98.44,54.36
                    c-1.9-0.01-3.67,1.01-4.61,2.66l-49.41,85.59c-0.96,1.64-0.96,3.68,0,5.32c0.94,1.65,2.7,2.67,4.61,2.66h98.82
                    c1.9,0.01,3.67-1.01,4.61-2.66c0.96-1.64,0.96-3.68,0-5.32l-49.41-85.59C102.11,55.37,100.35,54.35,98.44,54.36"/>
                <path id="Path_50" style={{ fill: "#E59F5D" }} d="M147.85,152.54H49.03c-4.01,0.02-7.27-3.22-7.29-7.23c-0.01-1.29,0.34-2.57,0.99-3.68
                    l49.41-85.58c0.32-0.55,0.7-1.06,1.15-1.51l0.01-0.01l0.03-0.03c1.01-1.01,2.31-1.69,3.71-1.95c0.16-0.03,0.32-0.06,0.48-0.08
                    c0.3-0.04,0.61-0.06,0.91-0.06c2.6-0.02,5.01,1.38,6.3,3.64l49.41,85.58c2.01,3.48,0.82,7.93-2.66,9.94
                    C150.39,152.2,149.13,152.54,147.85,152.54 M43.07,145.26c0.01,3.29,2.68,5.94,5.96,5.95h98.82c3.28,0.01,5.95-2.64,5.96-5.91
                    c0-1.06-0.27-2.1-0.81-3.01L103.6,56.7c-1.64-2.85-5.28-3.82-8.13-2.18c-0.9,0.52-1.66,1.27-2.18,2.18l-49.41,85.58
                    C43.35,143.19,43.07,144.22,43.07,145.26"/>
                <path id="Path_51" style={{ fill: "#FFFFFF" }} d="M98.4,137.01c-2.74-0.01-4.96-2.24-4.94-4.98c0.01-2.74,2.24-4.96,4.98-4.94
                    c2.74,0.01,4.96,2.24,4.94,4.98c-0.01,2.73-2.22,4.93-4.94,4.94H98.4z M98.44,129.76c-1.29-0.01-2.34,1.04-2.35,2.32
                    c-0.01,1.29,1.04,2.34,2.33,2.35c0.62,0,1.21-0.24,1.66-0.67c0.92-0.91,0.93-2.38,0.02-3.3c-0.43-0.44-1.02-0.69-1.64-0.7
                    l-0.02-0.63L98.44,129.76z"/>
                <path id="Path_52" style={{ fill: "#FFFFFF" }} d="M98.44,136.38H98.4c-2.37-0.04-4.25-2-4.21-4.37c0.04-2.32,1.92-4.18,4.24-4.21h0.04
                    c2.37,0.04,4.25,2,4.21,4.37C102.64,134.5,100.76,136.36,98.44,136.38"/>
                <path id="Rectangle_23" style={{ fill: "#FFFFFF" }} d="M98.44,78.52L98.44,78.52c1.82,0,3.29,1.47,3.29,3.29v35.86
                    c0,1.82-1.47,3.29-3.29,3.29l0,0c-1.82,0-3.29-1.47-3.29-3.29V81.82C95.15,80,96.62,78.52,98.44,78.52z"/>
            </g>
        </svg>
    </BecomeProactiveImage>
  );
};

const BecomeProactiveImage = styled.div`
    margin-bottom: 3.1vw;
    width: 9.1vw;
    height: 11.4vw;
    svg {
        width: 100%;
        height: auto;
    }

    ${media.mobile} {
        width: 25vw;
        height: auto;
        margin-bottom: 5vw;
    }
`;

export default BecomeProactive;
