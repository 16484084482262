import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Subtitle1, BodyCopy2 } from "components/Text";
import { ReactComponent as LogoSVG } from "assets/svg/Logo.svg";
import { ReactComponent as Linkedin } from "assets/svg/Linkedin.svg";
import Colors from "styles/Colors";
import media from "styles/media";
import { MobileContext } from "App";

const Footer: React.FC<{}> = () => {
  const mobile = useContext(MobileContext);
  const location = useLocation();
  const is_landing = location.pathname.includes('-lp');

  function getYear() {
    return new Date().getFullYear();
  }

  return (
    <Wrapper className={(is_landing ? 'landing-page' : '')}>
      <a href="/">
        <Logo />
      </a>
      <Copy>&copy; { getYear() } Source 7, Inc.</Copy>
      <SocialContainer>
        <a
          href="https://www.linkedin.com/company/source7/"
          aria-label="Linkedin"
          target="__blank"
          rel="noopener noreferrer"
        >
          <Linkedin />
        </a>
      </SocialContainer>
      <LinksContainer>
        {!is_landing && (<Link to="/find-product-label">
          <Subtitle>Find Product Label</Subtitle>
        </Link>)}
        <MobileWrap>
          <Link to="/terms-of-use">
            <Subtitle>{mobile ? "Terms of Use" : "Terms"}</Subtitle>
          </Link>
          <Link to="/privacy">
            <Subtitle>{mobile ? "Privacy Policy" : "Privacy"}</Subtitle>
          </Link>
        </MobileWrap>
      </LinksContainer>
    </Wrapper>
  );
};

const Wrapper = styled.footer`
  background: ${Colors.gunMetalDark};
  display: flex;
  align-items: center;
  height: 75px;
  padding: 2vw 2vw 2vw 25vw;
  box-sizing: border-box;
  position: relative;

  &.landing-page {
    padding-left: 2vw;
  }
  
  ${media.mobile} {
    width: 100%;
    height: 60vw;
    padding: 10vw 0 10vw 18.9vw;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const Copy = styled(BodyCopy2)`
  color: ${Colors.gunMetal40};
  font-weight: 300;
  margin-left: 18px;
  margin-right: auto;
  ${media.tablet} {
    font-size: 1.6vw;
  }
  ${media.mobile} {
    order: 3;
    margin-left: 0;
    font-size: 2.9vw;
  }
`;

const Subtitle = styled(Subtitle1)`
  color: ${Colors.gunMetal40};
  margin-left: 30px;
  ${media.tablet} {
    font-size: 1.6vw;
  }
  ${media.mobile} {
    margin-left: 0;
    font-size: 2.9vw;
    line-height: 100%;
  }
`;

const Logo = styled(LogoSVG)`
  width: 114px;
  height: 33px;

  ${media.tablet} {
    width: 11.1vw;
    height: 3.2vw;
  }
  ${media.mobile} {
    width: 32vw;
    height: 9.3vw;
    position: absolute;
    top: 9vw;
    left: 18.9vw;

    .landing-page & {
      left: auto;
      right: 5vw;
    }
  }
`;

const SocialContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
  }

  ${media.mobile} {
    margin-bottom: 4vw;
    margin-top: 12.3vw;
    order: 1;

    svg {
      cursor: pointer;
      width: 5.3vw;
      height: 5.3vw;
    }
  }
`;

const MobileWrap = styled.div`
  display: flex;
  ${media.mobile} {
    width: 39vw;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;

  a {
    cursor: pointer;
  }

  ${media.mobile} {
    margin-bottom: 4vw;
    width: 48vw;
    order: 2;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 12vw;
  }
`;

export default Footer;
