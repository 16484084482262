const Colors = {
  gunMetal100: "#1A1D24",
  gunMetal80: "#2B303B",
  gunMetal60: "#454C5F",
  gunMetal40: "#5E6982",
  gunMetal20: "#7D88A1",
  gunMetalDark: "#0A0C11", // -20 in figma

  intelligenceBG: "#27292D",

  industryOrange100: "#F09C4E",
  industryOrange80: "#F4B67C",
  industryOrange60: "#F7CBA1",
  industryOrange40: "#FAE0C7",
  industryOrange20: "#FDF5EC",
  industryOrangeDark: "#E17714", // -20 in figma

  cadetGray100: "#A0AFBB",
  cadetGray80: "#B7C3CC",
  cadetGray60: "#CFD7DD",
  cadetGray40: "#DBE1E6",
  cadetGray20: "#F3F5F7",
  cadetGrayDark: "#667C8F", // -20 in figma

  mauve100: "#AA97A7",
  mauve80: "#BFB0BD",
  mauve60: "#D1C7D0",
  mauve40: "#DAD2D9",
  mauve20: "#EDE9EC",
  mauveDark: "#7B6578", // -20 in figma

  winterGreen100: "#789A8E",
  winterGreen80: "#96B0A7",
  winterGreen60: "#AEC2BB",
  winterGreen40: "#C5D3CE",
  winterGreen20: "#DCE5E2",
  winterGreenDark: "#4F6960", // -20 in figma

  slateBlue100: "#5598B4",
  slateBlue80: "#71A9C1",
  slateBlue60: "#8EBACD",
  slateBlue40: "#9CC3D3",
  slateBlue20: "#B9D4DF",
  slateBlueDark: "#2D5362", // -20 in figma

  bone100: "#F5F4F5",
  bone80: "#ECEAEC",
  bone60: "#E2DFE2",
  bone40: "#D8D4D8",
  bone20: "#CFC9CF",
  boneLight: "#FEFEFE", // -20 in figma
};

export default Colors;

 
