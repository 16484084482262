import React, { useContext, useRef } from "react";
import { DesktopContext } from "App";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

import { RouteComponentProps, withRouter } from "react-router";
import { useLocation } from "react-router-dom";

const Header: React.FC<RouteComponentProps & { selectNum: number }> = ({
  history,
  selectNum,
}) => {
  const desktop = useContext(DesktopContext);
  const location = useLocation();
  const is_landing = location.pathname.includes('-lp');

  const selectRef = useRef(null);

  if (!is_landing) {
    if (desktop) {
      return (
        <Desktop selectNum={selectNum} history={history} selectRef={selectRef} />
      );
    } else {
      return (
        <Mobile selectNum={selectNum} history={history} selectRef={selectRef} />
      );
    }
  } else {
    return (null);
  }
};

export default withRouter(Header);
