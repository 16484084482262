import React, { useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import Colors from "styles/Colors";
import media from "styles/media";
import HeadingText from "components/HeadingText";
import SectionAccent from "components/SectionAccent";
import BecomeProactive from "./overview-animations/BecomeProactive";
import DataDrivenDecisions from "./overview-animations/DataDrivenDecisions";
import ReduceRisks from "./overview-animations/ReduceRisks";
import AddInsightsSeamlessly from "./overview-animations/AddInsightsSeamlessly";
import { Heading5, BodyCopy1 } from "components/Text";

const Overview: React.FC<{}> = () => {
  useEffect(() => {
    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".overview-section1",
        start: "top 70%",
      },
    });

    tl1.from([".overview-section1"], { opacity: 0, transform: "translateY(40px)", duration: 0.75 }, 0)
      .to([".overview-icon1-dot1 [style*='13,']"], { fill: "rgb(228, 160, 93)", duration: 0.3 }, 0.5)
      .to([".overview-icon1-dot1 [style*='13,']"], { fill: "rgb(13, 15, 20)", duration: 0.3 }, 0.7)
      .to([".overview-icon1-dot2 [style*='13,']"], { fill: "rgb(228, 160, 93)", duration: 0.3 }, 0.9)
      .to([".overview-icon1-dot2 [style*='13,']"], { fill: "rgb(13, 15, 20)", duration: 0.3 }, 1.1)
      .to([".overview-icon1-dot3 [style*='13,']"], { fill: "rgb(228, 160, 93)", duration: 0.3 }, 1.3)
      .to([".overview-icon1-dot3 [style*='13,']"], { fill: "rgb(13, 15, 20)", duration: 0.3 }, 1.5)
      .to([".overview-icon1-dot4 [style*='13,']"], { fill: "rgb(228, 160, 93)", duration: 0.3 }, 1.7)
      .to([".overview-icon1-dot4 [style*='13,']"], { fill: "rgb(13, 15, 20)", duration: 0.3 }, 1.9)
      .to([".overview-icon1-dot2 [style*='13,']"], { fill: "rgb(228, 160, 93)", duration: 0.3 }, 2.1);

    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".overview-section2",
        start: "top 70%",
      },
    });

    tl2.from([".overview-section2"], { opacity: 0, transform: "translateY(40px)", duration: 0.75 }, 0)
      .from([".overview-icon2-check"], { opacity: 0, x: -40, duration: 0.5, stagger: 0.3}, 0.5);

    const tl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".overview-section3",
        start: "top 70%",
      },
    });

    tl3.from([".overview-section3"], { opacity: 0, transform: "translateY(40px)", duration: 0.75 }, 0)
      .from([".overview-icon3-symbol"], { transformOrigin: "50% 50%", scale: 1.5, duration: 0.5 }, 0.5)
      .from([".overview-icon3-shield"], { opacity: 0, duration: 0.5 }, 0.9);

    const tl4 = gsap.timeline({
      scrollTrigger: {
        trigger: ".overview-section4",
        start: "top 70%",
      },
    });

    tl4.from([".overview-section4"], { opacity: 0, transform: "translateY(40px)", duration: 0.75 }, 0)
      .from([".overview-icon4-filament"], { opacity: 0, duration: 0.5}, 0.5)
      .from([".overview-icon4-radiate1 path"], { opacity: 0, duration: 0.5, stagger: 0.03}, 0.8)
      .from([".overview-icon4-radiate2 path"], { opacity: 0, duration: 0.5, stagger: 0.03}, 1.1);
  }, []);

  return (
    <Wrapper id="overview">
      <SectionAccent fill={Colors.gunMetal60}>Overview</SectionAccent>
      <Header>
        <HeadingText
          headingColor={Colors.bone100}
          headingSize={3}
          headingText={
            "For the first time, you have actionable, real-time home asset insights you can use to plan, assess risks, and make the best day-to-day decisions for your business."
          }
        />
      </Header>
      <DataDrivenSection className="overview-section1">
        <DataDrivenDecisions />
        <OrangeHeader>Data-Driven Decisions</OrangeHeader>
        <BodyCopy>
          Leverage real-time actionable asset 
          intelligence to take the guesswork out of 
          decisions and drive optimal outcomes for 
          your business.
        </BodyCopy>
      </DataDrivenSection>
      <ProactiveSection className="overview-section2">
        <BecomeProactive />
        <OrangeHeader>Become Proactive</OrangeHeader>
        <BodyCopy>
          Identify and address issues on key assets 
          before they become more expensive 
          problems, while also improving the 
          resident experience.
        </BodyCopy>
      </ProactiveSection>
      <ReduceRisksSection className="overview-section3">
        <ReduceRisks />
        <OrangeHeader>Reduce Risks</OrangeHeader>
        <BodyCopy>
          Gain visibility into the health of assets 
          within a property to accurately determine 
          its risk profile and mitigate any threats 
          the assets pose to the profitability of 
          your business.
        </BodyCopy>
      </ReduceRisksSection>
      <AddInsightsSection className="overview-section4">
        <AddInsightsSeamlessly />
        <OrangeHeader>Add Insights Seamlessly</OrangeHeader>
        <BodyCopy>
          Leverage real-time asset intelligence within 
          your existing applications and workflows – 
          no disruptions, just intelligence.
        </BodyCopy>
      </AddInsightsSection>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: ${Colors.gunMetalDark};
  height: 120vw;
  padding: 6.9vw 3.5vw 7.6vw 21.4vw;
  position: relative;
  text-align: left;
  box-sizing: border-box;
  overflow: hidden;

  ${media.tablet} {
    padding: 6.9vw 3.5vw 7.6vw 18vw;
  }

  ${media.mobile} {
    width: 100%;
    height: auto;
    padding: 15vw 2vw 0vw 23vw;
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.div`
  color: ${Colors.bone100};
  position: relative;
  width: 54.7vw;

  ${media.mobile} {
    width: 75%;
    margin-bottom: 15vw;
  }
`;

const SubSection = styled.div`
  position: absolute;

  ${media.mobile} {
    margin-bottom: 15vw;
    position: relative;
  }
`;

const OrangeHeader = styled(Heading5)`
  color: ${Colors.industryOrange100};
  margin-bottom: 1vw;

  ${media.mobile} {
    margin-bottom: 4vw;
  }
`;

const BodyCopy = styled(BodyCopy1)`
  color: ${Colors.bone100};
`;

const DataDrivenSection = styled(SubSection)`
  width: 23.6vw;
  left: 24.4vw;
  top: 26.6vw;

  ${media.tablet} {
    left: 18vw;
  }

  ${media.mobile} {
    width: 90%;
    left: 0vw;
    left: unset;
    top: 0vw;
  }
`;

const ProactiveSection = styled(SubSection)`
  width: 25.1vw;
  left: 57.4vw;
  top: 43.6vw;

  ${media.tablet} {
    left: 52.4vw;
  }

  ${media.mobile} {
    width: 90%;
    left: 0vw;
    top: 0vw;
  }
`;

const ReduceRisksSection = styled(SubSection)`
  width: 23.7vw;
  left: 24.4vw;
  top: 62.5vw;

  ${media.tablet} {
    left: 18vw;
  }

  ${media.mobile} {
    width: 90%;
    left: 0vw;
    top: 0vw;
  }
`;

const AddInsightsSection = styled(SubSection)`
  width: 23.6vw;
  left: 57.4vw;
  top: 81.9vw;

  ${media.tablet} {
    left: 52.4vw;
  }

  ${media.mobile} {
    width: 90%;
    left: 0vw;
    top: 0vw;
  }
`;

export default Overview;
