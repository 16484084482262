import React, { useEffect, useState } from "react";
import styled from "styled-components";
import gsap from "gsap";
import HeadingText from "components/HeadingText";
import { Heading3, BodyCopy1 } from "components/Text";
import Colors from "styles/Colors";
import media from "styles/media";
import { ReactComponent as StorySVG1 } from "assets/svg/Overview/overview-story1.svg";
import { ReactComponent as StorySVG2 } from "assets/svg/Overview/overview-story2.svg";
import { ReactComponent as StorySVG3 } from "assets/svg/Overview/overview-story3.svg";
import { ReactComponent as StorySVG4 } from "assets/svg/Overview/overview-story4.svg";

const Risk: React.FC<{}> = () => {
  const [animated, setAnimated] = useState(false);

  useEffect(() => {
    console.log('risk useEffect triggered', animated);

    if (!animated) {
      setAnimated(true);

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".risk__sticky",
          start: "top 40%",
        },
        repeat: -1,
        repeatDelay: 1,
      });

      const lastImageTL = gsap.timeline({
        scrollTrigger: {
          trigger: ".risk__sticky",
          start: "top 40%"
        },      
        repeat: -1
      });

      lastImageTL.to([".overview-story4-dottedCircle"],{rotate:360, transformOrigin:"50% 50%", duration:48, ease: "linear"}, 0);

      tl.set([".overview-story"],{ autoAlpha: 0 })      
        .set([".overview-story1-chevronL, .overview-story1-chevronR"],{x:0,y:0})
        .set([".overview-story2-topline, .overview-story2-botline"],{clearProps: "transform"})

        .to([".overview-story1-chevronL"],{x:"-470%", y:"-330%", duration:1}, 0.5)
        .to([".overview-story1-chevronR"],{x:"460%", y:"340%", duration:1}, 0.5)
        .to([".overview-story1-phone"],{autoAlpha: 0, duration:0.8}, 0.7)
        .to([".overview-story2"],{autoAlpha:1, scale:1, duration:1}, 0.7)
        .to([".overview-story2-container"],{transformOrigin:"50% 38%", scaleY:1.13, duration:1}, 3)
        .to([".overview-story2-topitems, .overview-story2-bottomitems"],{autoAlpha:0, duration:0.5}, 3)
        .to([".overview-story2-topline"],{transform: "translateY(-12%)", duration:0.5}, 3)
        .to([".overview-story2-botline"],{transform: "translateY(35%)", duration:0.5}, 3)
        .to([".overview-story1-chevronL"],{y:"-360%", duration:1}, 3)
        .to([".overview-story1-chevronR"],{y:"390%", duration:1}, 3)
        .to([".overview-story3"],{autoAlpha:1, duration:1}, 3.5)
        .to([".overview-story2"],{autoAlpha:0, duration:0.2}, 3.5)
        .to([".overview-story1-chevronL"],{x:"-570%", y:"-460%", autoAlpha:0, duration:1}, 5.5)
        .to([".overview-story1-chevronR"],{x:"560%", y:"490%", autoAlpha:0, duration:1}, 5.5)
        .to([".overview-story3"],{autoAlpha:0, scale:1.13, duration:1}, 5.5)
        .to([".overview-story4"],{autoAlpha:1, duration:1}, 6)
        .to([".overview-story4-inner"],{scale: 0.95, transformOrigin: "50% 52%", duration:1}, 6)
        .to([".overview-story4-outer"],{scale: 1, transformOrigin: "50% 50%", duration:1}, 6)
        .to([".overview-story4"],{autoAlpha:0, duration:1}, 10)
        .to([".overview-story1-phone"],{autoAlpha: 1, duration:0.8}, 11)
        .set([".overview-story1-chevronL, .overview-story1-chevronR"],{x:0,y:0}, 11)
        .to([".overview-story1-chevronL, .overview-story1-chevronR"],{autoAlpha: 1}, 11.5);
    }
  },[animated]);

  return (
    <Wrapper id="risk"> 
      <Heading>
        <HeadingText
          headingColor={Colors.gunMetal100}
          headingSize={1}
          headingText={"Gain insights never before possible to see within your portfolio and beyond to get ahead of issues, reduce risks, and seize opportunities."}
        />
      </Heading>
      <Container className="risk__sticky">
        <AnimWrap>
          <StorySVG1 className="overview-story1" />
          <StorySVG2 className="overview-story overview-story2" />
          <StorySVG3 className="overview-story overview-story3" />
          <StorySVG4 className="overview-story overview-story4" />
        </AnimWrap>
        <TextWrapper>
          <Wrap1 className="risk__wrap1">
            <SubHeading>Gaining Insights is Now Easy</SubHeading>
            <BodyCopy>
              Converting the unstructured data on an appliance 
              label into insights that can be integrated into your 
              systems is as easy as taking a picture. Source7 turns 
              your photos into actionable asset intelligence you 
              can immediately use within your applications and 
              workflows to improve decision-making across your 
              business.
            </BodyCopy>
          </Wrap1>
        </TextWrapper>
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${Colors.bone100};
  padding: 7.6vw 3.6vw 3.9vw 21.4vw;
  text-align: left;
  box-sizing: border-box;
  position: relative;
  ${media.tablet} {
    padding-top: 9.8vw;
    padding-left: 18vw;
  }
  ${media.mobile} {
    padding: 15vw 0vw 10vw 23vw;
    width: 100%;
    overflow: hidden;
  }
`;

const Heading = styled.div`
  margin-bottom: 7.8vw;
  width: 63vw;
  ${media.tablet} {
    width: 90%;
  }
  ${media.mobile} {
    width: 75vw;
    margin-bottom: 15vw;
  }
`;

const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-around;

  ${media.mobile} {
    flex-direction: column;
    padding-left: 0vw;
    justify-content: space-around;
    align-items: flex-start;
  }
`;

const TextWrapper = styled.div`
  width: 30vw;
  height: fit-content;
  ${media.mobile} {
    width: 100%;
    margin-top: 9.3vw;
  }
`;

const SubHeading = styled(Heading3)`
  margin-bottom: 1.2vw;
  width: 24vw;

  ${media.mobile} {
    margin-bottom: 5vw;
    width: 90%;
  }
`;

const BodyCopy = styled(BodyCopy1)`
  ${media.mobile} {
    width: 90%;
  }
`;

const AnimWrap = styled.div`
  position: relative;
  width: 24.3vw;
  height: 49.3vw;

  svg {
    overflow: visible;
  }

  .overview-story {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;

    &2 {
      top: 30%;
      width: 28.3vw;
      margin-left: -2vw;
      transform: scale(0);
    }

    &3 {
      top: 28%;
      width: 28.3vw;
      margin-left: -2vw;
    }

    &4 {
      top: 12%;
      width: 34.3vw;
      margin-left: -5.3vw;
      transform: translate(50%, 50%);

      .overview-story4-inner,
      .overview-story4-outer {
        transform: scale(0);
      }
    }
  }

  ${media.tablet} {
    // width: 34.2vw;
    // height: 69.3vw;
  }

  ${media.mobile} {
    // width: 24.3vw;
    // height: 49.3vw;

    margin: 15vw 0 18vw 20vw;
    transform: scale(1.7);
  }
`;

const Wrap1 = styled.div`
  position: relative;
  transition: opacity 0.5s;
  top: 0;
  ${media.mobile} {
  }
`;
export default Risk;
