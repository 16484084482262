import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { Heading1, Heading2, Heading3, Heading4 } from "components/Text";
import gsap from "gsap";
import media from "styles/media";
import Colors from "styles/Colors";

type Props = {
  headingText: any;
  headingSize: number;
  headingColor: string;
  headingText2?: string;
  bracketColor?: string;
};

const HeadingText: React.FC<Props> = ({
  headingText,
  headingSize,
  headingColor,
  headingText2,
  bracketColor,
}) => {
  const topBracketPath = useRef(null);
  const bottomBracketPath = useRef(null);
  const topBracket = useRef(null);
  const bottomBracket = useRef(null);
  const headingWrapper = useRef(null);
  const [headerOpacity, setHeaderOpacity] = useState(false);

  useEffect(() => {
    gsap.set([bottomBracketPath.current, topBracketPath.current], {
      drawSVG: "0 0",
      opacity: 1,
    });
    if (headingWrapper.current) {
      const tl = gsap.timeline({
        scrollTrigger: {
          //@ts-ignore
          trigger: headingWrapper.current,
          start: "top 99%",
        },
      });

      tl.to(
        [bottomBracketPath.current, topBracketPath.current],
        {
          drawSVG: "100 0",
          duration: 0.4,
          onComplete: () => setHeaderOpacity(true),
        },
        0
      )
        .to(
          topBracket.current,
          { top: "0", left: "-=7.5vw", duration: 0.4 },
          0.5
        )
        .to(
          bottomBracket.current,
          {
            bottom: "0",
            right: "-=7.5vw",
            duration: 0.4,
          },
          0.5
        )
        .to(topBracket.current, { left: "0", duration: 0.4 }, 0.9)
        .to(
          bottomBracket.current,
          {
            right: "0",
            duration: 0.4,
          },
          0.9
        )
        .to(
          [bottomBracketPath.current, topBracketPath.current],
          {
            drawSVG: "0 0",
            duration: 0.4,
          },
          1.3
        );
      return () => {
        tl.kill();
      };
    }
  }, []);

  let HeadingStyle = Heading1Style;

  switch (headingSize) {
    case 0:
      HeadingStyle = Heading1Style;
      break;

    case 1:
      HeadingStyle = Heading2Style;
      break;

    case 2:
      HeadingStyle = Heading3Style;
      break;

    case 3:
      HeadingStyle = Heading4Style;
      break;

    default:
      break;
  }

  return (
    <HeadingWrapper ref={headingWrapper}>
      <TopBracket ref={topBracket}>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            ref={topBracketPath}
            d="M56 2H2V56"
            stroke={bracketColor ? bracketColor : "#F09C4E"}
            strokeWidth="3"
          />
        </svg>
      </TopBracket>

      <BottomBracket ref={bottomBracket}>
        <svg
          width="56"
          height="56"
          viewBox="0 0 56 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            ref={bottomBracketPath}
            d="M0 54L54 54L54 -2.38419e-06"
            stroke={bracketColor ? bracketColor : "#F09C4E"}
            strokeWidth="3"
          />
        </svg>
      </BottomBracket>
      <HeadingStyle visible={headerOpacity ? 0 : 1} headingcolor={headingColor}>
        {headingText2 ? (
            <>{headingText} <br /> {headingText2}{" "}</>
        ) : (
          headingText
        )}
      </HeadingStyle>
    </HeadingWrapper>
  );
};

const Heading1Style = styled(Heading1)<{
  visible: number;
  headingcolor: string;
}>`
  color: ${(props) => props.headingcolor};
  width: 100%;
  opacity: ${(props) => (props.visible === 0 ? 1 : 0)};
  transition: opacity 2s;
`;

const Heading2Style = styled(Heading2)<{
  visible: number;
  headingcolor: string;
}>`
  color: ${(props) => props.headingcolor};
  width: 100%;
  opacity: ${(props) => (props.visible === 0 ? 1 : 0)};
  transition: opacity 2s;
  ${media.tablet} {
  }
`;

const Heading3Style = styled(Heading3)<{
  visible: number;
  headingcolor: string;
}>`
  color: ${(props) => props.headingcolor};
  width: 100%;
  opacity: ${(props) => (props.visible === 0 ? 1 : 0)};
  transition: opacity 2s;
  ${media.tablet} {
  }
`;

const Heading4Style = styled(Heading4)<{
  visible: number;
  headingcolor: string;
}>`
  color: ${(props) => props.headingcolor};
  width: 100%;
  opacity: ${(props) => (props.visible === 0 ? 1 : 0)};
  transition: opacity 2s;
  ${media.tablet} {
  }
`;

const HeadingWrapper = styled.div`
  position: relative;
  width: fit-content;
  
  span {
    color: ${Colors.industryOrange100};
  }
`;

const TopBracket = styled.div`
  position: absolute;
  top: calc(50% - 2.2vw);
  left: calc(50% - 2.2vw);
  width: 3.8vw;
  height: 3.8vw;
  opacity: 1;
  svg {
    width: 100%;
    height: 100%;
  }
  ${media.tablet} {
    width: 4.5vw;
    height: 4.5vw;
  }
  ${media.mobile} {
    width: 6vw;
    height: 6vw;
  }
`;

const BottomBracket = styled.div`
  position: absolute;
  width: 3.8vw;
  height: 3.8vw;
  bottom: calc(50% - 2.2vw);
  right: calc(50% - 2.2vw);
  opacity: 1;
  svg {
    width: 100%;
    height: 100%;
  }
  ${media.tablet} {
    width: 4.5vw;
    height: 4.5vw;
  }
  ${media.mobile} {
    width: 6vw;
    height: 6vw;
  }
`;

export default HeadingText;
