import React, { useState, useEffect, useRef, useContext } from "react";
import { MobileContext } from "App";
import styled from "styled-components";
import media from "styles/media";
import Colors from "styles/Colors";
import gsap from "gsap";
import Menu from "./Menu";
import slashElement from "assets/svg/slashElement.svg";

const Header: React.FC<{ history: any; selectRef: any; selectNum: number }> = ({
  history,
  selectRef,
  selectNum,
}) => {
  const mobile = useContext(MobileContext);

  const [toggleText, setToggleText] = useState("Menu");
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(true);
  const wrapperRef = useRef(null);
  const toggleWrapperRef = useRef(null);
  const toggleTextRef = useRef(null);
  const slashRef = useRef(null);
  const { pathname } = history.location;

  useEffect(() => {
    if (pathname === "/calculator") {
      setVisible(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (mobile) {
      if (!open) {
        gsap.to(wrapperRef.current, { duration: 1, left: "-85vw" });
      }
    } else {
      if (!open) {
        gsap.to(wrapperRef.current, { duration: 1, left: "-60vw" });
      }
    }
    // eslint-disable-next-line
  }, [mobile]);

  const openNav = () => {
    gsap.to(toggleTextRef.current, {
      duration: 1,
      left: "-160%",
      ease: "expo.inOut",
      opacity: 0,
      onComplete: () => {
        setToggleText("Close");
        gsap.fromTo(
          toggleTextRef.current,
          {
            right: "-160%",
            left: "unset",
            transform: "rotate(90deg)",
          },
          {
            duration: 1,
            ease: "expo.inOut",
            right: "0%",
            opacity: 1,
          }
        );
      },
    });

    gsap.to(wrapperRef.current, {
      duration: 1.5,
      ease: "expo.inOut",
      left: "0vw",
    });

    gsap.to(toggleWrapperRef.current, {
      duration: 2,
      ease: "expo.inOut",
      borderRight: "none",
      borderLeft: `1px solid ${Colors.bone100}`,
    });

    gsap.to(selectRef.current, {
      duration: 0.5,
      opacity: 1,
    });

    gsap.to(slashRef.current, {
      duration: 1,
      right: mobile ? "16vw" : "10.9vw",
    });
  };

  const closeNav = () => {
    gsap.to(toggleTextRef.current, {
      duration: 1,
      right: "-160%",
      ease: "expo.inOut",
      opacity: 0,
      onComplete: () => {
        setToggleText("Menu");
        gsap.fromTo(
          toggleTextRef.current,
          {
            left: "-160%",
            right: "unset",
            transform: "rotate(-90deg)",
          },
          {
            duration: 1,
            ease: "expo.inOut",
            left: "0%",
            opacity: 1,
          }
        );
      },
    });

    gsap.to(wrapperRef.current, {
      duration: 1.5,
      ease: "expo.inOut",
      left: mobile ? "-85vw" : "-60vw",
    });

    gsap.to(toggleWrapperRef.current, {
      duration: 2,
      ease: "expo.inOut",
      borderLeft: "none",
      borderRight: `1px solid ${Colors.bone100}`,
    });

    gsap.to(selectRef.current, {
      duration: 0.5,
      opacity: 0,
    });

    gsap.to(slashRef.current, {
      duration: 1,
      right: "-0.3vw",
    });
  };

  const toggleNav = () => {
    if (open) {
      closeNav();
      setOpen(false);
    } else {
      openNav();
      setOpen(true);
    }
  };

  return (
    <Wrapper ref={wrapperRef} visible={visible}>
      <MenuWrapper>
        <Menu
          selectNum={selectNum}
          toggleNav={toggleNav}
          selectRef={selectRef}
          history={history}
        />
      </MenuWrapper>
      <Toggle onClick={toggleNav} ref={toggleWrapperRef}>
        <ToggleText ref={toggleTextRef}>{toggleText}</ToggleText>
      </Toggle>
      <SlashElement ref={slashRef} />
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div<{ visible: boolean }>`
  position: fixed;
  left: 0vw;
  top: 0vw;
  z-index: 999;
  display: ${(props) => (props.visible ? "flex" : "none")};
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  justify-content: flex-start;

  ${media.tablet} {
    width: 71vw;
    height: 100vh;
    left: -60vw;
  }

  ${media.mobile} {
    width: 101vw;
    left: -85vw;
  }
`;

const MenuWrapper = styled.div`
  height: 100%;
  background: ${Colors.gunMetal100};

  ${media.tablet} {
    width: 60vw;
  }

  ${media.mobile} {
    width: 85vw;
  }
`;

const Toggle = styled.div`
  background: ${Colors.gunMetal100};
  height: 100%;
  width: 10vw;
  border-right: 1px solid ${Colors.bone100};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  ${media.mobile} {
    width: 15vw;
  }
`;

const ToggleText = styled.p`
  color: ${Colors.bone100};
  transform: rotate(-90deg);
  font-family: Roobert;
  font-size: 2.5vw;
  position: relative;

  ${media.mobile} {
    font-size: 5vw;
  }
`;

const SlashElement = styled.div`
  background: url(${slashElement});
  position: absolute;
  top: 0;
  height: 100vh;
  left: 189px;
  width: 14px;
  z-index: 999;
  background-blend-mode: difference;

  ${media.tablet} {
    right: -0.3vw;
    left: unset;
    width: 1vw;
  }
`;
