import React, { useState, useContext, useEffect } from "react";
import { SetRequestDemoContext, SetSuccessViewContext } from "App";
import gsap from "gsap";
import {
  SubWrapper,
  NavSub,
  NavLink,
  NavLinkSub,
  LinkWrap,
  LinkSubWrap,
  Select,
  Back,
} from "./Menu";

type Props = {
  copy: {
    title: string;
    url: string;
    sections: string[][];
  };
  handleBack: () => void;
  visible: boolean;
  selectNum: number;
  tallTitle?: boolean;
};

const SubMenu: React.FC<Props> = ({ copy, handleBack, visible, selectNum, tallTitle = false }) => {
  const [active, setActive] = useState(0);
  const [selectPosition, setSelectPosition] = useState(6.1);
  // const [lastActive, setLastActive] = useState(0);

  const setRequestDemo = useContext(SetRequestDemoContext);
  const setSuccessView = useContext(SetSuccessViewContext);

  const handleClick = (active: number, scrollId: string) => {
    setRequestDemo(false);
    setSuccessView(false);
    setActive(active);
    // handleSelectMove(active);
    gsap.to(window, { scrollTo: scrollId });
  };

  const handleSelectMove = (num: number, tallTitle: boolean = false) => {
    const positions = tallTitle ? [6.1, 11.1, 13.8, 16.6, 19.5] : [6.1, 10.1, 12.8, 15.6, 18.5];
    setActive(num);
    setSelectPosition(positions[num]);
  };

  const scrollToTop = () => {
    setRequestDemo(false);
    setSuccessView(false);
    setActive(0);
    handleSelectMove(0);
    gsap.to(window, { scrollTo: 0 });
  };

  useEffect(() => {
    handleSelectMove(selectNum, tallTitle);

    setActive(selectNum);
  }, [selectNum, tallTitle]);

  const subSections = copy.sections.map((section, i) => {
    return (
      <LinkSubWrap activeEl={active === i + 1} key={i}>
        <NavLinkSub onClick={() => handleClick(i + 1, section[0])}>
          {section[1]}
        </NavLinkSub>
      </LinkSubWrap>
    );
  });

  return (
    <SubWrapper visible={visible}>
      <Select position={selectPosition} />
      <Back to="/" onClick={() => handleBack()}>
        Back
      </Back>
      <NavSub>
        <LinkWrap activeEl={active === 0}>
          <NavLink to={copy.url} onClick={scrollToTop}>
            {copy.title}
          </NavLink>
        </LinkWrap>
        {subSections}
      </NavSub>
    </SubWrapper>
  );
};

export default SubMenu;
