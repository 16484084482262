import React, { useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import Colors from "styles/Colors";
import media from "styles/media";
import { Heading2, BodyCopy2 } from "components/Text";
import { ReactComponent as HomeIcon1SVG } from "assets/svg/Home/home-icon1.svg";
import { ReactComponent as HomeIcon2SVG } from "assets/svg/Home/home-icon2.svg";
import { ReactComponent as HomeIcon3SVG } from "assets/svg/Home/home-icon3.svg";
import { ReactComponent as HomeIcon4SVG } from "assets/svg/Home/home-icon4.svg";

const BetterIntelligence: React.FC<{}> = () => {
  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".betterIntHeading",
        start: "top 60%",
      },
    });

    tl.to([".BI_column1"], { opacity: 1, top: 0, duration: 0.7 }, 0)
      .to([".home-icon1-g1"], { opacity: 1, transform: 'translateX(0)', duration: 1 }, 0.25)
      .to([".home-icon1-g2, .home-icon1-g3, .home-icon1-g4, .home-icon1-g5"], 
        { opacity: 1, transform: 'translateX(0)', duration: 1, stagger: 0.05 }, 0.4)
      .to([".BI_column2"], { opacity: 1, top: 0, duration: 0.7 }, 0.7)
      .to([".home-icon2-page"], { opacity: 1, transform: 'translate(0)', duration: 0.4 }, 1)
      .to([".home-icon2-check1, .home-icon2-check2, .home-icon2-check3"], 
        { opacity: 1, duration: 1, stagger: 0.1 }, 1.1)
      .to([".BI_column3"], { opacity: 1, top: 0, duration: 0.7 }, 1.3)
      .from([".home-icon3-star"], { opacity: 0, duration: 1, transformOrigin: '50% 50%', scale: 0 }, 1.6)
      .to([".home-icon3-bubble"], { opacity: 1, duration: 1 }, 1.9)
      .to([".BI_column4"], { opacity: 1, top: 0, duration: 0.7 }, 1.9)
      .to([".home-icon4-gear"], { opacity: 1, transformOrigin: '50% 50%', rotation: 45, duration: 1 }, 2.4)
      .to([".home-icon4-symbol"], { opacity: 1, duration: 1 }, 2.7);
  }, []);

  return (
    <Wrapper>
        <Heading className="betterIntHeading">
            Better Intelligence for a Better Business
        </Heading>
        <IconColumns>
            <Column className="BI_column1">
                <ColumnImage>
                    <HomeIcon1 />
                </ColumnImage>
                <ColumnText>
                  Optimize with data-driven decisions regarding major appliances and mechanicals
                </ColumnText>
            </Column>
            <Column className="BI_column2">
                <ColumnImage>
                    <HomeIcon2 />
                </ColumnImage>
                <ColumnText>
                    Reduce urgent maintenance 
                    and claims to increase 
                    predictability and 
                    profitability
                </ColumnText>
            </Column>
            <Column className="BI_column3">
                <ColumnImage>
                    <HomeIcon3 />
                </ColumnImage>
                <ColumnText>
                    Deliver great resident and 
                    customer experiences to 
                    elevate your brand
                </ColumnText>
            </Column>
            <Column className="BI_column4">
                <ColumnImage>
                    <HomeIcon4 />
                </ColumnImage>
                <ColumnText>
                  Drive productivity and operational efficiency, focusing on action
                </ColumnText>
            </Column>
        </IconColumns>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: ${Colors.gunMetal100};
  padding: 6.1vw 5vw 8vw 18vw;
  position: relative;
  text-align: left;
  overflow: hidden;
  box-sizing: border-box;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  
  ${media.mobile} {
    width: 100%;
    height: auto;
    padding: 15vw 4vw 14vw 20vw;
  }
`;

const Heading = styled(Heading2)`
  color: ${Colors.bone100};
  margin-bottom: 5vw;
  width: 40.3vw;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  ${media.mobile} {
    width: 100%;
    margin-bottom: 8vw;
    text-align: left;
  }
`;

const IconColumns = styled.div`
  display: flex;

  ${media.tablet} {
    flex-wrap: wrap;
    gap: 5vw 0;
  }

  ${media.mobile} {
    gap: 8vw 0;
  }
`;

const Column = styled.div`
  flex: 0 0 25%;
  max-width: 25%;
  padding: 0 2.5vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 2vw;
  opacity: 0;
  position: relative;
  top: 40px;

  ${media.tablet} {
    flex: 0 0 50%;
    max-width: 50%;
  }

  ${media.mobile} {
    flex: 0 0 100%;
    max-width: 100%;
    gap: 4vw;
  }
`;

const ColumnImage = styled.div`
  height: 7vw;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    overflow: visible;
  }

  ${media.tablet} {
    height: 10vw;
  }

  ${media.mobile} {
    min-height: 10vw;
    height: auto;
    max-height: 18vw;
    justify-content: flex-start;
  }
`;

const ColumnText = styled(BodyCopy2)`
  text-align: center;
  color: ${Colors.bone100};

  ${media.mobile} {
    text-align: left;
  }
`;

const HomeIcon1 = styled(HomeIcon1SVG)`
  height: 100%;

  .home-icon1-g1,
  .home-icon1-g2,
  .home-icon1-g3,
  .home-icon1-g4,
  .home-icon1-g5 {
    transform: translateX(40px);
    opacity: 0;
  }
`;

const HomeIcon2 = styled(HomeIcon2SVG)`
  height: 100%;

  .home-icon2-check1,
  .home-icon2-check2,
  .home-icon2-check3 {
    opacity: 0;
  }

  .home-icon2-page {
    transform: translate(1vw, -1vw);
  }
`;

const HomeIcon3 = styled(HomeIcon3SVG)`
  height: 100%;

  .home-icon3-bubble {
    opacity: 0;
  }

  .home-icon3-star {
    transform-origin: 50% 50%;
  }
`;

const HomeIcon4 = styled(HomeIcon4SVG)`
  height: 100%;

  .home-icon4-gear,
  .home-icon4-symbol {
    opacity: 0;
  }

  .home-icon4-gear {
    transform-origin: 50% 50%;
  }
`;

export default BetterIntelligence;
