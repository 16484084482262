import { Link } from "react-router-dom";
import styled from "styled-components";
import Easings from "styles/Easings";
import Colors from "styles/Colors";
import media from "styles/media";
import OrangeArrow from "assets/svg/OrangeArrow.svg";
import OrangeArrowBack from "assets/svg/orangeArrowBack.svg";

export const ButtonRegularStyles = `
  color: ${Colors.bone100};
  font: 1.2vw Roobert;
  letter-spacing: -0.02em;
  position: relative;
  padding: 0 1.3vw 0.7vw 0;
  
  transition: opacity 0.5s;
  text-align: left;

  ::before {
    content: "";
    background-image: repeating-linear-gradient(
      to right,
      ${Colors.industryOrange100},
      ${Colors.industryOrange100} 33%,
      transparent 33%,
      transparent 67%,
      ${Colors.industryOrange100} 67%,
      ${Colors.industryOrange100} 100%
    );
    background-size: 300% 100%;
    height: 1px;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: background-position-x 700ms;
      cubic-bezier(${Easings.easeInOutCub});
  }
  :after {
    background: url(${OrangeArrow}) center/contain no-repeat;
    content: "";
    position: absolute;
    display: block;
    height: 0.7vw;
    width: 0.7vw;
    object-fit: contain;
    right: 0;
    top: 0.3vw;
     transition: all 400ms cubic-bezier(${Easings.easeInOutCub});
  }
  :hover:not(:disabled)::before {
    background-position-x: -150%;
  }
  :hover:not(:disabled)::after {
    top: 0;
    right: -5px;
  }
  :disabled {
    opacity: 0.5;
    cursor: initial;
  }

  ${media.fullWidth} {
    // font-size: 0px;2
  }

  ${media.mobile} {
    font-size: 4.5vw;
    padding-bottom: 1.5vw;
   
    ::after {
      width: 2.5vw;
      height: 2.5vw;
      top: 1vw;
    }
  }
`;

export const ButtonRegular = styled(Link)<{ visible?: number }>`
  ${ButtonRegularStyles}
  opacity: ${(props) => (props.visible === 0 ? 1 : 0)};
`;

export const ButtonLarge = styled(ButtonRegular)`
  font-size: 1.6vw;
  ::after {
    top: 0.4vw;
    height: 0.9vw;
    width: 0.9vw;
  }
`;

export const ButtonRegularExternal = styled.a<{ visible?: number }>`
  ${ButtonRegularStyles}
  opacity: ${(props) => (props.visible === 0 ? 1 : 0)};
`;

export const ButtonLargeExternal = styled(ButtonRegularExternal)`
  font-size: 1.6vw;
  ::after {
    top: 0.4vw;
    height: 0.9vw;
    width: 0.9vw;
  }
`;

export const ButtonBack = styled(Link)`
  ${ButtonRegularStyles}

  padding: 0 0 0.7vw 1.3vw;
  :after {
    position: absolute;
    background-image: url(${OrangeArrowBack});
    /* transform: rotateY(180deg); */
    right: none;
    left: 0;
    width: 0.8vw;
    height: 0.8vw;
  }
  :hover:not(:disabled):after {
    left: -5px;
    right: unset;
  }
  ${media.tablet} {
    padding: 0 0 2vw 8vw;
    font-size: 4vw;
    :after {
      width: 2vw;
      height: 2vw;
    }
  }
  ${media.mobile} {
    font-size: 5vw;
    padding: 0 0 3vw 8vw;
  }
`;
