import React from "react";
import styled from "styled-components";
import media from "styles/media";

const DataDrivenDecisions: React.FC<{}> = () => {
  return (
    <DataDrivenDecisionsImage>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 280.21 219">
        <g className="overview-icon1-dot2">
                <path id="Path_432" style={{ fill: "#0D0F14" }} d="M99.41,70.77c0-12.81,10.39-23.2,23.2-23.2c12.81,0,23.2,10.39,23.2,23.2
                    c0,12.81-10.39,23.2-23.2,23.2l0,0C109.8,93.97,99.41,83.59,99.41,70.77"/>
                <path id="Path_433" style={{ fill: "#FFFFFF" }} d="M122.61,95.42c-13.61,0-24.65-11.04-24.65-24.65c0-13.61,11.04-24.65,24.65-24.65
                    s24.65,11.04,24.65,24.65c0,0,0,0,0,0C147.25,84.38,136.22,95.41,122.61,95.42 M122.61,49.02c-12.01,0-21.75,9.74-21.75,21.76
                    c0,12.01,9.74,21.75,21.76,21.75c12.01,0,21.75-9.74,21.75-21.75C144.35,58.76,134.62,49.03,122.61,49.02"/>
            </g>
            <path id="Path_434" style={{ fill: "#FFFFFF" }} d="M17.93,179.01c-0.58,0-1.06-0.47-1.06-1.06c0-0.26,0.1-0.51,0.27-0.7
                c10.16-11.44,20.14-23.32,29.66-35.29c0.2-0.25,0.51-0.4,0.83-0.4c0.58,0,1.06,0.48,1.06,1.06c0,0.24-0.08,0.47-0.23,0.66
                c-9.56,12.01-19.56,23.92-29.73,35.38C18.53,178.89,18.24,179.02,17.93,179.01"/>
            <path id="Path_435" style={{ fill: "#FFFFFF" }} d="M224.53,124.31c-0.19,0-0.37-0.05-0.53-0.14l-45.29-26.39
                c-0.5-0.29-0.68-0.94-0.38-1.45c0,0,0,0,0,0c0.19-0.33,0.54-0.53,0.91-0.53c0.19,0,0.37,0.05,0.53,0.14l44.34,25.83l36.02-69.02
                c0.18-0.35,0.55-0.57,0.94-0.57c0.17,0,0.34,0.04,0.49,0.12c0.52,0.27,0.72,0.91,0.45,1.43l-36.54,70
                c-0.14,0.26-0.37,0.45-0.65,0.53C224.73,124.3,224.63,124.31,224.53,124.31"/>
            <g className="overview-icon1-dot1">
                <path id="Path_436" style={{ fill: "#0D0F14" }} d="M1.06,184.04c0-6.92,5.61-12.52,12.52-12.52s12.52,5.61,12.52,12.52
                    c0,6.91-5.61,12.52-12.52,12.52C6.66,196.56,1.06,190.95,1.06,184.04"/>
                <path id="Path_437" style={{ fill: "#0D0F14" }} d="M13.58,195.53c-6.35,0-11.49-5.15-11.49-11.49c0-6.35,5.15-11.49,11.49-11.49
                    c6.34,0,11.49,5.14,11.49,11.48C25.07,190.38,19.93,195.53,13.58,195.53"/>
                <path id="Path_438" style={{ fill: "#FFFFFF" }} d="M13.58,197.62c-7.5,0-13.58-6.08-13.58-13.58s6.08-13.58,13.58-13.58
                    c7.5,0,13.58,6.08,13.58,13.58C27.15,191.53,21.08,197.6,13.58,197.62 M13.58,172.57c-6.33,0-11.47,5.13-11.47,11.46
                    c0,6.33,5.13,11.47,11.46,11.47c6.33,0,11.47-5.13,11.47-11.46c0,0,0,0,0,0C25.04,177.71,19.91,172.58,13.58,172.57"/>
            </g>
            <g className="overview-icon1-dot4">
                <path id="Path_439" style={{ fill: "#0D0F14" }} d="M254.11,40.73c0-6.92,5.61-12.52,12.52-12.52s12.52,5.61,12.52,12.52
                    c0,6.91-5.61,12.52-12.52,12.52C259.72,53.26,254.11,47.65,254.11,40.73C254.11,40.73,254.11,40.73,254.11,40.73"/>
                <path id="Path_440" style={{ fill: "#FFFFFF" }} d="M266.63,54.31c-7.5,0-13.58-6.08-13.58-13.58s6.08-13.58,13.58-13.58
                    s13.58,6.08,13.58,13.58c0,0,0,0,0,0C280.2,48.23,274.13,54.3,266.63,54.31 M266.63,29.27c-6.33,0-11.46,5.13-11.46,11.46
                    s5.13,11.47,11.46,11.47c6.33,0,11.46-5.13,11.46-11.46c0,0,0,0,0,0C278.09,34.4,272.96,29.27,266.63,29.27"/>
            </g>
            <g className="overview-icon1-dot3">
                <path id="Path_441" style={{ fill: "#0D0F14" }} d="M212.01,123.25c0-6.92,5.61-12.52,12.52-12.52s12.52,5.61,12.52,12.52
                    c0,6.91-5.61,12.52-12.52,12.52C217.62,135.77,212.01,130.17,212.01,123.25"/>
                <path id="Path_442" style={{ fill: "#0D0F14" }} d="M224.53,134.74c-6.35,0-11.49-5.15-11.49-11.49c0-6.35,5.15-11.49,11.49-11.49
                    c6.35,0,11.49,5.15,11.49,11.49C236.02,129.6,230.88,134.74,224.53,134.74"/>
                <path id="Path_443" style={{ fill: "#FFFFFF" }} d="M224.54,136.83c-7.5,0-13.58-6.08-13.58-13.58c0-7.5,6.08-13.58,13.58-13.58
                    c7.5,0,13.58,6.08,13.58,13.58c0,0,0,0,0,0C238.11,130.75,232.03,136.82,224.54,136.83 M224.54,111.79
                    c-6.33,0-11.47,5.13-11.47,11.46c0,6.33,5.13,11.47,11.46,11.47c6.33,0,11.47-5.13,11.47-11.46c0,0,0,0,0,0
                    C235.99,116.92,230.87,111.79,224.54,111.79"/>
            </g>
            <path id="Path_444" style={{ fill: "#FFFFFF" }} d="M60.25,148.16c-0.8,0-1.45-0.65-1.44-1.45c0-0.32,0.11-0.64,0.31-0.89l45.66-57.95
                c0.27-0.35,0.69-0.55,1.14-0.55c0.8,0,1.45,0.65,1.45,1.45c0,0.33-0.11,0.64-0.31,0.9l-45.67,57.95
                C61.11,147.96,60.69,148.17,60.25,148.16"/>
            <path id="Path_445" style={{ fill: "#FFFFFF" }} d="M173.62,104.09c-0.27,0-0.53-0.07-0.76-0.21l-31.29-19.23
                c-0.69-0.4-0.92-1.29-0.52-1.98c0.4-0.69,1.29-0.92,1.98-0.52c0.02,0.01,0.03,0.02,0.05,0.03l31.3,19.23
                c0.68,0.42,0.89,1.31,0.48,1.99C174.59,103.83,174.13,104.09,173.62,104.09"/>
            <path id="Path_446" style={{ fill: "#FFFFFF" }} d="M100.25,160.39c-44.29,0-80.19-35.9-80.19-80.19S55.97,0,100.26,0
                c44.29,0,80.19,35.9,80.19,80.19C180.4,124.46,144.52,160.34,100.25,160.39 M100.25,2.46c-42.93,0-77.74,34.81-77.74,77.74
                c0,42.93,34.81,77.74,77.74,77.74c42.93,0,77.74-34.8,77.74-77.74C177.94,37.28,143.17,2.5,100.25,2.46"/>
            <path id="Path_447" style={{ fill: "#FFFFFF" }} d="M231.62,219c-1.44,0-2.82-0.57-3.84-1.59l-76.34-76.34l7.68-7.68l76.34,76.33
                c2.12,2.12,2.12,5.56,0,7.67C234.44,218.42,233.06,219,231.62,219"/>
        </svg>
    </DataDrivenDecisionsImage>
  );
};

const DataDrivenDecisionsImage = styled.div`
    margin-bottom: 2.9vw;
    width: 12.5vw;
    height: 9.8vw;
    svg {
        width: 100%;
        height: auto;
    }

    ${media.mobile} {
        width: 37vw;
        height: auto;
        margin-bottom: 5vw;
    }
`;

export default DataDrivenDecisions;
