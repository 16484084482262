import { useContext, useEffect } from "react";
import { SelectContext, DesktopContext } from "App";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const useSelectScroll = (triggers: any, subMenu?: boolean) => {
  const setSelectNum = useContext(SelectContext);
  const desktop = useContext(DesktopContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    ScrollTrigger.refresh();

    if (desktop || (!desktop && !subMenu)) {
      triggers.forEach((trigger: any) => {
        const prevSection =
          triggers[
            triggers.indexOf(trigger) === 0 ? 0 : triggers.indexOf(trigger) - 1
          ].number;

        gsap.to(trigger.name, {
          scrollTrigger: {
            trigger: trigger.name,
            start: trigger.number === 9 ? "top 80%" : "top 25%",
            end: "bottom bottom",
            onEnter: () => setSelectNum(trigger.number),
            onEnterBack: () => setSelectNum(trigger.number),
            onLeaveBack: () => setSelectNum(prevSection),
            markers: false,
          },
          // onComplete: () => {
          //   console.log(trigger.number, prevSection, "trigger");
          // },
        });
      });

      return () => {
        ScrollTrigger.getAll().forEach((trigger) => {
          trigger.kill();
        });
      };
    }

    // eslint-disable-next-line
  }, []);
};

export default useSelectScroll;
