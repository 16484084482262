import React, { useEffect } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { Heading4, BodyCopy1 } from "components/Text";
import HeadingText from "components/HeadingText";
import { ReactComponent as ClaimIconSVG } from "assets/svg/Overview/overview-icon5.svg";
import { ReactComponent as ReducedRiskIconSVG } from "assets/svg/Overview/overview-icon6.svg";
import { ReactComponent as DataIconSVG } from "assets/svg/Overview/overview-icon7.svg";
import { ReactComponent as ProductIconSVG } from "assets/svg/Overview/overview-icon8.svg";
import Colors from "styles/Colors";
import media from "styles/media";

const Reporting: React.FC<{}> = () => {
  useEffect(() => {
    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".row1-trigger",
        start: "top 50%",
        // end: "+=500",
        // scrub: 0,
        // markers: {
        //   startColor: "red",
        //   endColor: "blue",
        //   fontSize: "18px",
        //   fontWeight: "bold",
        //   indent: 20,
        // },
      },
      delay: 0.3,
    }); 

    tl1.from([".reporting-feature1"], { opacity:0, y: 40, duration: 0.5}, 0)
      .from([".overview-icon5-arrows"], { opacity: 0, transformOrigin: "50% 50%", rotate: -180, duration: 1}, 0.3)
      .from([".overview-icon5-appliance"], { opacity: 0, x: 40, duration: 0.5}, 0.4)
      .from([".reporting-feature2"], { opacity:0, y: 40, duration: 0.5}, 1.5)
      .to([".overview-icon6-arrow"], { transform: 'rotate(44deg)', duration: 1}, 1.8)
      .from([".overview-icon6-symbol"], { opacity: 0, duration: 1}, 2.1);
    
    const tl2 = gsap.timeline({
    scrollTrigger: {
      trigger: ".row2-trigger",
      start: "top 70%",
    },
    repeat: 0,
    delay: 0.3,
  }); 

  tl2.from([".reporting-feature3"], { opacity:0, y: 40, duration: 0.5}, 0)
    .from([".overview-icon7-box"], { opacity:0, transformOrigin: "50% 50%", scale:0, duration: 0.5 }, 0.3)
    .from([".overview-icon7-symbol"], { drawSVG: "0%", duration: 0.9 }, 0.7)
    .from([".reporting-feature4"], { opacity:0, y: 40, duration: 0.5}, 1.6)
    .from([".overview-icon8-box"], { opacity:0, x: 40, duration: 0.5 } ,1.9)
    .from([".overview-icon8-box-outline"], { stroke: "#000000", duration: 0.5 }, 2.1);
  }, []);

  return (
    <Wrapper>
      <Heading className="row1-trigger">
        <HeadingText
          headingColor={Colors.gunMetal100}
          headingSize={1}
          headingText={
            "Home Asset Intelligence Like No Other"
          }
        />
      </Heading>
      <FeaturesContainer>
        <ClaimFeature className="reporting-feature1">
          <ClaimIcon />
          <SubHeading>First real-time asset lifecycle database</SubHeading>
          <BodyCopy>
            Mine information on home appliances and mechanicals collected over years across industries and verticals to give everyone in your organization new, unique insights that allow them to make better data-driven decisions.
          </BodyCopy>
        </ClaimFeature>
        <RiskFeature className="reporting-feature2">
          <ReducedRiskIcon />
          <SubHeading>Risk scores and recommendations</SubHeading>
          <BodyCopy>
            Proprietary algorithms and failure trend data give you actionable intelligence you can use to determine what to do with an asset and how to take the surprise and guesswork out of decisions.
          </BodyCopy>
        </RiskFeature>
        <DataFeature className="reporting-feature3 row2-trigger">
          <DataIcon />
          <SubHeading>Future-proofed platform</SubHeading>
          <BodyCopy>
            Source7's unique database is getting bigger and smarter every day. With millions of inputs from a vast range of source and industries to further advance analysis - imagine what's possible.
          </BodyCopy>
        </DataFeature>
        <ProductFeature className="reporting-feature4">
          <ProductIcon />
          <SubHeading>Easy <br />integration</SubHeading>
          <BodyCopy>
            Open API enables easy, straightforward integration with existing apps, platforms and workflows to enable more informed decisions with much less effort - there are no additional systems to deploy, no new application to download or train on.
          </BodyCopy>
        </ProductFeature>
      </FeaturesContainer>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: ${Colors.bone100};
  padding: 7.6vw 15.3vw 6.3vw 21.4vw;

  ${media.tablet} {
    padding-left: 18vw;
  }

  ${media.mobile} {
    padding: 15vw 0vw 6vw 23vw;
  }
`;

const FeaturesContainer = styled.div`
  display: grid;
  padding-left: 3vw;
  grid-template-columns: 24.3vw 24.3vw;
  column-gap: 11vw;
  row-gap: 3.9vw;

  svg {
    overflow: visible;
  }

  ${media.tablet} {
    padding-left: 0;
    grid-template-columns: 28.3vw 28.3vw;
    row-gap: 6vw;
  }

  ${media.mobile} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0vw;
    justify-content: flex-start;
    row-gap: unset;
  }
`;

const Feature = styled.div`
  height: 34.7vw;
  width: 24.3vw;

  ${media.tablet} {
    height: auto;
    width: 28.3vw;
  }

  ${media.mobile} {
    margin-bottom: 11vw;
    width: 100%;
    height: auto;
  }
`;

const SubHeading = styled(Heading4)`
  margin: 1.7vw 0 1vw;

  ${media.mobile} {
    margin-top: 4vw;
    margin-bottom: 3vw;
    width: 90%;
  }
`;

const BodyCopy = styled(BodyCopy1)`
  ${media.mobile} {
    width: 85%;
  }
`;

const ClaimFeature = styled(Feature)`
  ${SubHeading} {
    width: 120%;
  }
  ${media.mobile} {
    ${SubHeading} {
      width: 90%;
    }
  }
`;

const RiskFeature = styled(Feature)`
  ${media.mobile} {
    ${SubHeading} {
      width: 65%;
    }
  }
`;

const DataFeature = styled(Feature)`
  ${media.mobile} {
    ${SubHeading} {
      width: 65%;
    }
  }
`;

const ProductFeature = styled(Feature)`
  ${media.mobile} {
    ${SubHeading} {
      width: 58%;
    }
  }
`;

const FeatureIconStyles = `
  width: 12vw;

  ${media.mobile} {
    width: 30vw;
    height: auto;
    margin-bottom: 1vw;
  }
`;

const ClaimIcon = styled(ClaimIconSVG)`
  ${FeatureIconStyles}
`;

const ReducedRiskIcon = styled(ReducedRiskIconSVG)`
  ${FeatureIconStyles}

  .overview-icon6-arrow {
    transform-origin: 50% 57%;
  }
`;

const DataIcon = styled(DataIconSVG)`
  ${FeatureIconStyles}

  .overview-icon7-box {
    transform-origin: 50% 50%;
  }
`;

const ProductIcon = styled(ProductIconSVG)`
  ${FeatureIconStyles}
`;

const Heading = styled.div`
  padding-right: 6.9vw;
  margin-bottom: 6.7vw;

  ${media.mobile} {
    padding-right: 0vw;
    margin-bottom: 15vw;
    width: 90%;
  }
`;

export default Reporting;
