import React, { useState, useRef, useEffect, useContext } from "react";
import { useForm } from "@formspree/react";
import styled from "styled-components";
import Colors from "styles/Colors";
import media from "styles/media";
import Easings from "styles/Easings";
import gsap from "gsap";
import { Heading1, BodyCopy1 } from "components/Text";
import DropdownArrowPNG from "assets/images/DropdownArrow.png";
import OrangeArrow from "assets/svg/OrangeArrow.svg";
import { SetSuccessViewContext, DesktopContext, MobileContext } from "App";
import { ReactComponent as LogoSVG } from "assets/svg/Logo.svg";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

const RequestDemoForm: React.FC<{ visible: boolean; setVisible: any; landingPage?: boolean }> = ({
  visible,
  setVisible,
  landingPage = false
}) => {
  const setSuccessView = useContext(SetSuccessViewContext);
  const desktop = useContext(DesktopContext);
  const mobile = useContext(MobileContext);

  const [formState, submitForm] = useForm("myylezaw");

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [solution, setSolution] = useState("");
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  // const [height, setHeight] = useState(0);

  const wrapperRef = useRef(null);
  const dropdownRef = useRef(null);
  const realEstateRef = useRef(null);
  const propertyInsuranceRef = useRef(null);
  const propertyManagementRef = useRef(null);
  const homeWarrantyRef = useRef(null);
  const otherRef = useRef(null);

  useEffect(() => {
    if (visible) {
      gsap.to(wrapperRef.current, {
        duration: 1,
        zIndex: 997,
        opacity: 1,
        onComplete: () => {
          if (!landingPage) {
            //@ts-ignore
            disableBodyScroll(wrapperRef.current);
          }
        },
      });
    } else {
      gsap.to(wrapperRef.current, {
        duration: 1,
        zIndex: -1,
        opacity: 0,
        onComplete: () => {
          clearAllBodyScrollLocks();
        },
      });
    }
  }, [visible, landingPage]);

  // useEffect(() => {
  //   if (mobile) {
  //     const dumbIOSHack = window.screen.availHeight;
  //     console.log(dumbIOSHack);
  //     setHeight(dumbIOSHack);
  //   }
  // }, [mobile]);

  useEffect(() => {
    const validateForm = () => {
      let regex = /^(?!\s*$).+/;
      if (
        fullName.match(regex) &&
        email.match(regex) &&
        companyName.match(regex) &&
        jobTitle.match(regex) &&
        phoneNumber.match(regex) &&
        message.match(regex) &&
        solution.match(regex)
      ) {
        setIsFormValid(true);
      }
    };
    validateForm();
  }, [fullName, email, companyName, jobTitle, phoneNumber, message, solution]);

  useEffect(() => {
    if (formState.succeeded) {
      setVisible(false);
      setSuccessView(true);
    }
    // eslint-disable-next-line
  }, [formState]);

  const onInputChange = (value: string, key: string) => {
    switch (key) {
      case "Full Name":
        setFullName(value);
        break;

      case "Email Address":
        setEmail(value);
        break;

      case "Company Name":
        setCompanyName(value);
        break;

      case "Job Title":
        setJobTitle(value);
        break;

      case "Phone Number":
        setPhoneNumber(value);
        break;

      case "Message":
        setMessage(value);
        break;

      case "Solution":
        setSolution(value);
        break;

      default:
        break;
    }
  };

  const openDropDown = () => {
    setDropDownOpen(true);
    setSolution("");
    resetDropDownAnimations();

    let height = desktop ? "13.5vw" : mobile ? "60vw" : "24vw";
    gsap.to(dropdownRef.current, {
      duration: 0.5,
      height,
    });
  };

  const closeDropDown = (value?: string) => {
    let height = desktop ? "2vw" : mobile ? "8.5vw" : "4vw";

    gsap.to(dropdownRef.current, {
      duration: 0.5,
      height,
      onComplete: () => {
        setDropDownOpen(false);
      },
    });

    switch (value) {
      case "Real Estate":
        realEstateAnimation();
        break;

      case "Property Insurance":
        propertyInsuranceAnimation();
        break;

      case "Property Management":
        propertyManagementAnimation();
        break;

      case "Home Warranty":
        homeWarrantyAnimation();
        break;

      case "Other":
        otherAnimation();
        break;

      default:
        break;
    }
  };

  const realEstateAnimation = () => {
    gsap.to(realEstateRef.current, {
      duration: 0.2,
      delay: 0.225,
      top: desktop ? "-2.3vw" : mobile ? "-8vw" : "-4vw",
      zIndex: 9,
    });
  };

  const propertyInsuranceAnimation = () => {
    gsap.to(propertyInsuranceRef.current, {
      duration: 0.34,
      delay: 0.175,
      top: desktop ? "-4.6vw" : mobile ? "-16.1vw" : "-8vw",
      zIndex: 9,
    });
  };

  const propertyManagementAnimation = () => {
    gsap.to(propertyManagementRef.current, {
      duration: 0.375,
      delay: 0.115,
      top: desktop ? "-7vw" : mobile ? "-24.4vw" : "-12vw",
      zIndex: 9,
    });
  };

  const homeWarrantyAnimation = () => {
    gsap.to(homeWarrantyRef.current, {
      duration: 0.48,
      delay: 0.045,
      top: desktop ? "-9.2vw" : mobile ? "-32.5vw" : "-16vw",
      zIndex: 9,
    });
  };

  const otherAnimation = () => {
    gsap.to(otherRef.current, {
      duration: 0.49,
      delay: 0.01,
      top: desktop ? "-11.5vw" : mobile ? "-40.5vw" : "-20vw",
      zIndex: 9,
    });
  };

  const handleSolutionClick = (e: any, value: string) => {
    e.stopPropagation();

    if (dropDownOpen) {
      onInputChange(value, "Solution");
      closeDropDown(value);
    } else {
      openDropDown();
    }
  };

  const resetDropDownAnimations = () => {
    gsap.to(realEstateRef.current, {
      duration: 0.19,
      top: "0vw",
      zIndex: 0,
    });
    gsap.to(propertyInsuranceRef.current, {
      duration: 0.34,
      top: "0vw",
      zIndex: 0,
    });
    gsap.to(propertyManagementRef.current, {
      duration: 0.34,
      top: "0vw",
      zIndex: 0,
    });
    gsap.to(homeWarrantyRef.current, {
      duration: 0.375,
      top: "0vw",
      zIndex: 0,
    });
    gsap.to(otherRef.current, {
      duration: 0.5,
      top: "0vw",
      zIndex: 0,
    });
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();

    submitForm(e);
  };

  return (
    <Wrapper ref={wrapperRef} className={landingPage ? 'landing' : ''}>
      <BorderContainer>
        {mobile && !landingPage && <Logo />}
        {!landingPage &&
        <TextContainer>
          <Heading>
            Adding Source7 to your processes is a no-brainer. <br />
            <Orange>Get Started Today</Orange>
          </Heading>
          <Text>
            Fill out the form and we will be in touch as soon as possible.
          </Text>
        </TextContainer>
        }
        <Form onSubmit={handleFormSubmit}>
          <Input
            required
            type="text"
            highlighted={!!fullName}
            name="Full Name"
            value={fullName}
            onChange={(e) => onInputChange(e.target.value, "Full Name")}
            placeholder="Full Name *"
          />
          <Input
            required
            type="email"
            highlighted={!!email}
            name="Email Address"
            value={email}
            onChange={(e) => onInputChange(e.target.value, "Email Address")}
            placeholder="Email Address *"
          />
          <Input
            required
            type="text"
            highlighted={!!companyName}
            name="Company Name"
            value={companyName}
            onChange={(e) => onInputChange(e.target.value, "Company Name")}
            placeholder="Company Name *"
          />
          <FlexRowContainer>
            <Input
              required
              type="text"
              highlighted={!!jobTitle}
              name="Job Title"
              value={jobTitle}
              onChange={(e) => onInputChange(e.target.value, "Job Title")}
              placeholder="Job Title"
              width={!mobile ? "48%" : "100%"}
            />
            <Input
              required
              type="phone"
              highlighted={!!phoneNumber}
              name="Phone Number"
              value={phoneNumber}
              onChange={(e) => onInputChange(e.target.value, "Phone Number")}
              placeholder="Phone Number"
              width={!mobile ? "48%" : "100%"}
            />
          </FlexRowContainer>
          <TextArea
            required
            highlighted={!!message}
            name="Message"
            value={message}
            onChange={(e) => onInputChange(e.target.value, "Message")}
            placeholder="Message..."
          />
          <input readOnly hidden name="Solution" value={solution} required />
          <DropDown
            tabIndex={0}
            onClick={
              dropDownOpen ? () => closeDropDown() : () => openDropDown()
            }
            ref={dropdownRef}
          >
            <P highlighted={desktop ? dropDownOpen : true}>
              What solution interests you?
            </P>
            <P
              ref={realEstateRef}
              highlighted={solution === "Real Estate" ? true : false}
              onClick={(e) => handleSolutionClick(e, "Real Estate")}
            >
              Real Estate
            </P>
            <P
              ref={propertyInsuranceRef}
              highlighted={solution === "Property Insurance" ? true : false}
              onClick={(e) => handleSolutionClick(e, "Property Insurance")}
            >
              Property Insurance
            </P>
            <P
              ref={propertyManagementRef}
              highlighted={solution === "Property Management" ? true : false}
              onClick={(e) => handleSolutionClick(e, "Property Management")}
            >
              Property Management
            </P>
            <P
              ref={homeWarrantyRef}
              highlighted={solution === "Home Warranty" ? true : false}
              onClick={(e) => handleSolutionClick(e, "Home Warranty")}
            >
              Home Warranty
            </P>
            <P
              ref={otherRef}
              highlighted={solution === "Other" ? true : false}
              onClick={(e) => handleSolutionClick(e, "Other")}
            >
              Other
            </P>
            <Img
              src={DropdownArrowPNG}
              alt="arrow"
              rotated={dropDownOpen ? true : false}
            />
          </DropDown>
          <Button disabled={!isFormValid} highlighted={isFormValid}>
            Submit Form
          </Button>
        </Form>
      </BorderContainer>
    </Wrapper>
  );
};

export default RequestDemoForm;

const Wrapper = styled.section`
  background-color: ${Colors.gunMetal100};
  position: fixed;
  top: 0;
  min-height: 100vh;
  height: 100%;
  width: calc(100vw - 12vw);
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4vw 4vw 8vw 10vw;
  box-sizing: border-box;
  right: 0vw;

  &.landing {
    position: static;
    min-height: unset;
    height: unset;
    width: 40vw;
    padding: 0;
    border: 2px solid ${Colors.industryOrange100};
    padding: 2.5vw;
  }

  ${media.fullWidth} {
    padding: 64px 64px 128px 160px;
  }

  ${media.tablet} {
    width: 90vw;
    height: 100vh;
    overflow-y: auto;
    bottom: 0vw;
  }

  ${media.mobile} {
    width: calc(100vw - 15vw);
    padding-left: 5vw;

    &.landing {
      width: 100%;
      padding: 4vw 3vw;
    }
  }
`;

const BorderContainer = styled.div`
  width: 100%;
  height: 100%;
  border-top: 1px solid ${Colors.gunMetal80};
  display: flex;
  justify-content: space-between;
  padding-top: 3.6vw;
  box-sizing: border-box;

  .landing & {
    padding: 0;
    border-top: none;
  }

  ${media.fullWidth} {
    padding-top: 57px;
  }

  ${media.tablet} {
    flex-direction: column;
    justify-content: unset;
    border-top: none;
    padding-bottom: 5vw;
    padding-top: 0vw;
  }

  ${media.mobile} {
    flex-direction: column;
    border-top: none;
    margin-bottom: 300px;
    margin-top: 300px;

    .landing & {
      margin: 0;
    }
  }
`;

const TextContainer = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;

  ${media.tablet} {
    width: 100%;
    margin-bottom: 10vw;
  }

  ${media.mobile} {
    width: 100%;
    margin-bottom: 15vw;
  }
`;

const Heading = styled(Heading1)`
  color: ${Colors.bone100};
  font-size: 4vw;
  margin-bottom: 3vw;

  ${media.fullWidth} {
    font-size: 64px;
    margin-bottom: 48px;
  }

  ${media.tablet} {
    font-size: 6vw;
  }

  ${media.mobile} {
    font-size: 8vw;
    width: 90%;
  }
`;

const Orange = styled.strong`
  color: ${Colors.industryOrange100};
`;

const Text = styled(BodyCopy1)`
  color: ${Colors.bone100};
  width: 60%;
  font-size: 1.3vw;

  ${media.fullWidth} {
    font-size: 20.8px;
  }

  ${media.tablet} {
    font-size: 2.6vw;
  }

  ${media.mobile} {
    font-size: 4vw;
    width: 100%;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 45%;
  position: relative;
  height: 100%;

  .landing & {
    width: 100%;
    padding-bottom: 5vw;
  }

  ${media.tablet} {
    width: 100%;

    .landing & {
      padding-bottom: 0;
    }
  }

  ${media.mobile} {
    
  }
`;

const InputStyles = `
  border: none;
  border-bottom: 1px solid ${Colors.industryOrange100};
  background-color: ${Colors.gunMetal100};
  padding-left: 0vw;
  padding-bottom: 0.8vw;
  color: ${Colors.bone100};
  font-size: 1.1vw;
  margin-bottom: 1.2vw;
  font-family: Roobert;
  position: relative;
  box-sizing: border-box;
  border-radius: 0px;

  ::placeholder {
    color: ${Colors.bone100};
    opacity: 0.3;
  }

  ${media.fullWidth} {
    padding-bottom: 12.8px;
  }

  ${media.tablet} {
    height: 5vw;
    font-size: 2vw;
  }

  ${media.mobile} {
    height: 10vw;
    border-width: 0.4vw;
    border-color: ${Colors.industryOrange100};
    font-size: 4vw;
    font-weight: 100;

    ::placeholder {
      color: ${Colors.bone100};
      opacity: 1;
    }
  }
`;

const Input = styled.input<{ width?: string; highlighted?: boolean }>`
  ${InputStyles}
  height: 2.5vw;
  width: ${(props) => (props.width ? props.width : "100%")};

  ${media.fullWidth} {
    height: 40px;
  }
  ${media.mobile} {
    opacity: ${(props) => (props.highlighted ? "1" : "0.5")};
  }
`;

const TextArea = styled.textarea<{ highlighted?: boolean }>`
  ${InputStyles}
  width: 100%;
  height: 11vw;
  resize: none;
  margin-bottom: 1.5vw;

  ${media.fullWidth} {
    height: 200px;
    margin-bottom: 24px;
  }

  ${media.tablet} {
    height: 20vw;

    .landing & {
      height: 12vw;
    }
  }

  ${media.mobile} {
    height: 40vw;
    padding-top: 5vw;
    opacity: ${(props) => (props.highlighted ? "1" : "0.5")};
  }
`;

const DropDown = styled.div`
  ${InputStyles}
  overflow: hidden;
  height: 2vw;
  position: relative;
  background-color: ${Colors.gunMetal100};
  z-index: 10;
  margin-bottom: 0vw;

  ${media.tablet} {
    margin-bottom: 10vw;
    height: 4vw;

    .landing & {
      margin-bottom: 10vw;
    }
  }

  ${media.mobile} {
    padding-top: 2vw;
    height: 8.5vw;
    margin-bottom: 10vw;
  }
`;

const P = styled.p<{ highlighted?: boolean }>`
  padding-bottom: 1.2vw;
  color: ${Colors.bone100};
  opacity: ${(props) => (props.highlighted ? 1 : 0.3)};
  position: relative;
  cursor: pointer;
  background-color: ${Colors.gunMetal100};

  ${media.tablet} {
    padding-bottom: 2vw;
  }

  ${media.mobile} {
    padding-bottom: 4.2vw;
  }
`;

const FlexRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${media.mobile} {
    flex-direction: column;
  }
`;

const Img = styled.img<{ rotated: boolean }>`
  width: 1vw;
  height: 1vw;
  position: absolute;
  top: 0vw;
  right: 1vw;
  transform: ${(props) => (props.rotated ? "rotate(180deg)" : "rotate(0deg)")};
  transition-duration: 0.5s;
  z-index: 10;
  cursor: pointer;

  ${media.fullWidth} {
    width: 16px;
    height: 16px;
    right: 16px;
  }

  ${media.tablet} {
    width: 2vw;
    height: 2vw;
  }

  ${media.mobile} {
    width: 3vw;
    height: 3vw;
    top: 3vw;
    right: 0vw;
  }
`;

const Button = styled.button<{ highlighted?: boolean }>`
  color: ${Colors.bone100};
  font-size: 1vw;
  font-family: Roobert;
  text-align: left;
  letter-spacing: -0.02em;
  position: absolute;
  padding: 0 1vw 0.5vw 0;
  width: 7vw;
  border-bottom: 1px solid ${Colors.industryOrange100};
  bottom: 0vw;
  right: 0vw;
  cursor: pointer;

  ::after {
    background: url(${OrangeArrow}) center/contain no-repeat;
    content: "";
    display: block;
    height: 10px;
    width: 10px;
    object-fit: contain;
    position: absolute;
    right: 0;
    top: 4px;
    transition: all 700ms cubic-bezier(${Easings.easeInOutCub});
  }

  ${media.fullWidth} {
    font-size: 16px;
    padding: 0 16px 8px 0;
    width: 112px;
  }

  ${media.tablet} {
    font-size: 2vw;
    width: 14vw;
    margin-bottom: 5vw;
    position: relative;

    opacity: ${(props) => (props.highlighted ? 1 : 0.5)};

    .landing & {
      margin-bottom: 0;
    }
  }

  ${media.mobile} {
    position: relative;
    width: 27vw;
    font-size: 4vw;
    font-weight: 100;
    padding-bottom: 2vw;
    margin-bottom: 12vw;
    opacity: ${(props) => (props.highlighted ? 1 : 0.5)};

    ::after {
      height: 3vw;
      width: 3vw;
    }
  }
`;

const Logo = styled(LogoSVG)`
  overflow: unset;
  width: 33vw;
  height: 12vw;
  margin-bottom: 15vw;

  ${media.fullWidth} {
    width: 528px;
    height: 192px;
    margin-bottom: 240px;
  }
`;
