import React, { useRef, useEffect, useState, useContext } from "react";
import { SetRequestDemoContext } from "App";
import styled from "styled-components";
import { ButtonRegularStyles } from "components/Buttons";
import { Heading1, Heading2Small, BodyCopy2 } from "components/Text";
import Colors from "styles/Colors";
import { ReactComponent as LogoSVG } from "assets/svg/Logo.svg";
import HouseAnimation from "components/HouseAnimation";
import gsap from "gsap";

import media from "styles/media";

const Hero: React.FC<{}> = () => {
  const topBracketPath = useRef(null);
  const bottomBracketPath = useRef(null);
  const topBracket = useRef(null);
  const bottomBracket = useRef(null);
  const [headerOpacity, setHeaderOpacity] = useState(false);
  const logoWrapper = useRef(null);
  const setRequestDemo = useContext(SetRequestDemoContext);

  useEffect(() => {
    gsap.set([bottomBracketPath.current, topBracketPath.current], {
      drawSVG: "0 0",
      opacity: 1,
    });

    const tl = gsap.timeline();

    tl.to(
      [bottomBracketPath.current, topBracketPath.current],
      {
        drawSVG: "100 0",
        duration: 0.5,
      },
      0
    )
      .to(logoWrapper.current, { opacity: 1, duration: 3 }, 0.1)
      .to(topBracket.current, { top: "0", left: "-=7.5vw", duration: 0.5 }, 0.6)
      .to(
        bottomBracket.current,
        { bottom: "0", right: "-=7.5vw", duration: 0.5 },
        0.6
      )
      .to(topBracket.current, { left: "0", duration: 0.5 }, 1.1)
      .to(
        bottomBracket.current,
        { right: "0", duration: 0.5, onComplete: () => setHeaderOpacity(true) },
        1.1
      )
      .to(
        [bottomBracketPath.current, topBracketPath.current],
        {
          drawSVG: "0 0",
          duration: 0.5,
        },
        1.6
      );

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <Wrapper id="home-hero">
      <TextWrapper>
        <LogoWrapper ref={logoWrapper}>
          <Logo />
        </LogoWrapper>
        <HeadingWrapper>
          <TopBracket ref={topBracket}>
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                ref={topBracketPath}
                d="M56 2H2V56"
                stroke="#F09C4E"
                strokeWidth="3"
              />
            </svg>
          </TopBracket>

          <BottomBracket ref={bottomBracket}>
            <svg
              width="56"
              height="56"
              viewBox="0 0 56 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                ref={bottomBracketPath}
                d="M0 54L54 54L54 -2.38419e-06"
                stroke="#F09C4E"
                strokeWidth="3"
              />
            </svg>
          </BottomBracket>
          <Heading visible={headerOpacity ? 0 : 1}>
            Stop Reacting.
          </Heading>
          <HeadingSmall visible={headerOpacity ? 0 : 1}>
            Start Proactively Managing Home Appliances to Drive Your Business
          </HeadingSmall>
        </HeadingWrapper>
        <Text visible={headerOpacity ? 0 : 1}>
          All the intelligence you need on your home appliance and major mechanicals to make proactive, data-driven decisions for your 
          properties that reduce risks, improve operations, and increase profitability 
        </Text>

        <Button
          visible={headerOpacity ? 0 : 1}
          onClick={() => setRequestDemo(true)}
        >
          Learn More
        </Button>
      </TextWrapper>
      <HouseAnimation visible={headerOpacity ? 0 : 1} />
    </Wrapper>
  );
};

const Wrapper = styled.section`
  min-height: 48.5vw;
  padding: 3.5vw 0 0 21.4vw;
  position: relative;
  background: ${Colors.gunMetal100};
  overflow: hidden;
  box-sizing: border-box;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;

  ${media.tablet} {
    padding: 3.5vw 0 0 18vw;
  }

  ${media.mobile} {
    width: 100%;
    height: 205vw;
    padding: 6vw 5vw 0vw 20vw;
  }
`;

const TextWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const Logo = styled(LogoSVG)`
  margin-bottom: 4.8vw;

  ${media.mobile} {
    width: 33vw;
    height: 12vw;
    margin-bottom: 9vw;
  }
`;

const Heading = styled(Heading1)<{ visible: number }>`
  color: ${Colors.industryOrange100};
  margin-bottom: 1vw;
  width: 52.1vw;
  opacity: ${(props) => (props.visible === 0 ? 1 : 0)};
  transition: opacity 0.5s;
  ${media.mobile} {
    width: 80%;
    margin-bottom: 3vw;
  }
`;

const HeadingSmall = styled(Heading2Small)<{ visible: number }>`
  color: ${Colors.bone100};
  margin-bottom: 2.4vw;
  width: 41.7vw;
  opacity: ${(props) => (props.visible === 0 ? 1 : 0)};

  ${media.mobile} {
    width: 100%;
    margin-bottom: 8vw;
  }
`;

const Text = styled(BodyCopy2)<{ visible: number }>`
  color: ${Colors.bone100};
  margin-bottom: 2.4vw;
  width: 31.8vw;
  opacity: ${(props) => (props.visible === 0 ? 1 : 0)};
  transition: opacity 0.5s;
  ${media.mobile} {
    width: 91%;
    margin-bottom: 9vw;
  }
`;

const HeadingWrapper = styled.div`
  position: relative;
  width: fit-content;
  ${media.mobile} {
  }
`;

const TopBracket = styled.div`
  position: absolute;
  top: calc(50% - 2.2vw);
  left: calc(50% - 2.2vw);
  width: 3.8vw;
  height: 3.8vw;
  opacity: 1;
  svg {
    width: 100%;
    height: 100%;
  }
  ${media.mobile} {
  }
`;

const BottomBracket = styled.div`
  position: absolute;
  width: 3.8vw;
  height: 3.8vw;
  bottom: calc(50% - 2.2vw);
  right: calc(50% - 2.2vw);
  opacity: 1;
  svg {
    width: 100%;
    height: 100%;
  }
  ${media.mobile} {
  }
`;

const LogoWrapper = styled.div`
  opacity: 0;
  ${media.mobile} {
  }
`;

const Button = styled.button<{ visible?: number }>`
  ${ButtonRegularStyles}

  opacity: ${(props) => (props.visible === 0 ? 1 : 0)};
  ${media.mobile} {
    width: 34vw;
  }
`;

export default Hero;
