import React from 'react'
import styled from 'styled-components'
import media from 'styles/media'
import { Heading5 } from 'components/Text'

type Props = {
  fill: string
}

const SectionAccent: React.FC<Props> = ({ children, fill }) => {
  return <Heading fill={fill}>
    {children}
  </Heading>
}

const Heading = styled(Heading5)<{ fill: string }>`
  border-top: 2px solid ${props => props.fill};
  color: ${props => props.fill};
  padding-top: 1.5vw;
  text-align: right;
  width: 100%;
  font-size: 1.5vw;

  ${media.mobile} {
    font-size: 4vw;
  }
`

export default SectionAccent
