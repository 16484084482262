const media = {
  tablet: "@media screen and (max-width: 1024px)",
  tabletOnly: "@media screen and (min-width: 768px) and (max-width: 1024px)",
  tabletUp: "@media screen and (min-width: 768px)",
  mobile: "@media screen and (max-width: 767px)",
  tabletLandscape: "@media screen and (max-width: 1200px)",
  desktop: "@media screen and (max-width: 1679px)",
  desktopUp: "@media screen and (min-width: 1025px)",
  fullWidth: "@media screen and (min-width: 1680px)",
  hover: "@media (hover: hover) ",
  noHover: "@media (hover: none) ",
  portraitTablet:
    "@media screen and (max-width: 1200px) and (min-width: 768px) and (orientation: portrait)",
};

export default media;
