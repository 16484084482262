//@ts-nocheck
import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import {
  DesktopContext,
  TabletContext,
  SetRequestDemoContext,
  SetSuccessViewContext,
  SelectContext,
} from "App";
import styled from "styled-components";
import media from "styles/media";
import Colors from "styles/Colors";
import gsap from "gsap";
import SubMenu from "./SubMenu";
import { MenuItem, MenuItemSmall } from "components/Text";
import { ButtonBack } from "components/Buttons";
import { ReactComponent as LogoSVG } from "assets/svg/Logo.svg";

type Props = {
  toggleNav?: any;
  selectRef: any;
  history: any;
  selectNum: number;
};

const sfrMfrCopy = {
  title: "Single & Multi-Family Rental Operators",
  url: "sfr-mfr",
  sections: [
    ["#intelligence", "Business Intelligence"],
    ["#benefits", "Benefits"],
    ["#contact", "Next Steps"],
  ],
};

const realEstateCopy = {
  title: "Real Estate Technology Providers",
  url: "real-estate",
  sections: [
    ["#intelligence", "Business Intelligence"],
    ["#benefits", "Benefits"],
    ["#contact", "Next Steps"],
  ],
};

const insuranceCopy = {
  title: "Insurance Companies",
  url: "insurance",
  sections: [
    ["#intelligence", "Business Intelligence"],
    ["#benefits", "Benefits"],
    ["#contact", "Next Steps"],
  ],
};

const warrantyCopy = {
  title: "Home Warranty & Service Providers",
  url: "home-warranty",
  sections: [
    ["#intelligence", "Business Intelligence"],
    ["#benefits", "Benefits"],
    ["#contact", "Next Steps"],
  ],
};

const termsCopy = {
  title: "Terms of\nUse",
  url: "terms-of-use",
  sections: [],
};

const privacyCopy = {
  title: "Privacy",
  url: "privacy",
  sections: [],
};

const jobsCopy = {
  title: "Jobs",
  url: "jobs",
  sections: [],
};

const integrationsCopy = {
  title: "Integrations",
  url: "integrations",
  sections: [],
};

const findCopy = {
  title: "Help Find Product Label",
  url: "find-product-label",
  sections: [],
};

const Menu: React.FC<Props> = ({
  toggleNav,
  selectRef,
  history,
  selectNum,
}) => {
  const desktop = useContext(DesktopContext);
  const tablet = useContext(TabletContext);
  const setRequestDemo = useContext(SetRequestDemoContext);
  const setSuccessView = useContext(SetSuccessViewContext);
  const setSelectNum = useContext(SelectContext);

  const [selectPos, setSelectPos] = useState(0);
  // eslint-disable-next-line
  const [active, setActive] = useState(0);
  const [lastActive, setLastActive] = useState(0);
  const [menuState, setMenuState] = useState(0);
  const contentRef = useRef(null);
  const wrapperRef = useRef(null);

  const positionsDesktop = useMemo(() => [3.8, 7, 10, 22.4, 25.8, 28.8], []);
  const positionsTablet = useMemo(
    () => [17.5, 25, 31, 38, 43.8, 51, 58.5, 65.5, 72.5, 79.5],
    []
  );
  const positionsMobile = useMemo(
    () => [28, 40, 52, 63, 76, 90, 104, 117, 128, 140],
    []
  );

  const { pathname } = history.location;

  useEffect(() => {
    const footerLinks = [
      "/find-product-label",
      "/integrations",
      "/terms-of-use",
      "/privacy",
    ];

    const footerLink = footerLinks.includes(pathname);

    if (desktop || footerLink) {
      const pathOptions = [
        "/",
        "/sfr-mfr",
        "/real-estate",
        "/insurance",
        "/home-warranty",
        "/terms-of-use",
        "/privacy",
        "/jobs",
        "/integrations",
        "/find-product-label",
      ];
      let index = pathOptions.indexOf(pathname);

      if (index <= 0) {
        index = 0;
        handleBack();
      }

      handleMenuState(index);
    }

    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    const positions = desktop
      ? positionsDesktop
      : tablet
      ? positionsTablet
      : positionsMobile;

    setSelectPos(positions[selectNum]);
    setActive(selectNum);

    // eslint-disable-next-line
  }, [selectNum]);

  const handleMenuState = (num: number) => {
    setMenuState(num);
    setLastActive(active);

    if (num !== 0) {
      gsap.to(wrapperRef.current, {
        duration: 0.5,
        opacity: 0,
      });
    }
  };

  const handleHomeClick = (num: number, scrollId?: string) => {
    setSuccessView(false);
    setRequestDemo(false);

    gsap.to(window, {
      scrollTo: {
        y: scrollId,
      },
      onComplete: () => {
        if (!desktop) {
          toggleNav();
        }
      },
    });
  };

  const handleSubLinkClick = (num: number) => {
    setSuccessView(false);
    setRequestDemo(false);

    if (desktop) {
      setMenuState(num);
    } else {
      setSelectNum(num + 2);
      setTimeout(toggleNav, 400);
      setActive(num + 2);
    }
  };

  const handleBack = () => {
    const sectionOptions = [
      "#root",
      "#overview",
      "#solutions",
      "#company",
      "#contact",
    ];
    setMenuState(0);
    handleHomeClick(lastActive);
    gsap.to(window, { scrollTo: sectionOptions[lastActive], duration: 0.1 });
    gsap.to(wrapperRef.current, {
      duration: 1,
      opacity: 1,
    });
  };

  return (
    <Content ref={contentRef}>
      <Wrapper ref={wrapperRef}>
        {!desktop && <Logo />}
        <Select position={selectPos} ref={selectRef} />
        <NavLink
          $activeEl={active === 0}
          to="/"
          onClick={() => handleHomeClick(0, "#root")}
        >
          Home
        </NavLink>
        <NavLink
          $activeEl={active === 1}
          to="/"
          onClick={() => handleHomeClick(1, "#overview")}
        >
          Overview
        </NavLink>
        <NavLink
          $activeEl={active === 2}
          to="/"
          onClick={() => handleHomeClick(2, "#solutions")}
        >
          Solutions
        </NavLink>

        <NavLinkSubHome
          to="/sfr-mfr"
          onClick={() => handleSubLinkClick(1)}
          $activeEl={active === 3 && !desktop}
        >
          Single & Multi-Family Rental Operators
        </NavLinkSubHome>
        <NavLinkSubHome
          to="/real-estate"
          $activeEl={active === 4 && !desktop}
          onClick={() => handleSubLinkClick(2)}
        >
          Real Estate Technology Providers
        </NavLinkSubHome>
        <NavLinkSubHome
          to="/insurance"
          onClick={() => handleSubLinkClick(3)}
          $activeEl={active === 5 && !desktop}
        >
          Insurance Companies
        </NavLinkSubHome>
        <NavLinkSubHome
          to="/home-warranty"
          onClick={() => handleSubLinkClick(4)}
          $activeEl={active === 6 && !desktop}
        >
          Home Warranty & Service Providers
        </NavLinkSubHome>
        <NavLink
          $activeEl={active === (desktop ? 3 : 7)}
          to="/"
          onClick={() => handleHomeClick(desktop ? 3 : 7, "#integrations")}
        >
          Integrations
        </NavLink>
        <NavLink
          $activeEl={active === (desktop ? 4 : 8)}
          to="/"
          onClick={() => handleHomeClick(desktop ? 4 : 8, "#company")}
        >
          Company
        </NavLink>
        <NavLinkSubHome 
            $activeEl={false}
            to="/"
            onClick={() => window.open("https://jobs.source7.com")}
        >
          Careers
        </NavLinkSubHome>
        <NavLink
          $activeEl={active === (desktop ? 5 : 9)}
          to="/"
          onClick={() => handleHomeClick(desktop ? 5 : 9, "#contact")}
        >
          Contact
        </NavLink>
        <NavLink
          $activeEl={active === (desktop ? 6 : 10)}
          to="/"
          onClick={() => window.open("https://app.source7.com")}
        >
          Login
        </NavLink>
      </Wrapper>

      <SubMenu
        copy={sfrMfrCopy}
        handleBack={handleBack}
        visible={menuState === 1}
        selectNum={selectNum}
        tallTitle={true}
      />
      <SubMenu
        copy={realEstateCopy}
        handleBack={handleBack}
        visible={menuState === 2}
        selectNum={selectNum}
        tallTitle={true}
      />
      <SubMenu
        copy={insuranceCopy}
        handleBack={handleBack}
        visible={menuState === 3}
        selectNum={selectNum}
      />
      <SubMenu
        copy={warrantyCopy}
        handleBack={handleBack}
        visible={menuState === 4}
        selectNum={selectNum}
        tallTitle={true}
      />
      <SubMenu
        copy={privacyCopy}
        handleBack={handleBack}
        visible={menuState === 6}
        selectNum={selectNum}
      />
      <SubMenu
        copy={termsCopy}
        handleBack={handleBack}
        visible={menuState === 5}
        selectNum={selectNum}
      />
      <SubMenu
        copy={jobsCopy}
        handleBack={handleBack}
        visible={menuState === 7}
        selectNum={selectNum}
      />
      <SubMenu
        copy={integrationsCopy}
        handleBack={handleBack}
        visible={menuState === 8}
        selectNum={0}
      />
      <SubMenu
        copy={findCopy}
        handleBack={handleBack}
        visible={menuState === 9}
        selectNum={0}
      />
    </Content>
  );
};

export default Menu;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding-top: 4vw;
  padding-left: 2.5vw;
  padding-right: 1vw;

  ${media.tablet} {
    padding-top: 5vw;
    padding-left: 15vw;
  }

  ${media.mobile} {
    padding-top: 10vw;
    padding-left: 25vw;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled(LogoSVG)`
  ${media.tablet} {
    width: 10vw;
    height: auto;
    margin-bottom: 10vw;
  }

  ${media.mobile} {
    width: 32vw;
  }
`;

export const Select = styled.div<{ position: number }>`
  height: 1.8vw;
  width: 0.3vw;
  background: ${Colors.industryOrange100};
  position: absolute;
  top: ${(props) => props.position}vw;
  transition: 400ms;
  right: 0vw;

  ${media.tablet} {
    height: 30px;
    width: 6px;
    right: 0vw;
    top: ${(props) => props.position}vw;
    z-index: 1000;
  }
`;

const NavLinkSubHome = styled(MenuItemSmall)<{ $activeEl?: boolean }>`
  font-family: Roobert;
  line-height: 100%;
  text-decoration: none;
  color: ${(props) =>
    props.$activeEl ? Colors.industryOrange100 : Colors.bone100};
  text-align: left;
  opacity: 0.6;
  transition: 0.4s;
  cursor: pointer;
  font-size: 0.8vw;
  margin-bottom: 1.3vw;

  ${media.tablet} {
    font-size: 2vw;
    padding-bottom: 3vw;
    position: relative;
    left: 2vw;
  }

  ${media.mobile} {
    font-size: 4vw;
    padding-bottom: 7vw;
    left: 6vw;
  }
`;

export const NavLink = styled(MenuItem)<{ $activeEl?: boolean }>`
  text-decoration: none;
  color: ${(props) =>
    props.$activeEl ? Colors.industryOrange100 : Colors.bone100};
  text-align: left;
  padding-bottom: 2vw;
  white-space: pre-wrap;

  ${media.tablet} {
    font-size: 3vw;
    padding-bottom: 4vw;
  }

  ${media.mobile} {
    font-size: 5vw;
    padding-bottom: 7vw;
  }
`;

export const NavLinkSub = styled.p`
  color: ${Colors.industryOrange60};
  font-size: 0.8vw;
  font-family: "Roobert";
  line-height: 100%;
  padding-bottom: 1vw;
  text-align: left;

  ${media.tablet} {
  }
`;

export const LinkWrap = styled.div<{ activeEl: boolean }>`
  text-align: left;
  padding-bottom: 2vw;

  ${NavLink} {
    color: ${(props) =>
      props.activeEl ? Colors.industryOrange100 : Colors.bone100};
    transition: 0.4s;
  }

  ${NavLinkSub} {
    text-align: left;
    opacity: ${(props) => (props.activeEl ? "1" : "0.4")};
    transition: 0.4s;
  }

  ${media.tablet} {
    padding-bottom: 6vw;
  }
`;

export const Back = styled(ButtonBack)`
  margin-bottom: 1.2vw;
  width: 2.6vw;
  ${media.tablet} {
    margin-bottom: 6vw;
    width: 11vw;
  }
  ${media.tablet} {
    margin-bottom: 4vw;
    width: 9vw;
  }
  ${media.mobile} {
    margin-bottom: 8vw;
    width: 11vw;
    :after {
      width: 4vw;
      height: 4vw;
      margin-top: 0.5vw;
    }
  }
`;

export const LinkSubWrap = styled.div<{ activeEl: boolean }>`
  cursor: pointer;
  text-align: left;
  padding-bottom: 1vw;

  ${NavLink} {
    color: ${(props) =>
      props.activeEl ? Colors.industryOrange100 : Colors.bone100};
    transition: 0.4s;
  }

  ${NavLinkSub} {
    text-align: left;
    opacity: ${(props) => (props.activeEl ? "1" : "0.4")};
    transition: 0.4s;
  }

  ${media.tablet} {
  }
`;

export const NavSub = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1vw;

  ${media.tablet} {
    padding-bottom: 3vw;
  }
`;

export const SubWrapper = styled.nav<{ visible: boolean }>`
  transition: 600ms;
  position: absolute;
  top: 0;
  left: 0;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  z-index: ${(props) => (props.visible ? 1 : -1)};
  display: flex;
  flex-direction: column;
  padding: 2.8vw 0 0 2.8vw;
  width: 12vw;
  box-sizing: border-box;

  ${NavLink} {
    color: ${Colors.industryOrange100};
  }
  ${NavLinkSub} {
    color: ${Colors.industryOrange60};
  }
  ${media.tablet} {
    width: 100%;
    padding: 6vw 0 0 6vw;

    ${NavSub} {
      width: 40vw;
    }
  }
`;
