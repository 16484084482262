import React, { useRef, useState, useEffect } from "react";
import Menu from "./Menu";
import styled from "styled-components";
import Colors from "styles/Colors";
import media from "styles/media";
import slashElement from "assets/svg/slashElement.svg";

const Header: React.FC<{ history: any; selectRef: any; selectNum: any }> = ({
  history,
  selectRef,
  selectNum,
}) => {
  const wrapperRef = useRef(null);
  const slashRef = useRef(null);
  const [visible, setVisible] = useState(true);
  const { pathname } = history.location;

  useEffect(() => {
    if (pathname === "/calculator") {
      setVisible(false);
    }
  }, [pathname]);

  return (
    <Wrapper ref={wrapperRef} visible={visible}>
      <MenuWrapper>
        <Menu history={history} selectRef={selectRef} selectNum={selectNum} />
      </MenuWrapper>
      <SlashElement ref={slashRef} />
    </Wrapper>
  );
};

const Wrapper = styled.header<{ visible: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 13vw;
  height: 100vh;
  z-index: 999;
  display: ${(props) => (props.visible ? "flex" : "none")};
  overflow: hidden;

  ${media.tablet} {
    width: 100%;
    height: 100vh;
    left: -81vw;
    border-right: none;
  }
`;

const MenuWrapper = styled.div`
  width: 12vw;
  border-right: 1px solid white;
  background: ${Colors.gunMetal100};
`;

const SlashElement = styled.div`
  background: url(${slashElement});
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  height: 100vh;
  right: 0vw;
  width: 1vw;
  z-index: 999;
  background-blend-mode: difference;
`;

export default Header;
