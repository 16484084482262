import React, { useContext } from "react";

import Hero from "../sections/Home/Hero";
import Solutions from "../sections/Home/Solutions";
import Company from "../sections/Home/Company";
import BetterIntelligence from "../sections/Home/BetterIntelligence";
import Overview from "../sections/Home/Overview";
import Integrations from "../sections/Home/Integrations";
import Risk from "../sections/Home/Risk";
import Reporting from "../sections/Home/Reporting";
import RequestDemo from "components/RequestDemo";
import styled from "styled-components";
import Colors from "styles/Colors";
import useSelectScroll from "hooks/SelectScroll";
import { DesktopContext } from "App";

const RequestDemoColors = {
  accent: Colors.industryOrange100,
  background: Colors.gunMetal100,
  icon: Colors.bone100,
  grid: Colors.gunMetalDark,
  text: Colors.bone100,
  buttonColor: Colors.gunMetal100,
};

const IndexPage: React.FC = () => {
  const desktop = useContext(DesktopContext);

  const allTriggers = [
    { name: "#home-hero", number: 0 },
    { name: "#overview", number: 1 },
    { name: "#risk", number: 1 },

    { name: "#solutions", number: 2 },
    { name: "#integrations", number: desktop ? 3 : 7 },
    { name: "#company", number: desktop ? 4 : 8 },
    { name: "#contact", number: desktop ? 5 : 9 },
  ];

  useSelectScroll(allTriggers);

  return (
    <Wrapper>
      <Hero />
      <BetterIntelligence />
      <Overview />
      <Risk />
      <Reporting />

      <Solutions />
      <Integrations />
      <Company />
      <RequestDemo colors={RequestDemoColors} />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  background: ${Colors.bone60};
`;

export default IndexPage;
