import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { ButtonRegularStyles } from "components/Buttons";
import Colors from "styles/Colors";
import HeadingText from "components/HeadingText";
import { Heading2 } from "components/Text";
import BlackArrow from "assets/svg/BlackArrow.svg";
import OrangeArrow from "assets/svg/OrangeArrow.svg";
import media from "styles/media";
import gsap from "gsap";
import { SetRequestDemoContext } from "App";
import { MobileContext } from "App";

type Props = {
  colors: {
    accent: string;
    background: string;
    icon: string;
    grid: string;
    text: string;
    buttonColor: string;
  };
};

// the request demo on the homepage is separate due to differences that would have overcomplicated this abstraction
// open to suggestions if it seems messy - jackson

const RequestDemo: React.FC<Props> = ({ colors }) => {
  const setRequestDemo = useContext(SetRequestDemoContext);
  const mobile = useContext(MobileContext);

  useEffect(() => {
    const tl = gsap.timeline({
      scrollTrigger: { trigger: ".rd__s7-key", start: "top 90%" },
    });

    tl.to(
      ".rd__s7-key",
      {
        y: !mobile ? "+=86" : "+=20",
        x: !mobile ? "+=3" : "0",
        duration: 1,
        ease: "none",
      },
      0.1
    ).to(".rd__s7-key", { y: "+=13", duration: 0.3, ease: "none" }, 1.05);
  }, [mobile]);

  return (
    <Wrapper id={"contact"} background={colors.background}>
      <TextWrapper color={colors.text}>
        <Heading>
          <HeadingText
            headingColor={colors.text}
            headingSize={1}
            bracketColor={
              colors.accent === Colors.industryOrange100
                ? Colors.industryOrange100
                : Colors.bone100
            }
            headingText={
              "Learn more about how Source7 fits into your processes"
            }
          />
        </Heading>
        <Button
          onClick={() => setRequestDemo(true)}
          home={colors.accent}
          visible={0}
        >
          Contact us for more info
        </Button>
      </TextWrapper>
      <KeyboardBG>
        {!mobile ? (
          <svg
            width="1004"
            height="690"
            viewBox="0 0 1004 690"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <rect
                width="218"
                height="218"
                rx="32.4194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 207.645 587.678)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="218"
                height="218"
                rx="32.4194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 413.762 707.67)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="218"
                height="218"
                rx="32.4194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 414.629 468.186)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="218"
                height="218"
                rx="32.4194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 620.746 588.178)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="218"
                height="218"
                rx="32.4194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 827.734 468.686)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="218"
                height="218"
                rx="32.4194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 827.102 707.686)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="218"
                height="218"
                rx="32.4194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 0.660156 468.186)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="218"
                height="218"
                rx="32.4194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 207.645 348.693)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="218"
                height="218"
                rx="32.4194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 414.629 229.201)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="218"
                height="218"
                rx="32.4194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 621.613 348.693)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="218"
                height="218"
                rx="32.4194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 621.984 109.493)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="218"
                height="218"
                rx="32.4194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 828.969 228.985)"
                fill={colors.grid}
              />
            </g>
            <g className="rd__s7-key">
              <rect
                x="0.866044"
                width="217"
                height="217"
                rx="31.9194"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 412.116 371.926)"
                fill={colors.buttonColor}
                stroke={colors.accent}
              />
              <mask id="path-14-inside-1" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M557.758 340.065L544.269 332.278L475.699 371.863L475.699 371.863L475.574 371.936L544.144 411.521L557.633 403.734L502.552 371.936L557.758 340.065Z"
                />
              </mask>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M557.758 340.065L544.269 332.278L475.699 371.863L475.699 371.863L475.574 371.936L544.144 411.521L557.633 403.734L502.552 371.936L557.758 340.065Z"
                fill="#1A1D24"
              />
              <path
                d="M544.269 332.278L545.135 331.778L544.269 331.278L543.403 331.778L544.269 332.278ZM557.758 340.065L558.624 340.565L559.49 340.065L558.624 339.565L557.758 340.065ZM475.699 371.863L474.833 371.363L473.967 371.863L474.833 372.363L475.699 371.863ZM475.699 371.863L476.565 372.363L477.431 371.863L476.565 371.363L475.699 371.863ZM475.574 371.936L474.708 371.436L473.842 371.936L474.708 372.436L475.574 371.936ZM544.144 411.521L543.278 412.021L544.144 412.521L545.01 412.021L544.144 411.521ZM557.633 403.734L558.499 404.234L559.365 403.734L558.499 403.234L557.633 403.734ZM502.552 371.936L501.686 371.436L500.82 371.936L501.686 372.436L502.552 371.936ZM543.403 332.778L556.892 340.565L558.624 339.565L545.135 331.778L543.403 332.778ZM476.565 372.363L545.135 332.778L543.403 331.778L474.833 371.363L476.565 372.363ZM476.565 371.363L476.565 371.363L474.833 372.363L474.833 372.363L476.565 371.363ZM476.44 372.436L476.565 372.363L474.833 371.363L474.708 371.436L476.44 372.436ZM545.01 411.021L476.44 371.436L474.708 372.436L543.278 412.021L545.01 411.021ZM556.767 403.234L543.278 411.021L545.01 412.021L558.499 404.234L556.767 403.234ZM501.686 372.436L556.767 404.234L558.499 403.234L503.418 371.436L501.686 372.436ZM556.892 339.565L501.686 371.436L503.418 372.436L558.624 340.565L556.892 339.565Z"
                fill={colors.accent}
                mask="url(#path-14-inside-1)"
              />
              <mask id="path-16-inside-2" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M640.689 403.808L654.178 411.595L722.748 372.01L722.748 372.009L722.872 371.937L654.302 332.352L640.813 340.139L695.894 371.938L640.689 403.808Z"
                />
              </mask>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M640.689 403.808L654.178 411.595L722.748 372.01L722.748 372.009L722.872 371.937L654.302 332.352L640.813 340.139L695.894 371.938L640.689 403.808Z"
                fill="#1A1D24"
              />
              <path
                d="M654.178 411.595L653.312 412.095L654.178 412.595L655.044 412.095L654.178 411.595ZM640.689 403.808L639.823 403.308L638.957 403.808L639.823 404.308L640.689 403.808ZM722.748 372.01L723.614 372.51L724.48 372.01L723.614 371.51L722.748 372.01ZM722.748 372.009L721.882 371.509L721.015 372.009L721.882 372.509L722.748 372.009ZM722.872 371.937L723.739 372.437L724.605 371.937L723.739 371.437L722.872 371.937ZM654.302 332.352L655.168 331.852L654.302 331.352L653.436 331.852L654.302 332.352ZM640.813 340.139L639.947 339.639L639.081 340.139L639.947 340.639L640.813 340.139ZM695.894 371.938L696.76 372.437L697.626 371.938L696.76 371.438L695.894 371.938ZM655.044 411.095L641.555 403.308L639.823 404.308L653.312 412.095L655.044 411.095ZM721.882 371.51L653.312 411.095L655.044 412.095L723.614 372.51L721.882 371.51ZM721.882 372.509L721.882 372.51L723.614 371.51L723.614 371.509L721.882 372.509ZM722.006 371.437L721.882 371.509L723.614 372.509L723.739 372.437L722.006 371.437ZM653.436 332.852L722.006 372.437L723.739 371.437L655.168 331.852L653.436 332.852ZM641.679 340.639L655.168 332.852L653.436 331.852L639.947 339.639L641.679 340.639ZM696.76 371.438L641.679 339.639L639.947 340.639L695.028 372.437L696.76 371.438ZM641.555 404.308L696.76 372.437L695.028 371.438L639.823 403.308L641.555 404.308Z"
                fill={colors.accent}
                mask="url(#path-16-inside-2)"
              />
              <path
                d="M567.269 375.327L567.702 375.577L568.135 375.327L592.162 361.456L592.348 361.563L612.446 395.542L612.764 396.079L613.458 395.678L625.329 388.825L625.599 388.669L625.476 388.461L604.972 353.782L604.926 353.704L604.825 353.646L594.753 347.831L594.32 347.581L593.887 347.831L557.197 369.013L556.764 369.263L557.197 369.513L567.269 375.327Z"
                fill="#1A1D24"
                stroke={colors.accent}
              />
            </g>
          </svg>
        ) : (
          <svg
            width="461"
            height="306"
            viewBox="0 0 461 306"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 67.4084 192.344)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 134.534 231.426)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 201.738 270.34)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 134.817 153.43)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 201.943 192.508)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 269.352 153.594)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 269.146 231.43)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 0 153.43)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 336.556 192.512)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 336.882 114.605)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 67.4084 114.516)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 134.817 75.6016)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 202.225 114.516)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 202.346 36.6133)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 269.754 75.5312)"
                fill={colors.grid}
              />
            </g>
            <g opacity="0.5">
              <rect
                width="70.9958"
                height="70.9958"
                rx="10.558"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 336.958 114.453)"
                fill={colors.grid}
              />
            </g>

            <g className="rd__s7-key">
              <rect
                x="0.280427"
                width="70.6762"
                height="70.6762"
                rx="10.3356"
                transform="matrix(0.866044 -0.499967 0.866044 0.499967 135.038 121.14)"
                fill={colors.buttonColor}
                stroke={colors.accent}
                strokeWidth="0.323802"
              />
              <mask id="path-18-inside-1" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M182.197 110.825L177.829 108.303L155.626 121.121L155.626 121.121L155.585 121.145L177.788 133.963L182.156 131.441L164.32 121.145L182.197 110.825Z"
                />
              </mask>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M182.197 110.825L177.829 108.303L155.626 121.121L155.626 121.121L155.585 121.145L177.788 133.963L182.156 131.441L164.32 121.145L182.197 110.825Z"
                fill="#1A1D24"
              />
              <path
                d="M177.829 108.303L178.109 108.141L177.829 107.979L177.548 108.141L177.829 108.303ZM182.197 110.825L182.477 110.987L182.757 110.825L182.477 110.663L182.197 110.825ZM155.626 121.121L155.345 120.959L155.065 121.121L155.345 121.283L155.626 121.121ZM155.626 121.121L155.906 121.283L156.187 121.121L155.906 120.959L155.626 121.121ZM155.585 121.145L155.305 120.983L155.024 121.145L155.305 121.307L155.585 121.145ZM177.788 133.963L177.508 134.124L177.788 134.286L178.069 134.124L177.788 133.963ZM182.156 131.441L182.436 131.603L182.717 131.441L182.436 131.279L182.156 131.441ZM164.32 121.145L164.04 120.983L163.76 121.145L164.04 121.306L164.32 121.145ZM177.548 108.465L181.916 110.987L182.477 110.663L178.109 108.141L177.548 108.465ZM155.906 121.283L178.109 108.465L177.548 108.141L155.345 120.959L155.906 121.283ZM155.906 120.959L155.906 120.959L155.345 121.283L155.345 121.283L155.906 120.959ZM155.865 121.307L155.906 121.283L155.345 120.959L155.305 120.983L155.865 121.307ZM178.069 133.801L155.865 120.983L155.305 121.307L177.508 134.124L178.069 133.801ZM181.876 131.279L177.508 133.801L178.069 134.124L182.436 131.603L181.876 131.279ZM164.04 121.306L181.876 131.603L182.436 131.279L164.601 120.983L164.04 121.306ZM181.916 110.663L164.04 120.983L164.601 121.306L182.477 110.987L181.916 110.663Z"
                fill={colors.accent}
                mask="url(#path-18-inside-1)"
              />
              <mask id="path-20-inside-2" fill="white">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M209.049 131.464L213.417 133.986L235.62 121.168L235.62 121.168L235.66 121.144L213.457 108.326L209.09 110.848L226.925 121.145L209.049 131.464Z"
                />
              </mask>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M209.049 131.464L213.417 133.986L235.62 121.168L235.62 121.168L235.66 121.144L213.457 108.326L209.09 110.848L226.925 121.145L209.049 131.464Z"
                fill="#1A1D24"
              />
              <path
                d="M213.417 133.986L213.137 134.148L213.417 134.31L213.698 134.148L213.417 133.986ZM209.049 131.464L208.769 131.302L208.489 131.464L208.769 131.626L209.049 131.464ZM235.62 121.168L235.901 121.33L236.181 121.168L235.901 121.006L235.62 121.168ZM235.62 121.168L235.339 121.006L235.059 121.168L235.339 121.33L235.62 121.168ZM235.66 121.144L235.941 121.306L236.221 121.144L235.941 120.982L235.66 121.144ZM213.457 108.326L213.738 108.164L213.457 108.003L213.177 108.164L213.457 108.326ZM209.09 110.848L208.809 110.686L208.529 110.848L208.809 111.01L209.09 110.848ZM226.925 121.145L227.206 121.306L227.486 121.145L227.206 120.983L226.925 121.145ZM213.698 133.824L209.33 131.302L208.769 131.626L213.137 134.148L213.698 133.824ZM235.34 121.006L213.137 133.824L213.698 134.148L235.901 121.33L235.34 121.006ZM235.339 121.33L235.34 121.33L235.901 121.006L235.9 121.006L235.339 121.33ZM235.38 120.982L235.339 121.006L235.9 121.33L235.941 121.306L235.38 120.982ZM213.177 108.488L235.38 121.306L235.941 120.982L213.738 108.164L213.177 108.488ZM209.37 111.01L213.738 108.488L213.177 108.164L208.809 110.686L209.37 111.01ZM227.206 120.983L209.37 110.686L208.809 111.01L226.645 121.306L227.206 120.983ZM209.33 131.626L227.206 121.306L226.645 120.983L208.769 131.302L209.33 131.626Z"
                fill={colors.accent}
                mask="url(#path-20-inside-2)"
              />
              <path
                d="M185.276 122.237L185.416 122.318L185.557 122.237L193.337 117.746L193.397 117.78L199.905 128.783L200.007 128.957L200.232 128.827L204.076 126.608L204.163 126.557L204.124 126.49L197.484 115.261L197.47 115.235L197.437 115.217L194.176 113.334L194.036 113.253L193.895 113.334L182.015 120.192L181.875 120.273L182.015 120.354L185.276 122.237Z"
                fill="#1A1D24"
                stroke={colors.accent}
                strokeWidth="0.323802"
              />
            </g>
          </svg>
        )}
      </KeyboardBG>
    </Wrapper>
  );
};

const Wrapper = styled.section<{ background: string }>`
  padding: 15vw 0 15vw 25vw;
  position: relative;
  background: ${(props) => props.background};
  overflow: hidden;
  text-align: left;

  ${media.fullWidth} {
    padding-left: 450px;
  }

  ${media.mobile} {
    padding: 15vw 2vw 20vw 23vw;
    overflow: hidden;
    box-sizing: border-box;
    height: 175vw;
  }
`;

// const Button = styled.button<{ color: string }>`
//   ${ButtonRegularStyles}
//   ::before {
//     background: repeating-linear-gradient(
//       to right,
//       ${(props) => props.color},
//       ${(props) => props.color} 33%,
//       transparent 33%,
//       transparent 67%,
//       ${(props) => props.color} 67%,
//       ${(props) => props.color} 100%
//     );
//     background-size: 300% 100%;
//   }

//   ::after {
//     background: url(${BlackArrow}) center/contain no-repeat;
//   }

//   ${media.mobile} {
//     width: 34vw;
//   }
// `;

const Button = styled.button<{ visible: number; home?: string }>`
  ${ButtonRegularStyles}
  opacity: ${(props) => (props.visible === 0 ? 1 : 0)};

  ::after {
    background: url(${(props) =>
        props.home === Colors.industryOrange100 ? OrangeArrow : BlackArrow})
      center/contain no-repeat;
  }

  ::before {
    background-image: repeating-linear-gradient(
      to right,
      ${(props) =>
        props.home === Colors.industryOrange100
          ? Colors.industryOrange100
          : Colors.gunMetal100},
      ${(props) =>
          props.home === Colors.industryOrange100
            ? Colors.industryOrange100
            : Colors.gunMetal100}
        33%,
      transparent 33%,
      transparent 67%,
      ${(props) =>
          props.home === Colors.industryOrange100
            ? Colors.industryOrange100
            : Colors.gunMetal100}
        67%,
      ${(props) =>
          props.home === Colors.industryOrange100
            ? Colors.industryOrange100
            : Colors.gunMetal100}
        100%
    );
  }

  ${media.mobile} {
    width: 33vw;
    font-size: 4vw;
    padding-bottom: 2vw;
    margin-bottom: 20vw;

    ::after {
      width: 3vw;
      height: 3vw;
      top: 0vw;
      right: 0vw;
    }
  }
`;

const TextWrapper = styled.div<{ color: string }>`
  position: relative;
  z-index: 3;

  ${Heading2}, ${Button} {
    color: ${(props) => props.color};
  }
`;

const Heading = styled(Heading2)`
  margin-bottom: 4vw;
  width: 50%;
  font-size: 4.5vw;

  ${media.mobile} {
    width: 90%;
    margin-bottom: 15vw;
    font-size: 8vw;
  }
`;

const KeyboardBG = styled.div`
  position: absolute;
  height: auto;
  width: 69.7vw;
  right: 0;
  top: 6.8vw;

  z-index: 1;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
  }
  ${media.mobile} {
    width: 118vw;
    height: auto;
    top: 70vw;
    left: 22vw;
  }
`;

export default RequestDemo;
