import React from "react";
import styled from "styled-components";
import media from "styles/media";

const AddInsightsSeamlessly: React.FC<{}> = () => {
  return (
    <AddInsightsSeamlesslyImage>
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 230 272.31">
            <path className="overview-icon4-filament" id="Path_458" style={{ fill: "#E39F5C" }} d="M123.99,183.35c-0.8,0-1.45-0.65-1.45-1.45v-51.93h-14.9v51.93
                c0,0.8-0.65,1.45-1.45,1.45c-0.8,0-1.45-0.65-1.45-1.45v-51.93h-11.9c-8.17,0-14.79-6.62-14.79-14.79s6.62-14.79,14.79-14.79
                s14.79,6.62,14.79,14.79v11.9h14.9v-11.9c0-8.17,6.62-14.79,14.79-14.79c8.17,0,14.79,6.62,14.79,14.79s-6.62,14.79-14.79,14.79
                h-11.9v51.93c0,0.8-0.65,1.45-1.45,1.45 M137.32,103.28c-6.57,0-11.9,5.33-11.9,11.9v11.9h11.9c6.57-0.16,11.77-5.61,11.61-12.19
                C148.77,108.55,143.67,103.45,137.32,103.28 M92.84,103.29c-6.57,0.16-11.77,5.61-11.61,12.19c0.15,6.35,5.26,11.45,11.61,11.61
                h11.9v-11.9C104.73,108.62,99.41,103.29,92.84,103.29"/>
            <path id="Path_459" style={{ fill: "#FFFFFF" }} d="M115.08,272.31c-5.49,0-10.72-2.35-14.36-6.45c-0.55-0.58-0.53-1.5,0.05-2.05
                c0.58-0.55,1.5-0.53,2.05,0.05c0.02,0.02,0.04,0.04,0.06,0.07c5.99,6.75,16.32,7.36,23.07,1.37c2.07-1.84,3.64-4.17,4.56-6.78
                l1.42-4h-21.27c-0.8,0-1.45-0.65-1.45-1.45s0.65-1.45,1.45-1.45h22.24c4.11,0,7.45-3.34,7.45-7.45s-3.34-7.45-7.45-7.45h-4.45
                c-0.8,0-1.45-0.65-1.45-1.45s0.65-1.45,1.45-1.45h4.45c4.11,0,7.45-3.34,7.45-7.45s-3.34-7.45-7.45-7.45h-4.45
                c-0.8,0-1.45-0.65-1.45-1.45s0.65-1.45,1.45-1.45h4.45c4.11,0,7.44-3.34,7.45-7.45v-7.45h-11.9c-0.8,0-1.45-0.65-1.45-1.45
                s0.65-1.45,1.45-1.45l0,0h13.35c4.11,0,7.45-3.34,7.45-7.45v-9.89c-0.1-5.8,2.46-11.32,6.94-15c15.5-12.4,24.42-31.25,24.2-51.1
                C179.79,80.28,152.74,52.05,118.3,50c-1.07-0.05-2.13-0.08-3.15-0.08c-36.05,0-65.27,29.23-65.27,65.28
                c0,19.53,8.74,38.03,23.83,50.42c4.52,3.58,7.19,9.01,7.25,14.77v10.4c0,4.11,3.34,7.45,7.45,7.45h26.69c0.8,0,1.45,0.65,1.45,1.45
                s-0.65,1.45-1.45,1.45H89.85v7.45c0,4.11,3.34,7.44,7.45,7.45h17.79c0.8,0,1.45,0.65,1.45,1.45s-0.65,1.45-1.45,1.45H97.3
                c-4.11,0-7.45,3.34-7.45,7.45s3.34,7.45,7.45,7.45h17.79c0.8,0,1.45,0.65,1.45,1.45s-0.65,1.45-1.45,1.45H97.3
                c-4.11-0.01-7.45,3.33-7.46,7.44c-0.01,4.01,3.17,7.31,7.18,7.45c0.14,0.02,0.27,0.03,0.41,0.03c0.13,0.02,0.25,0.06,0.37,0.1
                l0.18,0.09c0.09,0.04,0.18,0.09,0.26,0.15c0.02,0.03,0.14,0.26,0.14,0.26l0.12,0.17c0.43,0.6,0.28,1.44-0.32,1.86
                c-0.22,0.16-0.49,0.24-0.76,0.24H97.3c-5.68,0.03-10.31-4.54-10.35-10.22c-0.02-2.59,0.94-5.08,2.69-6.99l1.85-2.03l-1.86-2.02
                c-3.54-3.89-3.54-9.84,0-13.73l1.86-2.02l-1.86-2.03c-1.72-1.88-2.68-4.33-2.68-6.88v-7.38l-2-0.71c-4.11-1.46-6.87-5.34-6.89-9.71
                v-10.44c-0.08-4.9-2.37-9.51-6.23-12.53c-29.08-23.92-33.26-66.88-9.34-95.95c12.94-15.73,32.23-24.85,52.59-24.87
                c1.09,0,2.21,0.03,3.33,0.08c35.98,2.16,64.24,31.66,64.86,67.7c0.23,20.73-9.1,40.42-25.3,53.36c-3.77,3.17-5.91,7.86-5.84,12.78
                v9.87c-0.03,4.36-2.79,8.24-6.9,9.7l-2,0.71v7.39c-0.01,2.55-0.97,5-2.68,6.87l-1.85,2.03l1.86,2.02c3.54,3.89,3.54,9.84,0,13.73
                l-1.87,2.03l1.86,2.03c1.01,1.11,1.77,2.42,2.22,3.85c1.69,5.38-1.31,11.11-6.69,12.8c0,0,0,0-0.01,0l-1.76,0.55l-0.31,1.82
                c-1.56,9.23-9.55,15.99-18.91,16"/>
            <g className="overview-icon4-radiate1">
                <path id="Path_460" style={{ fill: "#FFFFFF" }} d="M45.51,156.73c0,1.4-1.13,2.53-2.53,2.53c-1.4,0-2.53-1.13-2.53-2.53
                    c0-1.4,1.13-2.53,2.53-2.53c0,0,0,0,0,0C44.37,154.2,45.51,155.33,45.51,156.73"/>
                <path id="Path_461" style={{ fill: "#FFFFFF" }} d="M35.14,115.49c0,1.4-1.13,2.53-2.53,2.53s-2.53-1.13-2.53-2.53
                    c0-1.4,1.13-2.53,2.53-2.53h0C34.01,112.96,35.14,114.09,35.14,115.49"/>
                <path id="Path_462" style={{ fill: "#FFFFFF" }} d="M46.2,74.02c0,1.4-1.13,2.53-2.53,2.53s-2.53-1.13-2.53-2.53
                    c0-1.4,1.13-2.53,2.53-2.53c0,0,0,0,0,0C45.07,71.48,46.2,72.62,46.2,74.02"/>
                <path id="Path_463" style={{ fill: "#FFFFFF" }} d="M76.85,44.76c0,1.4-1.13,2.53-2.53,2.53c-1.4,0-2.53-1.13-2.53-2.53
                    s1.13-2.53,2.53-2.53l0,0C75.71,42.22,76.85,43.36,76.85,44.76"/>
                <path id="Path_464" style={{ fill: "#FFFFFF" }} d="M117.62,33.93c0,1.4-1.13,2.53-2.53,2.53c-1.4,0-2.53-1.13-2.53-2.53
                    s1.13-2.53,2.53-2.53l0,0C116.49,31.39,117.62,32.53,117.62,33.93"/>
                <path id="Path_465" style={{ fill: "#FFFFFF" }} d="M158.15,44.27c0,1.4-1.13,2.53-2.53,2.53s-2.53-1.13-2.53-2.53s1.13-2.53,2.53-2.53
                    l0,0C157.01,41.73,158.15,42.87,158.15,44.27"/>
                <path id="Path_466" style={{ fill: "#FFFFFF" }} d="M188.53,74.16c0,1.4-1.13,2.53-2.53,2.53s-2.53-1.13-2.53-2.53
                    c0-1.4,1.13-2.53,2.53-2.53l0,0C187.4,71.63,188.53,72.76,188.53,74.16"/>
                <path id="Path_467" style={{ fill: "#FFFFFF" }} d="M199.28,114.97c0,1.4-1.13,2.53-2.53,2.53s-2.53-1.13-2.53-2.53
                    c0-1.4,1.13-2.53,2.53-2.53l0,0C198.15,112.43,199.28,113.57,199.28,114.97"/>
                <path id="Path_468" style={{ fill: "#FFFFFF" }} d="M188.02,156.34c0,1.4-1.13,2.53-2.53,2.53c-1.4,0-2.53-1.13-2.53-2.53
                    c0-1.4,1.13-2.53,2.53-2.53l0,0C186.89,153.8,188.02,154.94,188.02,156.34"/>
            </g>
            <g className="overview-icon4-radiate2">
                <path id="Path_473" style={{ fill: "#FFFFFF" }} d="M26.96,170.21c0,3.44-2.79,6.22-6.22,6.22s-6.22-2.79-6.22-6.22s2.79-6.22,6.22-6.22
                    c0,0,0,0,0,0C24.17,163.99,26.96,166.77,26.96,170.21"/>
                <path id="Path_472" style={{ fill: "#FFFFFF" }} d="M12.44,114.91c0,3.44-2.79,6.22-6.22,6.22S0,118.35,0,114.91
                    c0-3.44,2.79-6.22,6.22-6.22S12.44,111.48,12.44,114.91L12.44,114.91"/>
                <path id="Path_471" style={{ fill: "#FFFFFF" }} d="M27.48,59.96c0,3.44-2.79,6.22-6.22,6.22s-6.22-2.79-6.22-6.22s2.79-6.22,6.22-6.22
                    S27.48,56.53,27.48,59.96L27.48,59.96"/>
                <path id="Path_470" style={{ fill: "#FFFFFF" }} d="M67.05,21.08c0,3.44-2.79,6.22-6.22,6.22c-3.44,0-6.22-2.79-6.22-6.22
                    s2.79-6.22,6.22-6.22C64.26,14.86,67.05,17.65,67.05,21.08L67.05,21.08"/>
                <path id="Path_469" style={{ fill: "#FFFFFF" }} d="M121.31,6.22c0,3.44-2.79,6.22-6.22,6.22s-6.22-2.79-6.22-6.22S111.65,0,115.09,0
                    c0,0,0,0,0,0C118.52,0,121.31,2.79,121.31,6.22L121.31,6.22"/>
                <path id="Path_474" style={{ fill: "#FFFFFF" }} d="M175.74,21.08c0,3.44-2.79,6.22-6.22,6.22c-3.44,0-6.22-2.79-6.22-6.22
                    s2.79-6.22,6.22-6.22c0,0,0,0,0,0C172.96,14.86,175.74,17.65,175.74,21.08"/>
                <path id="Path_475" style={{ fill: "#FFFFFF" }} d="M215.14,60.31c0,3.44-2.79,6.22-6.22,6.22s-6.22-2.79-6.22-6.22s2.79-6.22,6.22-6.22
                    l0,0C212.35,54.09,215.14,56.87,215.14,60.31"/>
                <path id="Path_476" style={{ fill: "#FFFFFF" }} d="M230,114.74c0,3.44-2.79,6.22-6.22,6.22c-3.44,0-6.22-2.79-6.22-6.22
                    c0-3.44,2.79-6.22,6.22-6.22l0,0C227.21,108.52,230,111.3,230,114.74"/>
                <path id="Path_477" style={{ fill: "#FFFFFF" }} d="M215.83,169c0,3.44-2.79,6.22-6.22,6.22s-6.22-2.79-6.22-6.22s2.79-6.22,6.22-6.22h0
                    C213.04,162.78,215.83,165.57,215.83,169"/>
            </g>
        </svg>
    </AddInsightsSeamlesslyImage>
  );
};

const AddInsightsSeamlesslyImage = styled.div`
    margin-bottom: 3.1vw;
    width: 10.8vw;
    height: 12.8vw;
    svg {
        width: 100%;
        height: auto;
    }

    ${media.mobile} {
        width: 30vw;
        height: auto;
        margin-bottom: 5vw;
    }
`;

export default AddInsightsSeamlessly;
