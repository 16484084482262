import React, { useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Colors from "styles/Colors";
import media from "styles/media";
import { ButtonRegularStyles } from "components/Buttons";
import { SetRequestDemoContext } from "App";
import HeadingText from "components/HeadingText";
import { ReactComponent as OrangeArrowSVG } from "assets/svg/OrangeArrow.svg";
import { Heading4, Heading5 } from "components/Text";
import EnhancedInsights from "./overview-animations/EnhancedInsights";

// THIS NEEDS TO UPDATE THE NAV !!!
const Solutions: React.FC<{}> = () => {
  const setRequestDemo = useContext(SetRequestDemoContext);

  return (
    <Wrapper id="solutions">
      <Heading>
        <HeadingText
          headingColor={Colors.gunMetal100}
          headingSize={0}
          headingText={"The Intelligence Your Business Needs"}
        />
      </Heading>
      <EnhancedInsightsWrap>
        <EnhancedInsights />
      </EnhancedInsightsWrap>
      <SolutionsGrid>
        <SolutionsGridItem to="/sfr-mfr">
          <Heading4>Single & Multi-Family Rental Operators</Heading4>
          <OrangeArrow></OrangeArrow>
        </SolutionsGridItem>
        <SolutionsGridItem to="/real-estate">
          <Heading4>Real Estate Technology Providers</Heading4>
          <OrangeArrow></OrangeArrow>
        </SolutionsGridItem>
        <SolutionsGridItem to="/insurance">
          <Heading4>Insurance Companies</Heading4>
          <OrangeArrow></OrangeArrow>
        </SolutionsGridItem>
        <SolutionsGridItem to="/home-warranty">
          <Heading4>Home Warranty & Service Providers</Heading4>
          <OrangeArrow></OrangeArrow>
        </SolutionsGridItem>
      </SolutionsGrid>
      <ContactHeading>We're here to talk about your needs</ContactHeading>
      <Button
        onClick={() => setRequestDemo(true)}
      >
        Contact Us
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: ${Colors.bone60};
  padding: 9.5vw 3.5vw 9vw 24.4vw;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  ${media.tablet} {
    padding: 8vw 3.5vw 8vw 18vw;
  }

  ${media.mobile} {
    padding: 13vw 12vw 10vw 20vw;
  }
`;

const Heading = styled.div`
  margin-bottom: 4.7vw;
  width: 58.7vw;
  position: relative;
  z-index: 1;

  ${media.mobile} {
    width: 100%;
    margin-bottom: 8vw;
  }
`;

const EnhancedInsightsWrap = styled.div`
  position: absolute;
  top: 4vw;
  right: 5vw;

  [class*="insights__lines"] {
    stroke: rgb(160, 175, 187);
  }

  ${media.mobile} {
    width: 40vw;
    right: 3vw;
    top: 10vw;

    .insights__wrapper {
      width: 100%;
    }
  }
`;

const SolutionsGrid = styled.div`
  display: grid;
  grid-template-columns: 40% 40%;
  column-gap: 10%;
  row-gap: 4vw;

  ${media.mobile} {
    display: flex;
    flex-direction: column;
    row-gap: 7vw;
  }
`;

const SolutionsGridItem = styled(Link)`
  position: relative;
  padding-bottom: 1vw;
  border-bottom: 0.3vw solid ${Colors.industryOrange100};

  h4 {
    max-width: 23vw;
  }

  &:hover {
    svg {
      transform: translate(5px, -5px);
    }
  }

  ${media.mobile} {
    border-bottom: 0.6vw solid ${Colors.industryOrange100};

    h4 {
      max-width: 80%;
    }
  }
`;

const OrangeArrow = styled(OrangeArrowSVG)`
  height: 1.2vw;
  width: 1.2vw;
  position: absolute;
  bottom: 1.25vw;
  right: 0;
  stroke-width: 0.5px;
  stroke: ${Colors.industryOrange100};
  transition: 300ms;

  ${media.mobile} {
    width: 2.5vw;
    height: 2.5vw;
    bottom: auto;
    top: 0.8vw;    
  }
`;

const ContactHeading = styled(Heading5)`
  margin-top: 7vw;  
  width: 40vw;
  margin-bottom: 3vw;

  ${media.tabletUp} {
    width: 16vw;
    font-size: 2vw;
    margin-bottom: 1vw;
    margin-top: 6vw;
  }

  ${media.mobile} {
    margin-top: 12vw;
  }
`;

const Button = styled.button`
  ${ButtonRegularStyles}
  color: black;
  margin-top: 2vw;
  width: 8vw;

  ${media.mobile} {
    width: 34vw;
  }
`;

export default Solutions;
