import React, {
  useState,
  useEffect,
  createContext,
  lazy,
  Suspense,
} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./fonts/typography.css";
import Home from "pages/Home";
import Header from "components/Header";
import Footer from "components/Footer";
import styled from "styled-components";
import RequestDemoForm from "components/RequestDemoForm";
import SuccessView from "components/SuccessView";
import Colors from "styles/Colors";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { CustomEase } from "gsap/CustomEase";

const RealEstate = lazy(() => import("pages/RealEstate"));
const InsuranceCompanies = lazy(() => import("pages/InsuranceCompanies"));
const RentalOperators = lazy(() => import("pages/RentalOperators"));
const HomeWarranty = lazy(() => import("pages/HomeWarranty"));
const FourOhFour = lazy(() => import("pages/404"));
const Terms = lazy(() => import("pages/Terms"));
const Privacy = lazy(() => import("pages/Privacy"));
const JobsPage = lazy(() => import("pages/Jobs"));
const IntegrationsPage = lazy(() => import("pages/Integrations"));
const FindProductLabel = lazy(() => import("pages/FindProductLabel"));
const Calculator = lazy(() => import("pages/Calculator"));
const LandingPage = lazy(() => import("pages/LandingPageBase"));
const MultiFamilyLP = lazy(() => import("pages/MultiFamilyLP"));
const RealEstateLP = lazy(() => import("pages/RealEstateLP"));
const InsuranceLP = lazy(() => import("pages/InsuranceLP"));
const HomeWarrantyLP = lazy(() => import("pages/HomeWarrantyLP"));

export const DesktopContext = createContext(false);
export const TabletContext = createContext(false);
export const TabletPortraitContext = createContext(false);
export const MobileContext = createContext(false);
export const LandingPageContext = createContext(false);
export const SelectContext = createContext((val: number) => {});
export const SetRequestDemoContext = createContext((val: boolean) => {});
export const SetSuccessViewContext = createContext((val: boolean) => {});

gsap.registerPlugin(
  ScrollTrigger,
  DrawSVGPlugin,
  ScrollToPlugin,
  MorphSVGPlugin,
  CustomEase,
  MotionPathPlugin
);

const App = () => {
  const [desktop, setDesktop] = useState(window.innerWidth > 1024);
  const [tablet, setTablet] = useState(
    window.innerWidth >= 768 && window.innerWidth <= 1024
  );
  const [tabletPortrait, setTabletPortrait] = useState(
    window.innerWidth >= 768 &&
      window.innerWidth <= 1200 &&
      window.innerHeight > window.innerWidth
  );
  const [mobile, setMobile] = useState(window.innerWidth < 768);
  const [requestDemo, setRequestDemo] = useState(false);
  const [successView, setSuccesView] = useState(false);
  const [selectNum, setSelectNum] = useState(0);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setDesktop(window.innerWidth > 1024);
      setTablet(window.innerWidth >= 768 && window.innerWidth <= 1024);
      setMobile(window.innerWidth < 768);
      setTabletPortrait(
        window.innerWidth >= 768 &&
          window.innerWidth <= 1200 &&
          window.innerHeight > window.innerWidth
      );
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  return (
    // @ts-ignore
    <SetSuccessViewContext.Provider value={setSuccesView}>
      <SetRequestDemoContext.Provider value={setRequestDemo}>
        <SelectContext.Provider value={setSelectNum}>
          <DesktopContext.Provider value={desktop}>
            <TabletContext.Provider value={tablet}>
              <TabletPortraitContext.Provider value={tabletPortrait}>
                <MobileContext.Provider value={mobile}>
                  <Wrapper className="App">
                    <Router>
                      <Header selectNum={selectNum} />

                      <Switch>
                        <Route path="/real-estate">
                          <Suspense fallback={<Fallback />}>
                            <RealEstate />
                          </Suspense>
                        </Route>
                        <Route path="/insurance">
                          <Suspense fallback={<Fallback />}>
                            <InsuranceCompanies />
                          </Suspense>
                        </Route>
                        <Route path="/sfr-mfr">
                          <Suspense fallback={<Fallback />}>
                            <RentalOperators />
                          </Suspense>
                        </Route>
                        <Route path="/home-warranty">
                          <Suspense fallback={<Fallback />}>
                            <HomeWarranty />
                          </Suspense>
                        </Route>
                        <Route exact path="/">
                          <Suspense fallback={<Fallback />}>
                            <Home />
                          </Suspense>
                        </Route>
                        <Route path="/jobs">
                          <Suspense fallback={<Fallback />}>
                            <JobsPage />
                          </Suspense>
                        </Route>
                        <Route path="/terms-of-use">
                          <Suspense fallback={<Fallback />}>
                            <Terms />
                          </Suspense>
                        </Route>
                        <Route path="/privacy">
                          <Suspense fallback={<Fallback />}>
                            <Privacy />
                          </Suspense>
                        </Route>
                        <Route path="/integrations">
                          <Suspense fallback={<Fallback />}>
                            <IntegrationsPage />
                          </Suspense>
                        </Route>
                        <Route path="/find-product-label">
                          <Suspense fallback={<Fallback />}>
                            <FindProductLabel />
                          </Suspense>
                        </Route>
                        <Route path="/calculator">
                          <Suspense fallback={<Fallback />}>
                            <Calculator />
                          </Suspense>
                        </Route>
                        <Route path="/landing-page">
                          <Suspense fallback={<Fallback />}>
                            <LandingPage />
                          </Suspense>
                        </Route>
                        <Route path="/multifamily-lp">
                          <Suspense fallback={<Fallback />}>
                            <MultiFamilyLP />
                          </Suspense>
                        </Route>
                        <Route path="/realestate-lp">
                          <Suspense fallback={<Fallback />}>
                            <RealEstateLP />
                          </Suspense>
                        </Route>
                        <Route path="/insurance-lp">
                          <Suspense fallback={<Fallback />}>
                            <InsuranceLP />
                          </Suspense>
                        </Route>
                        <Route path="/homewarranty-lp">
                          <Suspense fallback={<Fallback />}>
                            <HomeWarrantyLP />
                          </Suspense>
                        </Route>
                        <Route path="/request-demo">
                          <Suspense fallback={<Fallback />}>
                            <RequestDemoForm
                              visible={true}
                              setVisible={true}
                            />
                          </Suspense>
                        </Route>
                        <Route path="*">
                          <Suspense fallback={<Fallback />}>
                            <FourOhFour />
                          </Suspense>
                        </Route>
                      </Switch>
                      <Footer />
                    </Router>
                    <RequestDemoForm
                      visible={requestDemo}
                      setVisible={setRequestDemo}
                    />
                    <SuccessView visible={successView} />
                  </Wrapper>
                </MobileContext.Provider>
              </TabletPortraitContext.Provider>
            </TabletContext.Provider>
          </DesktopContext.Provider>
        </SelectContext.Provider>
      </SetRequestDemoContext.Provider>
    </SetSuccessViewContext.Provider>
  );
};

export default App;

const Wrapper = styled.div``;

const Fallback: React.FC = () => {
  return <Container></Container>;
};

const Container = styled.div`
  height: 300vh;
  width: 100%;
  background-color: ${Colors.gunMetal100};
`;
