import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import Colors from "styles/Colors";
import media from "styles/media";
import { ReactComponent as WaterLineSVG } from "assets/svg/WaterLine.svg";
import { ReactComponent as ElectricLineSVG } from "assets/svg/ElectricLine.svg";

type Props = {
  visible: number;
};

const HouseAnimation: React.FC<Props> = ({ visible }) => {
  // orange timeline
  const electricMeterRef = useRef(null);
  const acUnitRef = useRef(null);
  const dryerRef = useRef(null);
  const roombaRef = useRef(null);
  const lampLeftRef = useRef(null);
  const acDuctRef = useRef(null);
  const lampRightRef = useRef(null);
  const spaceHeaterRef = useRef(null);
  const tvRef = useRef(null);
  const mixerRef = useRef(null);
  const toasterRef = useRef(null);
  const microwaveRef = useRef(null);
  const ovenRef = useRef(null);

  const roofRef = useRef(null);
  const electricLineRef = useRef(null);

  const fadeStartValues = useCallback(() => {
    return {
      fill: Colors.gunMetal100,
      stroke: "#F5F4F5",
      strokeOpacity: 0.4,
    };
  }, []);

  useEffect(() => {
    const orangeFadeEndValues = {
      fill: Colors.gunMetal80,
      stroke: Colors.industryOrange100,
      strokeOpacity: 1,
    };
    const startValues = fadeStartValues();
    const orangeTimeline = gsap.timeline({ repeat: -1 });
    orangeTimeline.to(electricLineRef.current, {
      strokeDashoffset: "-345%",
      duration: 5,
      ease: "none",
    });
    orangeTimeline
      .fromTo(electricMeterRef.current, startValues, orangeFadeEndValues, 0)
      .to(electricMeterRef.current, startValues, 1);
    orangeTimeline
      .fromTo(acUnitRef.current, startValues, orangeFadeEndValues, 0.1)
      .to(acUnitRef.current, startValues, 1.1);
    orangeTimeline
      .fromTo(roombaRef.current, startValues, orangeFadeEndValues, 1.0)
      .to(roombaRef.current, startValues, 2.0);
    orangeTimeline
      .fromTo(lampLeftRef.current, startValues, orangeFadeEndValues, 1.2)
      .to(lampLeftRef.current, startValues, 2.2);
    orangeTimeline
      .to(roofRef.current, { opacity: "0.25" }, 1.3)
      .to(roofRef.current, { opacity: "1" }, 2.3);
    orangeTimeline
      .fromTo(acDuctRef.current, startValues, orangeFadeEndValues, 1.45)
      .to(acDuctRef.current, startValues, 2.45);
    orangeTimeline
      .fromTo(lampRightRef.current, startValues, orangeFadeEndValues, 1.85)
      .to(lampRightRef.current, startValues, 2.85);
    orangeTimeline
      .fromTo(spaceHeaterRef.current, startValues, orangeFadeEndValues, 1.95)
      .to(spaceHeaterRef.current, startValues, 2.95);
    orangeTimeline
      .fromTo(tvRef.current, startValues, orangeFadeEndValues, 2.1)
      .to(tvRef.current, startValues, 3.1);
    orangeTimeline
      .fromTo(dishwasherRef.current, startValues, orangeFadeEndValues, 2.6)
      .to(dishwasherRef.current, startValues, 3.6);
    orangeTimeline
      .fromTo(mixerRef.current, startValues, orangeFadeEndValues, 2.9)
      .to(mixerRef.current, startValues, 3.9);
    orangeTimeline
      .fromTo(toasterRef.current, startValues, orangeFadeEndValues, 2.95)
      .to(toasterRef.current, startValues, 3.95);
    orangeTimeline
      .fromTo(microwaveRef.current, startValues, orangeFadeEndValues, 3.0)
      .to(microwaveRef.current, startValues, 4.0);
    orangeTimeline
      .fromTo(ovenRef.current, startValues, orangeFadeEndValues, 3.0)
      .to(ovenRef.current, startValues, 4.0);
    orangeTimeline
      .fromTo(dryerRef.current, startValues, orangeFadeEndValues, 4.4)
      .to(dryerRef.current, startValues, 4.8);
  }, [fadeStartValues]);

  // blue timeline
  const waterHeaterRef = useRef(null);
  const fridgeRef = useRef(null);
  const kitchenSinkRef = useRef(null);
  const dishwasherRef = useRef(null);
  const bathroomSinkRef = useRef(null);
  const toiletRef = useRef(null);
  const washerRef = useRef(null);

  const kitchenFloorRef = useRef(null);
  const waterLineRef = useRef(null);

  useEffect(() => {
    const blueFadeEndValues = {
      fill: Colors.gunMetal80,
      stroke: Colors.slateBlue100,
      strokeOpacity: 1,
    };
    const startValues = fadeStartValues();
    const blueTimeline = gsap.timeline({ repeat: -1 });
    blueTimeline.to(waterLineRef.current, {
      strokeDashoffset: "-270%",
      duration: 5,
      ease: "none",
    });
    blueTimeline
      .fromTo(waterHeaterRef.current, startValues, blueFadeEndValues, 0.2)
      .to(waterHeaterRef.current, startValues, 1.2);
    blueTimeline
      .fromTo(fridgeRef.current, startValues, blueFadeEndValues, 0.8)
      .to(fridgeRef.current, startValues, 1.8);
    blueTimeline
      .fromTo(dishwasherRef.current, startValues, blueFadeEndValues, 1.3)
      .to(dishwasherRef.current, startValues, 2.3);
    blueTimeline
      .fromTo(kitchenSinkRef.current, startValues, blueFadeEndValues, 1.4)
      .to(kitchenSinkRef.current, startValues, 2.4);
    blueTimeline
      .fromTo(bathroomSinkRef.current, startValues, blueFadeEndValues, 1.9)
      .to(bathroomSinkRef.current, startValues, 2.9);
    blueTimeline
      .fromTo(toiletRef.current, startValues, blueFadeEndValues, 2.1)
      .to(toiletRef.current, startValues, 3.1);
    blueTimeline
      .fromTo(washerRef.current, startValues, blueFadeEndValues, 3.8)
      .to(washerRef.current, startValues, 4.8);
    blueTimeline
      .to(kitchenFloorRef.current, { opacity: "0.25" }, 0.5)
      .to(kitchenFloorRef.current, { opacity: "1" }, 1.5);
  }, [fadeStartValues]);

  return (
    <Wrapper visible={visible}>
      <WaterLine ref={waterLineRef} />
      <ElectricLine ref={electricLineRef} />
      <svg
        className="house"
        viewBox="0 0 802 675"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M364.608 245.251L768.789 477.655V575.371L364.608 342.967V245.251Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M333.714 547.729L644.593 368.944L489.154 279.552L178.236 458.336L333.714 547.729Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M295.619 207.93L522.758 338.592V436.308L295.619 305.646V207.93Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M366.736 246.529L770.917 477.655V576.649L768.788 577.926L366.736 344.244V246.529Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M364.607 345.522L302.431 381.255L420.085 570.454L601.076 674.325L768.788 577.926V575.371L364.607 345.522Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M302.431 378.7L364.607 342.967L768.788 575.371L601.076 671.771L420.085 567.899L302.431 378.7Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M614.86 535.921L500.613 602.123L575.332 645.019L689.618 578.816L614.86 535.921Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="water-heater" ref={waterHeaterRef}>
          <path
            d="M743.471 545.87C743.625 545.87 743.78 545.832 743.896 545.754C744.361 545.522 744.555 544.98 744.555 544.128V519.661H743.742V544.128C743.742 544.709 743.625 544.98 743.509 545.019C743.354 545.096 743.045 544.941 742.929 544.825L726.707 535.185L726.281 535.882L742.464 545.522C742.541 545.561 742.967 545.87 743.471 545.87Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M741.844 542.618C741.998 542.618 742.153 542.58 742.269 542.502C742.734 542.27 742.928 541.728 742.928 540.876V516.409H742.115V540.876C742.115 541.457 741.998 541.728 741.882 541.767C741.727 541.844 741.418 541.689 741.302 541.573L725.08 531.933L724.654 532.63L740.837 542.27C740.914 542.309 741.34 542.618 741.844 542.618Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M745.096 549.122C745.25 549.122 745.405 549.084 745.521 549.006C745.986 548.774 746.18 548.232 746.18 547.38V522.912H745.367V547.38C745.367 547.961 745.25 548.232 745.134 548.271C744.979 548.348 744.67 548.193 744.554 548.077L728.332 538.437L727.906 539.134L744.089 548.774C744.166 548.813 744.592 549.122 745.096 549.122Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M747.768 533.714L737.818 527.945V525.623L747.768 531.391V533.714Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M740.566 525.739V524.577L744.863 527.016V528.178L740.566 525.739Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M742.387 507.891L752.375 513.621L747.768 516.292L737.818 510.524L742.387 507.891Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M747.768 533.675L748.193 533.482L748.348 533.869L752.375 531.546V513.621L747.768 516.292V533.675Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M747.768 532.63L737.818 526.9V524.926L747.768 530.694V532.63Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M747.768 531.43L737.818 525.661V510.524L747.768 516.292V531.43Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M747.768 533.714L737.818 527.945V525.623L747.768 531.391V533.714Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M747.768 533.675L748.193 533.482L748.348 533.869L752.375 531.546V513.621L747.768 516.292V533.675Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M740.566 525.739V524.577L744.863 527.016V528.178L740.566 525.739Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M742.387 507.891L752.375 513.621L747.768 516.292L737.818 510.524L742.387 507.891Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M718.383 564.918C715.441 564.918 712.499 564.26 710.215 562.943C707.659 561.472 706.266 559.42 706.266 557.136V496.741C706.266 496.238 706.459 495.735 706.846 495.386C707.195 495.038 707.698 494.806 708.24 494.806L728.565 494.883C729.649 494.883 730.501 495.735 730.501 496.819L730.462 557.098C730.462 559.382 729.069 561.434 726.513 562.905C724.229 564.26 721.287 564.918 718.383 564.918ZM710.176 498.716V557.136C710.176 557.949 710.911 558.84 712.189 559.575C715.557 561.511 721.248 561.511 724.616 559.575C725.894 558.84 726.63 557.949 726.63 557.098L726.668 498.755L710.176 498.716Z"
            fill="#1A1D24"
          />
          <path
            d="M718.42 490.198C721.014 490.198 723.608 490.779 725.621 491.902C727.596 493.063 728.602 494.534 728.602 496.044V496.857V497.748V556.401V557.136C728.602 558.646 727.596 560.117 725.621 561.279C723.647 562.44 721.053 562.982 718.42 562.982C715.827 562.982 713.233 562.401 711.219 561.279C709.245 560.117 708.238 558.646 708.238 557.136V556.323V555.433V496.78V496.044C708.238 494.534 709.245 493.063 711.219 491.902C713.194 490.74 715.788 490.198 718.42 490.198ZM718.42 488.263C715.362 488.263 712.458 488.96 710.252 490.237C707.696 491.708 706.767 493.489 706.458 494.844H706.303V496.78V555.433V556.323V557.136C706.303 558.452 706.806 560.969 710.252 562.943C712.458 564.221 715.362 564.918 718.42 564.918C721.479 564.918 724.382 564.221 726.589 562.943C729.144 561.472 730.074 559.691 730.383 558.336H730.538V556.401V497.748V496.857V496.044C730.538 494.728 730.035 492.212 726.589 490.237C724.344 488.96 721.44 488.263 718.42 488.263Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M708.24 496.78L728.565 496.858L728.526 557.136C728.526 558.646 727.52 560.117 725.545 561.279C721.596 563.563 715.131 563.563 711.182 561.279C709.208 560.117 708.201 558.646 708.201 557.136L708.24 496.78Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M710.872 489.889C715.053 487.489 721.789 487.489 725.971 489.889C728.177 491.167 729.223 492.87 729.068 494.535C729.184 496.199 728.177 497.903 725.971 499.18C721.789 501.581 715.053 501.581 710.872 499.18C708.665 497.903 707.62 496.199 707.775 494.535C707.62 492.831 708.665 491.128 710.872 489.889Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M718.499 496.509C718.073 496.509 717.725 496.161 717.725 495.735V448.5C717.725 448.074 718.073 447.726 718.499 447.726C718.925 447.726 719.273 448.074 719.273 448.5V495.735C719.273 496.161 718.925 496.509 718.499 496.509Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
        </g>
        <path
          d="M364.493 398.29L226.127 478.468L316.642 530.423L455.008 450.245L364.493 398.29Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M327.674 461.821L315.943 473.319C315.905 473.358 315.866 473.435 315.827 473.474C313.543 476.532 318.189 481.449 326.745 484.74C335.301 488.031 344.67 488.534 348.348 486.018C348.425 485.979 348.502 485.94 348.58 485.901L361.123 474.713C361.549 474.326 361.123 473.706 360.233 473.358L330.113 461.743C329.184 461.395 328.1 461.434 327.674 461.821Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M354.967 503.749C354.774 503.632 354.58 503.555 354.387 503.516L353.612 503.4L335.881 489.076L352.374 481.72C352.451 481.836 352.567 481.952 352.761 482.068C353.419 482.455 354.464 482.455 355.084 482.068C355.742 481.681 355.742 481.062 355.084 480.675C354.619 480.404 334.681 487.992 334.681 487.992C334.681 487.992 314.743 480.442 314.317 480.713C313.659 481.1 313.659 481.72 314.317 482.107C314.975 482.494 316.02 482.494 316.64 482.107C316.833 481.991 316.95 481.836 317.027 481.72L333.442 489.037C333.442 489.037 319.814 500.303 315.711 503.4C315.014 503.903 314.317 503.671 314.278 503.671C313.62 504.058 313.62 504.678 314.278 505.065C314.936 505.452 315.982 505.452 316.601 505.065C317.259 504.678 317.259 504.058 316.601 503.671C316.562 503.671 316.562 503.632 316.524 503.632L334.603 489.734L352.683 503.632C352.645 503.632 352.606 503.671 352.567 503.671C351.909 504.058 351.909 504.678 352.567 505.065C353.225 505.452 354.271 505.452 354.89 505.065C355.587 504.755 355.587 504.136 354.967 503.749Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M354.969 503.478C354.659 503.284 354.233 503.207 353.846 503.207L335.882 488.611L352.723 481.798C353.381 482.185 354.427 482.185 355.046 481.798C355.704 481.41 355.704 480.791 355.046 480.404C354.388 480.017 353.343 480.017 352.723 480.404C352.452 480.559 352.297 480.752 352.259 480.946L334.644 487.876L317.067 480.946C316.99 480.752 316.873 480.597 316.641 480.443C315.983 480.055 314.938 480.055 314.318 480.443C313.66 480.83 313.66 481.449 314.318 481.836C314.976 482.223 316.022 482.223 316.641 481.836C316.68 481.836 316.68 481.798 316.719 481.798L333.482 488.573L315.518 503.129C315.093 503.129 314.667 503.207 314.357 503.4C313.699 503.787 313.699 504.407 314.357 504.794C315.015 505.181 316.06 505.181 316.68 504.794C317.222 504.484 317.299 503.981 316.951 503.594L334.682 489.269L352.414 503.594C352.026 503.981 352.104 504.484 352.646 504.833C353.304 505.22 354.349 505.22 354.969 504.833C355.588 504.484 355.588 503.865 354.969 503.478Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M366.196 459.343C366.002 459.188 365.344 458.917 364.763 459.42C364.182 459.924 347.845 474.597 347.613 474.829C347.535 474.868 347.458 474.945 347.38 474.984C344.012 477.307 336.269 477.268 328.41 475.061C326.939 474.635 325.468 474.171 324.035 473.629C323.687 473.513 323.338 473.358 323.029 473.203L330.113 462.866L328.797 445.173L328.758 445.212C328.836 444.864 328.333 444.709 328.1 444.67C327.249 444.554 326.436 444.631 326.087 444.98L308.395 462.169C308.317 462.247 308.278 462.285 308.278 462.363C307.543 463.331 307.388 464.492 307.698 465.731C308.007 468.402 308.975 476.919 309.517 478.855C310.446 482.223 314.899 486.753 323.106 489.928C333.211 493.838 344.283 494.418 348.658 491.437C348.774 491.399 348.851 491.36 348.929 491.283L361.511 480.133C361.976 479.707 366.234 462.247 366.699 460.35C366.815 460.079 366.66 459.691 366.196 459.343Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M349.548 474.79C345.251 472.235 343.276 465.654 342.618 462.827C342.425 461.976 341.728 461.085 340.45 461.395C337.314 462.247 328.023 461.434 321.248 458.801C314.55 456.207 310.408 451.523 309.788 449.935C309.633 449.664 308.278 448.697 308.123 449.858C307.891 451.407 307.543 455.123 307.465 459.072C307.388 462.905 307.543 464.337 307.543 464.337C307.814 467.589 311.956 471.654 318.809 474.751C319.544 475.1 320.319 475.41 321.132 475.719C321.945 476.029 322.758 476.339 323.571 476.571C333.249 479.707 343.973 479.436 347.612 476.92C348.425 476.261 349.084 475.681 349.471 475.332C349.703 475.1 349.626 474.868 349.548 474.79Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path d="M377.655 19.6219L643.857 172.08V269.796" fill="#1A1D24" />
        <path
          d="M377.655 19.6219L643.857 172.08V269.796"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M379.784 18.3442L377.655 19.6218L643.857 172.08V278.313L645.986 277.036V170.803L379.784 18.3442Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M525.119 340.179L643.895 272.235V329.649L525.119 397.864V340.179Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M364.84 490.005L363.988 490.044V507.581L364.84 507.078V490.005Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M405.607 484.43H404.678V501.813L405.607 502.355V484.43Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M379.822 497.4V516.447L380.829 515.905V498.987L405.606 484.74V502.355L406.613 501.852V482.107L379.822 497.4Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M363.022 487.953V507.001L363.99 507.581V490.237L378.895 498.871V515.866L379.824 516.447V497.399L363.022 487.953Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M379.861 498.29L406.652 482.92V481.72L379.861 497.051V498.29Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M362.944 487.256L389.812 471.925L406.652 481.72L379.862 497.051L362.944 487.256Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M362.944 487.256V488.495L379.862 498.29V497.051L362.944 487.256Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="kitchen-floor" ref={kitchenFloorRef}>
          <path
            d="M488.417 638.089L799.296 459.343L643.857 369.951L332.939 548.697L488.417 638.089Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M332.939 548.696L488.417 638.089V640.528L332.939 551.135V548.696Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M599.335 503.439L600.884 479.552H599.529L597.98 503.439C597.98 503.439 597.709 513.427 597.709 513.582C597.709 513.892 598.135 514.163 598.677 514.163C599.219 514.163 599.645 513.892 599.645 513.582C599.606 513.427 599.335 503.439 599.335 503.439Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M669.37 486.327L609.826 450.632V494.302L669.37 529.997V486.327Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M656.014 522.719L654.465 498.832H655.82L657.369 522.719C657.369 522.719 657.64 532.707 657.64 532.862C657.64 533.172 657.214 533.443 656.672 533.443C656.13 533.443 655.704 533.172 655.704 532.862C655.704 532.707 656.014 522.719 656.014 522.719Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M632.901 522.719L634.45 498.832H633.095L631.546 522.719C631.546 522.719 631.275 532.707 631.275 532.862C631.275 533.172 631.701 533.443 632.243 533.443C632.785 533.443 633.211 533.172 633.211 532.862C633.211 532.707 632.901 522.719 632.901 522.719Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M622.41 503.439L620.861 479.552H622.216L623.765 503.439C623.765 503.439 624.036 513.427 624.036 513.582C624.036 513.892 623.61 514.163 623.068 514.163C622.526 514.163 622.1 513.892 622.1 513.582C622.139 513.427 622.41 503.439 622.41 503.439Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M669.369 486.327L690.7 474.055V517.725L669.369 529.997V486.327Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M611.337 472.622C611.337 472.622 611.608 505.917 611.608 506.072C611.608 506.381 611.182 506.652 610.64 506.652C610.098 506.652 609.672 506.381 609.672 506.072C609.672 505.917 609.943 472.622 609.943 472.622H611.337Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M610.912 496.277C604.524 496.277 599.336 499.374 599.336 503.207C599.336 507.039 604.524 510.137 610.912 510.137C617.3 510.137 622.487 507.039 622.487 503.207C622.449 499.413 617.3 496.277 610.912 496.277ZM610.912 509.324C605.337 509.324 600.846 506.614 600.846 503.245C600.846 499.877 605.337 497.167 610.912 497.167C616.487 497.167 620.977 499.877 620.977 503.245C620.977 506.614 616.448 509.324 610.912 509.324Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M644.902 491.902C644.902 491.902 645.174 525.196 645.174 525.351C645.174 525.661 644.748 525.932 644.206 525.932C643.664 525.932 643.238 525.661 643.238 525.351C643.238 525.196 643.509 491.902 643.509 491.902H644.902Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M644.476 515.557C638.088 515.557 632.9 518.654 632.9 522.487C632.9 526.319 638.088 529.417 644.476 529.417C650.864 529.417 656.052 526.319 656.052 522.487C656.052 518.693 650.864 515.557 644.476 515.557ZM644.476 528.604C638.901 528.604 634.41 525.893 634.41 522.525C634.41 519.157 638.901 516.447 644.476 516.447C650.051 516.447 654.542 519.157 654.542 522.525C654.542 525.893 650.012 528.604 644.476 528.604Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M645.096 497.477C645.096 497.477 645.367 540.218 645.367 540.373C645.367 540.683 644.941 540.954 644.399 540.954C643.857 540.954 643.431 540.683 643.431 540.373C643.431 540.218 643.702 497.477 643.702 497.477H645.096Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M658.684 495.27C659.071 495.503 658.8 496.819 658.8 497.129C658.8 497.477 658.607 497.825 658.181 498.096L645.831 505.491C644.979 505.994 643.624 505.994 642.773 505.491L630.423 498.096C629.997 497.864 629.803 497.516 629.803 497.206C629.803 496.858 629.532 495.58 629.919 495.348L642.734 488.921C643.586 488.418 644.941 488.418 645.792 488.921L658.684 495.27Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M658.182 494.689C659.034 495.193 659.034 496.006 658.182 496.509L645.832 503.904C644.98 504.407 643.625 504.407 642.773 503.904L630.423 496.509C629.572 496.006 629.572 495.193 630.423 494.689L642.773 487.295C643.625 486.792 644.98 486.792 645.832 487.295L658.182 494.689Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M611.53 478.197C611.53 478.197 611.801 520.938 611.801 521.093C611.801 521.403 611.375 521.674 610.833 521.674C610.291 521.674 609.865 521.403 609.865 521.093C609.865 520.938 610.137 478.197 610.137 478.197H611.53Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M625.12 475.99C625.508 476.223 625.237 477.539 625.237 477.849C625.237 478.197 625.043 478.545 624.617 478.816L612.267 486.211C611.415 486.714 610.06 486.714 609.247 486.211L596.897 478.816C596.472 478.584 596.278 478.236 596.278 477.926C596.278 477.578 596.007 476.3 596.394 476.068L609.209 469.641C610.06 469.138 611.415 469.138 612.228 469.641L625.12 475.99Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M661.24 489.463V491.438L696.742 470.919V468.983L661.24 489.463Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M624.616 475.41C625.468 475.913 625.468 476.726 624.616 477.229L612.266 484.624C611.415 485.127 610.06 485.127 609.247 484.624L596.897 477.229C596.045 476.726 596.045 475.913 596.897 475.41L609.247 468.015C610.098 467.512 611.453 467.512 612.266 468.015L624.616 475.41Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M597.516 451.213L661.24 489.463L696.741 468.944L633.017 430.733L597.516 451.213Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M597.516 451.213L661.24 489.463V491.437L597.516 453.187V451.213Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M646.064 468.364L654.581 463.369V463.021L646.064 468.015V468.364Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M631.275 459.459V459.808L646.064 468.364V468.015L631.275 459.459Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M667.666 458.453C663.408 460.892 663.408 464.879 667.666 467.357C671.925 469.796 678.855 469.796 683.113 467.357C687.372 464.918 687.372 460.93 683.113 458.453C678.855 455.975 671.925 455.975 667.666 458.453Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M670.842 468.015C668.325 469.486 668.325 470.88 670.842 472.312C673.358 473.784 677.462 473.784 679.979 472.312C682.495 470.841 682.495 469.448 679.979 468.015C677.423 466.583 673.358 466.583 670.842 468.015Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M664.608 461.937C664.104 463.834 665.111 465.886 667.666 467.357C671.925 469.796 678.855 469.796 683.113 467.357C685.668 465.886 686.675 463.873 686.172 461.937C686.288 462.208 686.327 462.518 686.327 462.827C686.327 468.944 681.41 472.932 675.37 472.932C669.331 472.932 664.414 468.944 664.414 462.827C664.453 462.479 664.491 462.208 664.608 461.937Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M631.275 459.459L639.792 454.465L654.581 463.021L646.064 468.015L631.275 459.459Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M634.799 457.872L639.793 454.968L653.807 463.021L648.775 465.963L634.799 457.872Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M643.972 442.928L639.791 454.465L654.58 463.021L658.761 451.484L643.972 442.928Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M644.128 443.47L640.334 454.078L654.504 462.208L658.337 451.6L644.128 443.47Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M465.615 504.059L394.264 545.019L503.323 608.201L574.674 567.241L465.615 504.059Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M508.084 537.702V559.149C508.084 559.149 509.052 559.73 509.284 559.808C509.517 559.885 509.788 559.885 509.788 559.885V538.515L508.084 537.702Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M529.107 497.98L528.488 497.632L525.778 526.861V548.155C525.778 548.155 525.507 548.193 525.275 548.077C525.042 548 524.539 546.683 524.539 546.683V525.235C524.539 525.235 526.668 498.522 526.63 498.019C526.63 497.516 527.055 497.516 527.133 497.477C527.791 497.516 529.107 497.98 529.107 497.98Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M529.726 499.684C529.804 501.736 527.132 525.932 527.132 525.932V547.38C527.132 547.38 526.319 547.961 526.203 548.038C526.048 548.116 525.816 548.155 525.816 548.155L525.855 526.823C525.855 526.823 528.061 498.793 528.061 498.213C528.061 497.632 528.294 497.593 528.294 497.593C528.294 497.593 529.687 497.632 529.726 499.684Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M510.833 546.49L526.358 556.401C527.171 556.904 528.487 556.904 529.339 556.401C529.339 556.401 542.811 547.341 543.199 547.07C543.586 546.799 543.276 545.793 543.276 545.406C543.237 543.741 539.985 541.031 537.391 539.444C534.178 537.547 527.945 534.991 525.661 536.192L511.491 543.935C510.64 544.438 510.833 546.49 510.833 546.49ZM525.854 538.243C527.945 537.159 533.675 539.482 536.578 541.225C539.521 542.967 543.237 545.367 541.456 546.567L529.261 554.504C528.526 554.968 527.287 554.968 526.551 554.504L512.382 545.561L525.854 538.243Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M511.569 545.793L526.474 555.162C527.287 555.665 528.603 555.665 529.455 555.162L542.773 546.49C544.747 545.212 540.644 541.457 537.469 539.521C534.256 537.585 528.023 535.069 525.739 536.269L511.569 544.012C510.756 544.515 510.756 545.29 511.569 545.793ZM525.932 537.005C528.023 535.921 533.752 538.243 536.656 539.986C539.598 541.728 543.315 545.173 541.534 546.374L529.339 554.31C528.603 554.775 527.365 554.775 526.629 554.31L513.001 545.715C512.266 545.251 512.266 544.554 513.001 544.089L525.932 537.005Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M525.352 547.883V569.331C525.352 569.331 526.319 569.912 526.552 569.989C526.784 570.067 527.055 570.067 527.055 570.067V548.696L525.352 547.883Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M528.41 547.767V569.215C528.41 569.215 527.597 569.874 527.481 569.951C527.326 570.028 527.094 570.067 527.094 570.067L527.171 548.658L528.41 547.767Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M484.314 547.69V568.286C484.314 568.286 485.282 568.789 485.514 568.867C485.747 568.944 486.018 568.944 486.018 568.944V548.503L484.314 547.69Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M502.006 566.738L486.482 556.827C485.668 556.323 484.352 556.323 483.5 556.827L469.641 566.157C469.641 566.157 469.641 567.396 469.641 567.783C469.679 569.448 472.854 572.235 475.487 573.784C478.7 575.719 484.933 578.236 487.217 577.036L501.387 569.293C502.2 568.789 502.006 566.738 502.006 566.738ZM487.024 574.945C484.933 576.029 479.203 573.706 476.3 571.964C473.357 570.222 469.641 567.822 471.46 566.621L483.655 558.685C484.391 558.22 485.63 558.22 486.365 558.685L500.535 567.628L487.024 574.945Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M501.386 565.925L486.481 556.556C485.668 556.052 484.352 556.052 483.5 556.556L470.182 565.228C468.208 566.505 472.311 570.261 475.486 572.196C478.699 574.132 484.932 576.649 487.217 575.448L501.386 567.705C502.199 567.202 502.199 566.389 501.386 565.925ZM487.023 574.674C484.932 575.758 479.203 573.435 476.299 571.693C473.357 569.951 469.64 566.505 471.46 565.305L483.655 557.369C484.39 556.904 485.629 556.904 486.365 557.369L499.992 565.963C500.728 566.428 500.728 567.125 499.992 567.589L487.023 574.674Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M501.852 557.988V579.436C501.852 579.436 502.819 579.939 503.052 580.017C503.284 580.094 503.555 580.094 503.555 580.094V558.801L501.852 557.988Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M504.871 557.872V579.32C504.871 579.32 504.058 579.901 503.942 579.978C503.787 580.055 503.555 580.094 503.555 580.094L503.593 558.762L504.871 557.872Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M511.104 537.585V559.033C511.104 559.033 510.291 559.692 510.175 559.769C510.02 559.846 509.787 559.885 509.787 559.885L509.865 538.476L511.104 537.585Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M546.877 523.222C546.838 521.016 542.618 517.376 539.211 515.324C535.882 513.311 531.042 511.105 527.248 510.911L528.332 497.864C531.971 498.058 536.617 500.187 539.831 502.123C543.121 504.097 547.186 507.62 547.225 509.75L546.877 523.222Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M548.115 508.898L547.496 508.549L544.592 536.695V557.988C544.592 557.988 544.321 558.027 544.089 557.911C543.857 557.833 543.353 556.517 543.353 556.517V535.069C543.353 535.069 545.831 508.123 545.792 507.62C545.792 507.117 546.218 507.117 546.296 507.078C546.915 507.078 548.115 508.898 548.115 508.898Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M548.735 510.601C548.812 512.653 545.908 535.727 545.908 535.727V557.175C545.908 557.175 545.095 557.756 544.979 557.833C544.824 557.911 544.592 557.949 544.592 557.949L544.631 536.618C544.631 536.618 547.07 509.711 547.07 509.13C547.07 508.549 547.302 508.511 547.302 508.511C547.302 508.511 548.696 508.549 548.735 510.601Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M507.31 537.934L525.545 549.084C526.474 549.664 528.023 549.664 528.952 549.084L544.941 538.321C544.941 538.321 544.941 536.889 544.941 536.463C544.902 534.527 541.224 531.314 538.205 529.533C534.527 527.326 527.287 524.422 524.654 525.777L507.968 535.03C506.613 535.611 507.31 537.934 507.31 537.934Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M508.008 537.005L525.545 547.535C526.475 548.116 528.023 548.116 528.952 547.535L544.361 537.508C546.645 536.037 541.922 531.662 538.244 529.455C534.566 527.248 527.326 524.345 524.694 525.7L508.008 534.953C507.079 535.533 507.079 536.424 508.008 537.005Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M476.455 512.305V533.753C476.455 533.753 477.423 534.333 477.655 534.411C477.887 534.488 478.158 534.488 478.158 534.488V513.118L476.455 512.305Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M497.476 472.545L496.857 472.196L494.147 501.426V522.719C494.147 522.719 493.876 522.758 493.644 522.642C493.411 522.564 492.908 521.248 492.908 521.248V499.8C492.908 499.8 495.037 473.087 494.999 472.584C494.999 472.08 495.424 472.08 495.502 472.042C496.16 472.119 497.476 472.545 497.476 472.545Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M498.135 474.248C498.212 476.3 495.541 500.497 495.541 500.497V521.945C495.541 521.945 494.728 522.525 494.612 522.603C494.457 522.68 494.225 522.719 494.225 522.719L494.264 501.387C494.264 501.387 496.47 473.358 496.47 472.777C496.47 472.196 496.703 472.158 496.703 472.158C496.703 472.158 498.058 472.196 498.135 474.248Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M479.244 521.054L494.768 530.965C495.581 531.468 496.898 531.468 497.749 530.965C497.749 530.965 511.222 521.906 511.609 521.635C511.996 521.364 511.687 520.357 511.687 519.97C511.648 518.305 508.396 515.595 505.802 514.008C502.589 512.072 496.356 509.556 494.071 510.756L479.902 518.499C479.05 519.002 479.244 521.054 479.244 521.054ZM494.226 512.847C496.317 511.763 502.047 514.086 504.95 515.828C507.893 517.57 511.609 519.97 509.828 521.17L497.633 529.107C496.898 529.571 495.659 529.571 494.923 529.107L480.754 520.164L494.226 512.847Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M479.976 520.396L494.882 529.765C495.695 530.268 497.011 530.268 497.863 529.765L511.181 521.093C513.155 519.815 509.051 516.06 505.877 514.124C502.663 512.189 496.43 509.672 494.146 510.872L479.976 518.615C479.125 519.08 479.125 519.893 479.976 520.396ZM494.34 511.608C496.43 510.524 502.16 512.847 505.064 514.589C508.006 516.331 511.722 519.777 509.942 520.977L497.746 528.913C497.011 529.378 495.772 529.378 495.036 528.913L481.409 520.319C480.673 519.854 480.673 519.157 481.409 518.693L494.34 511.608Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M493.76 522.487V543.935C493.76 543.935 494.727 544.515 494.96 544.593C495.192 544.67 495.463 544.67 495.463 544.67V523.3L493.76 522.487Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M496.818 522.332V543.78C496.818 543.78 496.005 544.438 495.889 544.515C495.734 544.593 495.502 544.631 495.502 544.631L495.579 523.222L496.818 522.332Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M505.646 556.014L487.412 544.864C486.483 544.283 484.934 544.283 484.005 544.864L468.016 555.626C468.016 555.626 468.016 557.059 468.016 557.485C468.054 559.42 471.732 562.634 474.752 564.415C478.43 566.621 485.669 569.525 488.302 568.17L504.988 558.917C505.879 558.375 505.646 556.014 505.646 556.014Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M504.949 555.046L487.411 544.515C486.482 543.935 484.934 543.935 484.004 544.515L468.596 554.542C466.312 556.014 471.035 560.388 474.713 562.595C478.391 564.802 485.63 567.705 488.263 566.35L504.949 557.098C505.878 556.556 505.878 555.626 504.949 555.046Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M445.832 531.894V552.491C445.832 552.491 446.799 552.994 447.032 553.071C447.264 553.149 447.535 553.149 447.535 553.149V532.707L445.832 531.894Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M463.484 550.942L447.96 541.031C447.147 540.528 445.83 540.528 444.979 541.031L431.119 550.361C431.119 550.361 431.119 551.6 431.119 551.987C431.158 553.652 434.332 556.44 436.965 557.988C440.178 559.924 446.411 562.44 448.695 561.24L462.865 553.497C463.717 552.955 463.484 550.942 463.484 550.942ZM448.502 559.15C446.411 560.234 440.681 557.911 437.778 556.169C434.835 554.426 431.119 552.026 432.9 550.826L445.095 542.889C445.83 542.425 447.069 542.425 447.805 542.889L461.974 551.833L448.502 559.15Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M462.906 550.129L448.001 540.76C447.188 540.257 445.872 540.257 445.02 540.76L431.702 549.432C429.728 550.71 433.831 554.465 437.006 556.401C440.219 558.336 446.452 560.853 448.737 559.653L462.906 551.91C463.719 551.406 463.719 550.593 462.906 550.129ZM448.504 558.878C446.414 559.962 440.684 557.64 437.78 555.897C434.838 554.155 431.121 550.71 432.902 549.509L445.097 541.573C445.833 541.108 447.072 541.108 447.807 541.573L461.435 550.168C462.171 550.632 462.171 551.329 461.435 551.794L448.504 558.878Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M463.33 542.192V563.64C463.33 563.64 464.298 564.144 464.53 564.221C464.762 564.298 465.033 564.298 465.033 564.298V543.005L463.33 542.192Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M466.388 542.076V563.524C466.388 563.524 465.575 564.105 465.459 564.182C465.304 564.26 465.072 564.299 465.072 564.299L465.111 542.967L466.388 542.076Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M467.125 540.179L448.89 529.029C447.961 528.449 446.412 528.449 445.483 529.029L429.494 539.792C429.494 539.792 429.494 541.225 429.494 541.65C429.533 543.586 433.21 546.799 436.23 548.58C439.908 550.787 447.148 553.691 449.78 552.336L466.466 543.083C467.357 542.541 467.125 540.179 467.125 540.179Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M466.427 539.25L448.89 528.72C447.96 528.139 446.412 528.139 445.483 528.72L430.074 538.747C427.79 540.218 432.513 544.593 436.191 546.8C439.869 549.006 447.109 551.91 449.741 550.555L466.427 541.302C467.356 540.76 467.356 539.831 466.427 539.25Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M479.513 512.188V533.636C479.513 533.636 478.7 534.295 478.584 534.372C478.429 534.449 478.197 534.488 478.197 534.488L478.274 513.079L479.513 512.188Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M515.286 497.787C515.248 495.58 511.028 491.941 507.621 489.889C504.291 487.876 499.452 485.669 495.658 485.475L496.742 472.428C500.381 472.622 505.027 474.751 508.24 476.687C511.531 478.662 515.596 482.185 515.635 484.314L515.286 497.787Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M516.526 483.501L515.906 483.153L513.003 511.298V532.591C513.003 532.591 512.732 532.63 512.499 532.514C512.267 532.436 511.764 531.081 511.764 531.081V509.633C511.764 509.633 514.242 482.688 514.203 482.185C514.203 481.681 514.629 481.681 514.706 481.643C515.326 481.681 516.526 483.501 516.526 483.501Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M517.144 485.204C517.222 487.256 514.318 510.33 514.318 510.33V531.778C514.318 531.778 513.505 532.359 513.389 532.436C513.234 532.514 513.002 532.553 513.002 532.553L513.04 511.221C513.04 511.221 515.479 484.314 515.479 483.733C515.479 483.153 515.712 483.114 515.712 483.114C515.712 483.114 517.067 483.153 517.144 485.204Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M475.72 512.498L493.955 523.648C494.884 524.229 496.432 524.229 497.361 523.648L513.351 512.885C513.351 512.885 513.351 511.453 513.351 511.027C513.312 509.091 509.634 505.878 506.614 504.097C502.936 501.89 495.697 498.987 493.064 500.342L476.378 509.595C475.023 510.214 475.72 512.498 475.72 512.498Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M476.416 511.608L493.953 522.138C494.883 522.719 496.431 522.719 497.36 522.138L512.769 512.111C515.053 510.64 510.33 506.265 506.652 504.059C502.974 501.852 495.734 498.948 493.102 500.303L476.416 509.556C475.486 510.098 475.486 511.027 476.416 511.608Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M419.234 510.756L514.395 566.389L559.691 540.063L464.531 484.43L419.234 510.756Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M514.395 566.389L419.234 510.756V511.802L514.395 567.435V566.389Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M559.69 540.063L514.394 566.389V567.434L559.69 541.108V540.063Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M466.776 528.952L466.157 528.604L468.054 556.439V577.733C468.054 577.733 467.783 577.771 467.55 577.655C467.318 577.578 466.815 576.261 466.815 576.261V554.813C466.815 554.813 464.453 528.178 464.453 527.674C464.453 527.171 464.879 527.171 464.956 527.132C465.576 527.171 466.776 528.952 466.776 528.952Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M467.434 530.694C467.511 532.746 469.447 555.51 469.447 555.51V576.958C469.447 576.958 468.634 577.539 468.518 577.616C468.363 577.694 468.131 577.732 468.131 577.732L468.169 556.401C468.169 556.401 465.769 529.765 465.769 529.223C465.769 528.642 466.001 528.604 466.001 528.604C466.001 528.604 467.356 528.603 467.434 530.694Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M466.892 529.146C468.75 531.701 472.079 534.604 474.247 535.882C476.764 537.392 481.526 539.289 484.778 539.986L486.133 540.876L484.429 542.618L472.273 537.74L465.73 530.965L465.459 528.255C465.498 528.333 466.659 528.875 466.892 529.146Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M465.498 528.333C465.537 530.539 469.757 534.178 473.164 536.23C476.493 538.243 482.339 540.721 486.133 540.915L486.714 554.426C483.075 554.233 477.422 551.832 474.209 549.935C470.918 547.961 466.853 544.438 466.815 542.309L465.498 528.333Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M486.521 540.334L486.908 568.17V589.463C486.908 589.463 486.637 589.502 486.405 589.386C486.173 589.308 485.205 588.805 485.205 588.805V567.357C485.205 567.357 484.353 540.721 484.353 540.218C484.353 539.715 484.857 539.676 484.857 539.676L486.521 540.334Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M487.412 542.425C487.489 544.477 488.263 567.163 488.263 567.163V588.611C488.263 588.611 487.45 589.192 487.334 589.269C487.179 589.347 486.947 589.386 486.947 589.386L486.986 568.054C486.986 568.054 486.095 541.418 486.095 540.876C486.095 540.295 486.328 540.257 486.328 540.257C486.328 540.257 487.334 540.373 487.412 542.425Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M428.254 513.157L427.635 512.808L429.532 540.644V561.937C429.532 561.937 429.261 561.976 429.029 561.86C428.796 561.782 428.293 560.466 428.293 560.466V539.018C428.293 539.018 425.931 512.382 425.931 511.879C425.931 511.376 426.357 511.376 426.435 511.337C427.054 511.337 428.254 513.157 428.254 513.157Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M428.913 514.86C428.99 516.912 430.926 539.676 430.926 539.676V561.124C430.926 561.124 430.113 561.705 429.997 561.782C429.842 561.859 429.61 561.898 429.61 561.898L429.649 540.566C429.649 540.566 427.248 513.931 427.248 513.389C427.248 512.808 427.481 512.769 427.481 512.769C427.481 512.769 428.836 512.808 428.913 514.86Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M428.372 513.35C430.23 515.905 433.521 518.809 435.728 520.086C438.244 521.596 443.006 523.493 446.258 524.19L447.652 525.081L445.948 526.823L433.792 521.945L427.249 515.17L426.978 512.46C426.978 512.498 428.178 513.079 428.372 513.35Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M427.017 512.498C427.056 514.705 431.276 518.344 434.683 520.396C438.012 522.409 443.858 524.887 447.652 525.08L448.233 538.592C444.594 538.398 438.941 535.998 435.728 534.101C432.437 532.127 428.372 528.604 428.334 526.474L427.017 512.498Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M448.037 524.5L448.424 552.336V573.629C448.424 573.629 448.153 573.667 447.921 573.551C447.689 573.474 446.721 572.971 446.721 572.971V551.523C446.721 551.523 445.869 524.887 445.869 524.384C445.869 523.88 446.372 523.842 446.372 523.842L448.037 524.5Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M448.927 526.629C449.005 528.681 449.779 551.368 449.779 551.368V572.816C449.779 572.816 448.966 573.396 448.85 573.474C448.695 573.551 448.463 573.59 448.463 573.59L448.502 552.258C448.502 552.258 447.611 525.661 447.611 525.08C447.611 524.5 447.843 524.461 447.843 524.461C447.843 524.461 448.85 524.577 448.927 526.629Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M799.297 459.343L488.418 638.089V640.528L799.297 461.782V459.343Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M382.146 480.365C382.03 480.443 382.068 480.83 382.223 480.907L389.966 484.624C390.121 484.701 390.353 484.662 390.469 484.585L393.954 482.223C394.07 482.146 394.147 481.759 393.992 481.681L382.146 480.365Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M385.669 477.926L382.184 480.288C382.068 480.365 382.068 480.52 382.223 480.559L389.966 484.275C390.121 484.353 390.353 484.314 390.469 484.237L393.954 481.875C394.07 481.798 394.07 481.681 393.915 481.604L386.172 477.887C386.017 477.849 385.785 477.849 385.669 477.926Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M392.521 481.372L389.269 483.578L382.727 480.404L385.979 478.197L392.521 481.372Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M522.68 337.701L525.157 339.134V438.05L522.68 436.656V337.701Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M331.701 546.412L333.986 547.961L525.12 438.205L522.836 436.656L331.701 546.412Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M334.604 448.851L332.939 449.819V548.735L334.604 547.767V448.851Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M99.6445 372.467L66.3499 391.863V379.359L99.6445 359.962V372.467Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M91.5147 376.029L67.7439 389.618V379.862L91.5147 366.273V376.029Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M91.5147 374.984L67.7439 388.572V380.907L91.5147 367.318V374.984Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M91.5147 373.9L67.7439 387.489V381.991L91.5147 368.402V373.9Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M91.5147 372.816L67.7439 386.405V383.075L91.5147 369.486V372.816Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M91.5147 371.732L67.7439 385.32V384.159L91.5147 370.531V371.732Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="ac-unit" ref={acUnitRef}>
          <path
            d="M21.4805 356.246C21.4805 356.246 21.4805 369.138 21.4805 369.641C21.4805 370.145 21.8289 370.648 22.487 370.996L59.1886 392.289L66.0411 392.057L99.5293 372.622C100.226 372.235 100.536 371.732 100.536 371.19C100.536 370.687 100.497 357.872 100.497 357.872C99.1421 357.137 21.4805 356.246 21.4805 356.246Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M59.7685 337.354L94.4569 357.021L60.0782 375.913L27.4805 356.285L59.7685 337.354Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M21.9824 370.841L59.0324 392.289V379.823L21.9824 358.336V370.841Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M33.752 376.223L57.5615 389.811V380.017L33.752 366.428V376.223Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M33.752 375.139L57.5615 388.727V381.101L33.752 367.512V375.139Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M33.752 374.054L57.5615 387.643V382.185L33.752 368.596V374.054Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M33.752 373.009L57.5615 386.598V383.23L33.752 369.641V373.009Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M33.752 371.925L57.5615 385.514V384.314L33.752 370.725V371.925Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M57.1753 334.682L22.487 354.813C21.1319 355.588 21.1319 356.827 22.487 357.601L59.1885 378.894L66.041 378.662L99.5292 359.227C100.845 358.453 100.845 357.175 99.5292 356.439L62.0147 334.682C60.6596 333.908 58.4916 333.908 57.1753 334.682ZM76.5714 347.612L66.8153 351.484C64.686 350.361 61.8211 349.974 59.1885 350.284L55.6267 344.36C62.8664 343.354 70.8416 344.438 76.5714 347.612ZM82.8431 360.156L69.5253 356.285C70.0673 354.736 69.4091 353.11 67.4734 351.871L77.2295 348C82.7657 351.368 84.624 355.975 82.8431 360.156ZM76.9198 366.312C74.1323 367.938 70.8029 369.022 67.2798 369.602L63.7181 360.079C64.9957 359.846 66.1571 359.42 67.1637 358.84C68.1703 358.259 68.8671 357.562 69.293 356.827L82.6109 360.698C81.6043 362.75 79.7073 364.686 76.9198 366.312ZM45.4061 366.505L55.1621 359.072C57.2915 360.195 60.1563 360.582 62.7889 360.272L66.3507 369.796C59.111 370.764 51.1745 369.68 45.4061 366.505ZM39.1343 353.923L52.4521 354.194C51.9101 355.743 52.5683 357.369 54.504 358.607L44.7479 366.041C39.2504 362.75 37.3534 358.143 39.1343 353.923ZM45.0576 347.806C47.8451 346.18 51.1745 345.096 54.6976 344.515L58.2593 350.477C56.9817 350.71 55.7816 351.136 54.775 351.716C53.7684 352.297 53.0716 352.994 52.6457 353.729L39.3278 353.458C40.3731 351.368 42.2702 349.432 45.0576 347.806Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M56.1302 352.452C53.4201 354.001 53.4201 356.556 56.1302 358.105C58.8402 359.653 63.1763 359.653 65.8863 358.105C68.5963 356.556 68.5963 354.001 65.8863 352.452C63.1763 350.865 58.8015 350.865 56.1302 352.452Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M56.5949 352.22C54.1559 353.614 54.1559 355.898 56.5949 357.33C59.0339 358.724 62.9441 358.724 65.3831 357.33C67.8221 355.936 67.8221 353.652 65.3831 352.22C62.9441 350.787 59.0339 350.787 56.5949 352.22Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M377.655 19.6219L643.857 172.08V269.796L377.655 117.338V19.6219Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M557.135 138.824L548.502 133.83V123.106L557.135 128.1V138.824Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M549.664 133.172L556.052 136.811V128.875L549.664 125.235V133.172Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M549.664 132.32L556.052 135.959V129.726L549.664 126.087V132.32Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M549.664 131.43L556.052 135.069V130.617L549.664 126.978V131.43Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M549.664 130.578L556.052 134.217V131.468L549.664 127.829V130.578Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M549.664 129.688L556.052 133.327V132.359L549.664 128.72V129.688Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M506.46 97.3996L425.701 144.128V237.624L506.46 190.934V97.3996Z"
          fill="#1A1D24"
        />
        <path
          d="M66.7361 296.896L377.654 118.112L643.856 270.57L332.938 449.316L66.7361 296.896Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M66.7361 296.122L332.938 448.542V451.02L66.7361 298.561V296.122Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M643.779 269.719L489.926 180.597L408.51 227.442L472.737 264.647L472.66 264.686L485.242 271.887L562.362 316.563L643.779 269.719Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M546.335 278.855L532.553 270.609L516.873 280.056L530.617 288.302L546.335 278.855Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M516.873 280.055L530.617 288.302V289.85L516.873 281.565V280.055Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M546.335 278.855L530.617 288.302V289.85L546.335 280.404V278.855Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M544.361 278.855L532.553 271.771L521.752 278.275L533.56 285.359L544.361 278.855Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M529.611 284.159L523.726 280.636L521.752 281.797L527.675 285.359L529.611 284.159Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M584.276 253.497L562.092 266.195L595.967 285.824L618.151 273.125L584.276 253.497Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M549.626 242.541C549.626 242.541 552.142 235.03 545.87 230.617C539.599 226.203 566.428 218.538 566.428 218.538L569.409 220.009L572.39 234.953C572.39 234.953 572.583 235.417 572.235 235.611C571.887 235.766 559.343 243.315 555.859 245.328C554.852 245.909 554.31 245.406 554.31 245.406L549.626 242.541Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M579.552 199.529L573.048 203.4L572.273 225.081L578.274 221.558L579.552 199.529Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M552.647 191.166L554.157 214.24L572.275 225.08L573.049 203.4L552.647 191.166Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M543.742 216.137C530.502 224.655 544.749 233.172 552.724 232.243C568.946 230.346 569.759 216.641 563.835 213.273C562.519 212.537 560.274 212.382 558.26 212.15C553.46 211.608 547.885 213.466 543.742 216.137Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M543.701 215.247C537.584 218.925 536.733 224.384 541.804 227.403C546.876 230.462 555.935 229.92 562.052 226.242C568.169 222.564 569.021 217.144 563.949 214.086C558.877 211.066 549.818 211.569 543.701 215.247Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M563.601 212.653C558.685 209.711 549.896 210.214 543.934 213.776C538.011 217.338 537.159 222.603 542.076 225.584C546.993 228.526 555.781 228.023 561.743 224.461C567.705 220.899 568.518 215.596 563.601 212.653Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M581.022 197.632C581.022 197.516 580.945 197.4 580.829 197.322L560.93 185.359C560.659 185.204 560.233 185.204 559.962 185.359L552.645 189.773C552.49 189.889 552.412 190.005 552.451 190.121H552.412C552.412 190.121 552.451 191.36 552.451 191.438C552.451 191.554 552.528 191.67 552.645 191.747L572.544 203.71C572.815 203.865 573.241 203.865 573.551 203.71L580.868 199.297C580.984 199.219 581.061 199.103 581.061 198.987C581.022 198.871 581.022 197.632 581.022 197.632Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M609.787 166.66L628.487 177.423V207.311L609.787 196.548V166.66Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M611.608 169.641L626.668 178.352V204.329L611.608 195.657V169.641Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M629.377 206.807V176.881L610.678 166.118L609.787 166.66L628.487 177.423V207.311L629.377 206.807Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M571.267 168.905L579.668 173.784V162.13L571.267 157.252V168.905Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M583.54 164.105L554.039 147.109L552.684 147.883H552.723H552.684V148.271L582.185 165.266L583.54 164.492V164.105Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M578.314 162.518L579.669 161.782L559.15 149.935L557.795 150.671L557.834 150.71V166.815L578.353 178.662V162.556L578.314 162.518Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M297.012 66.0408L524.151 196.703V294.419L297.012 163.757V66.0408Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M516.989 231.894L511.414 228.642V217.454L516.989 220.706V231.894Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="space-heater" ref={spaceHeaterRef}>
          <path
            d="M455.385 264.939L456.198 264.978V265.714L455.385 265.288V264.939Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M432.93 263.275H433.781V264.205L432.93 264.708V263.275Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M446.015 269.199V271.561L445.125 271.096V270.67L432.929 263.585V264.708L432.039 264.244V261.146L446.015 269.199Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M446.015 270.011L432 261.92V260.797L446.015 268.888V270.011Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M457.047 263.082V265.211L456.196 265.715V265.134L446.904 270.515V271.057L446.014 271.561V269.199L457.047 263.082Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M457.125 262.463V263.547L446.014 270.012V268.89L457.125 262.463Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M446.015 269.044L432 260.953V240.473L446.015 248.564V269.044Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M433.781 252.59L443.421 258.165V259.636L433.781 254.061V252.59Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M457.126 241.053L443.034 233L432 239.427L446.015 247.518L457.126 241.053Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M457.126 241.053L443.034 233L432 239.427L446.015 247.518L457.126 241.053Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M433.781 243.994L443.421 249.569V251.04L433.781 245.465V243.994Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M433.781 245.041L443.421 250.616V252.087L433.781 246.512V245.041Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M433.781 246.086L443.421 251.661V253.132L433.781 247.557V246.086Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M433.781 247.131L443.421 252.706V254.177L433.781 248.602V247.131Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M433.781 248.176L443.421 253.751V255.222L433.781 249.647V248.176Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M433.781 249.221L443.421 254.796V256.267L433.781 250.692V249.221Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M433.781 250.268L443.421 255.843V256.268L433.781 250.693V250.268Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M457.125 241.053V242.175L446.014 248.602V247.518L457.125 241.053Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M457.125 242.137V262.617L446.014 269.043V248.563L457.125 242.137Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M446.015 248.601L432 240.549V239.426L446.015 247.517V248.601Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M516.989 226.668L511.414 223.416V217.454L516.989 220.706V226.668Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M199.142 274.287L197.012 273.087L427.907 140.024V238.94L199.142 370.803V274.287Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M244.477 343.586H245.483V347.187L244.477 347.767V343.586Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M259.769 350.477V355.781L258.685 355.201V352.22L244.477 343.935V347.767L243.431 347.225V341.108L259.769 350.477Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M259.73 351.445L243.353 341.999V340.721L259.73 350.129V351.445Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M406.032 276.145L267.627 356.323L358.142 408.278L496.547 328.1L406.032 276.145Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="roomba" ref={roombaRef}>
          <path
            d="M293.218 398.678L315.557 398.794L315.518 401.93C315.518 403.595 314.434 405.221 312.266 406.459C307.891 408.976 300.845 408.976 296.47 406.459C294.302 405.221 293.218 403.556 293.18 401.93L293.218 398.678Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M293.218 397.594L315.557 397.71L315.518 400.846C315.518 402.511 314.434 404.137 312.266 405.375C307.891 407.892 300.845 407.892 296.47 405.375C294.302 404.137 293.218 402.472 293.18 400.846L293.218 397.594Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M296.238 393.992C300.729 391.398 308.007 391.398 312.498 393.992C314.86 395.347 315.983 397.206 315.828 398.986C315.983 400.767 314.86 402.626 312.498 403.981C308.007 406.575 300.729 406.575 296.238 403.981C293.876 402.626 292.754 400.767 292.909 398.986C292.754 397.167 293.876 395.347 296.238 393.992Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M299.645 395.503C297.051 397.013 297.051 399.452 299.645 400.924C302.239 402.433 306.458 402.433 309.091 400.924C311.685 399.414 311.685 396.975 309.091 395.503C306.458 393.994 302.239 393.994 299.645 395.503Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M300.148 396.548C299.49 396.935 299.49 397.516 300.148 397.903C300.806 398.29 301.851 398.29 302.509 397.903C303.168 397.516 303.168 396.935 302.509 396.548C301.851 396.161 300.806 396.161 300.148 396.548Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M451.6 333.559L452.49 333.598V342.812L451.6 342.347V333.559Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M279.281 328.604H280.249V329.92L279.281 330.501V328.604Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M201 275.216L199.297 276.145V373.977L201 373.009V275.216Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M239.25 347.613L204.639 367.551V274.752L239.25 254.813V347.613Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M237.508 347.651L206.381 365.576V282.03L237.508 264.105V347.651Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M232.861 314.976C232.861 315.944 233.481 316.37 234.255 315.905C235.029 315.441 235.649 314.318 235.649 313.35C235.649 312.382 235.029 311.956 234.255 312.421C233.481 312.886 232.861 314.008 232.861 314.976Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M362.092 277.616H363.099V280.326L362.092 280.907V277.616Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M377.384 284.508V289.812L376.339 289.231V286.211L362.092 277.965V280.907L361.047 280.365V275.139L377.384 284.508Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M377.384 285.475L361.008 276.029V274.713L377.384 284.159V285.475Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M401.115 272.274L402.045 272.312V275.681L401.115 275.177V272.274Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M403.052 270.067V275.1L402.046 275.681V272.506L378.391 286.056V289.231L377.385 289.812V284.508L403.052 270.067Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M403.168 269.37L361.008 274.713L377.384 284.159L403.168 269.37Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M403.169 269.37V270.648L377.385 285.475V284.159L403.169 269.37Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M403.168 269.37L361.008 274.713L377.384 284.159L403.168 269.37Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M377.384 269.757L361.008 260.311V258.995L377.384 268.441V269.757Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M377.384 284.353L361.008 274.906V260.233L377.384 269.68V284.353Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M368.131 247.187L371.19 245.406V239.211L368.131 241.031V247.187Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M421.441 242.696L386.83 262.634V169.796L421.441 149.858V242.696Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M419.698 242.696L388.533 260.659V177.113L419.698 159.149V242.696Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M403.169 253.652V254.93L377.385 269.757V268.441L403.169 253.652Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M403.169 254.891V269.525L377.385 284.353V269.68L403.169 254.891Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M403.169 261.782V263.485L377.385 278.274V276.61L403.169 261.782Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M355.742 240.373L278.158 284.701V319.66L355.742 275.371V240.373Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M354.156 243.973L279.747 286.482V319.506L354.156 277.036V243.973Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M375.991 375.294V386.172L374.945 385.592V376.997L279.243 322.022V330.501L278.236 329.959V319.196L375.991 375.294Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M453.497 331.352V342.231L452.491 342.812V333.753L376.997 376.842V385.592L375.99 386.172V375.293L453.497 331.352Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M355.742 240.373L278.158 284.701L277.113 284.082L354.697 239.792L355.742 240.373Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M278.197 316.989L278.158 284.701L277.113 284.082L277.151 316.37L278.197 316.989Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M375.951 376.261L278.158 320.086V315.324L375.951 371.499V376.261Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M453.613 327.171L355.742 271.035L278.158 315.325L375.951 371.5L453.613 327.171Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M453.613 327.171V331.933L375.951 376.261V371.499L453.613 327.171Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M375.951 369.293L282.03 315.363V306.459L375.951 360.388V369.293Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M446.489 315.518L356.478 263.911L282.03 306.459L375.951 360.388L445.676 320.822L446.489 315.518Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M282.262 338.902L283.191 338.94V342.27L282.262 341.766V338.902Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M284.159 336.695V341.689L283.191 342.27V339.095L260.776 352.065V355.201L259.769 355.781V350.478L284.159 336.695Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M284.275 335.959L243.392 340.721L259.73 350.129L284.275 335.959Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M284.276 335.959V337.275L259.731 351.445V350.129L284.276 335.959Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M284.275 335.959L243.392 340.721L259.73 350.129L284.275 335.959Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M259.73 335.727L243.353 326.281V324.964L259.73 334.411V335.727Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M259.73 350.323L243.353 340.876V326.242L259.73 335.65V350.323Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M448.465 316.679L374.636 274.287L300.188 316.834L372.545 358.414L300.188 316.834V325.816L375.952 369.293L450.517 326.745C450.517 326.745 450.517 321.674 450.517 319.66C450.517 317.647 448.465 316.679 448.465 316.679Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M374.636 274.287L300.188 316.834L312.77 324.113L387.257 281.604L374.636 274.287Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M300.188 325.816V316.834L312.77 324.113V333.094L300.188 325.816Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M284.275 320.241L267.821 310.834L243.392 324.964L259.73 334.411L284.275 320.241Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M284.275 320.241L267.821 310.834L243.392 324.964L259.73 334.411L284.275 320.241Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M284.276 320.241V321.558L259.731 335.727V334.411L284.276 320.241Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M284.276 321.48V336.153L259.731 350.323V335.65L284.276 321.48Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M284.276 328.41V330.075L259.731 344.244V342.58L284.276 328.41Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M263.1 321.635L269.72 324.771L276.844 319.351L270.262 316.215L263.1 321.635Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M269.835 322.448L269.912 324.384L263.679 321.441L263.641 319.506L269.835 322.448Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M269.68 322.835C269.68 322.835 269.951 323.145 269.912 323.764C269.873 324.384 269.718 324.771 269.718 324.771L276.842 319.351C276.842 319.351 276.997 318.848 277.035 318.46C277.035 318.035 276.803 317.454 276.803 317.454L269.68 322.835Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M263.1 319.699L269.72 322.835L276.844 317.454L270.262 314.318L263.1 319.699Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M235.959 313.428V314.512L229.494 318.228V317.144L235.959 313.428Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M413.079 296.238L442.193 313.079L368.131 355.355C368.131 355.355 367.705 355.549 367.705 356.052C367.705 356.556 367.705 364.569 367.705 364.569L338.592 347.729C338.592 347.729 338.592 340.063 338.592 339.405C338.592 338.747 339.173 338.398 339.173 338.398L413.079 296.238Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="lamp-left" ref={lampLeftRef}>
          <path
            d="M251.678 324.965C251.678 327.017 254.465 328.681 257.872 328.681C261.318 328.681 264.066 327.017 264.066 324.965C264.066 322.913 261.279 321.248 257.872 321.248C254.426 321.248 251.678 322.913 251.678 324.965Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M255.742 311.995C255.742 312.111 255.858 312.227 255.974 312.227C256.091 312.227 256.207 312.111 256.207 311.995C256.207 311.879 256.091 311.763 255.974 311.763C255.858 311.763 255.742 311.84 255.742 311.995Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M255.974 311.879C256.051 311.879 256.09 311.84 256.09 311.763V307.117C256.09 307.04 256.051 307.001 255.974 307.001C255.896 307.001 255.857 307.04 255.857 307.117V311.763C255.857 311.84 255.896 311.879 255.974 311.879Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M257.33 323.377V304.987H258.375V323.415C258.375 323.609 258.143 323.725 257.833 323.725C257.562 323.725 257.33 323.57 257.33 323.377Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M250.865 303.942L252.065 295.773H263.68L264.88 303.942C264.88 304.058 264.88 304.136 264.88 304.252C264.88 306.575 261.744 308.472 257.873 308.472C254.001 308.472 250.865 306.575 250.865 304.252C250.865 304.136 250.865 304.058 250.865 303.942Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M252.064 295.773C252.064 297.709 254.658 299.258 257.872 299.258C261.085 299.258 263.679 297.709 263.679 295.773C263.679 293.838 261.085 292.289 257.872 292.289C254.658 292.289 252.064 293.876 252.064 295.773Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M514.822 289.424L515.635 289.463V292.405L514.822 291.941V289.424Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M502.278 295.541V300.187L503.207 299.684V297.051L515.634 289.812V292.405L516.564 291.902V287.334L502.278 295.541Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M502.278 296.354L516.602 288.108V286.985L502.278 295.232V296.354Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M368.943 276.377C368.75 275.526 353.728 266.466 352.451 266.815C337.894 269.06 325.041 282.456 325.041 282.456C325.041 282.456 335.339 290.857 341.456 292.25C342.617 291.786 361.975 283.656 368.943 276.377Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M367.666 272.583C367.473 271.732 352.451 262.672 351.174 263.021C336.617 265.266 323.764 278.661 323.764 278.661C323.764 278.661 334.062 287.063 340.179 288.456C341.34 288.03 360.736 279.862 367.666 272.583Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M333.096 296.199C332.902 295.348 317.881 286.289 316.604 286.637C302.047 288.882 289.194 302.278 289.194 302.278C289.194 302.278 299.492 310.679 305.609 312.073C306.77 311.608 326.127 303.478 333.096 296.199Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M333.096 292.405C332.902 291.554 317.881 282.494 316.604 282.843C302.047 285.088 289.194 298.484 289.194 298.484C289.194 298.484 299.492 306.885 305.609 308.278C306.77 307.814 326.127 299.684 333.096 292.405Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M473.783 280.249L472.97 280.288V283.23L473.783 282.804V280.249Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M472.119 278.352V282.688L472.971 283.23V280.442L501.387 296.896V299.645L502.278 300.148V295.541L472.119 278.352Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M472.041 277.694V278.855L502.277 296.354V295.231L472.041 277.694Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M502.278 261.434L516.602 253.187V252.065L502.278 260.311V261.434Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M502.278 295.386L516.602 287.14V253.149L502.278 261.395V295.386Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M403.168 253.652L386.714 244.244L361.008 258.995L377.384 268.441L403.168 253.652Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M466.66 250.865L463.562 249.084V242.889L466.66 244.67V250.865Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M472.041 242.773L486.404 234.566L516.601 252.065L502.277 260.311L472.041 242.773Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M472.041 242.773V243.935L502.277 261.434V260.311L472.041 242.773Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M472.041 243.857V277.849L502.277 295.386V261.395L472.041 243.857Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M472.041 251.445V252.916L502.277 270.416V268.944L472.041 251.445Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M472.041 258.995V260.466L502.277 278.003V276.532L472.041 258.995Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M472.041 266.544V268.015L502.277 285.553V284.082L472.041 266.544Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M643.857 269.796L332.939 448.542V451.02L643.857 272.235V269.796Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M619.465 227.636L621.13 257.523L612.187 262.634C611.993 262.75 611.722 262.75 611.529 262.634L598.017 254.852L600.534 224.964L619.465 227.636Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="lamp-right" ref={lampRightRef}>
          <path
            d="M388.185 239.56C388.185 239.676 388.069 239.792 387.953 239.792C387.837 239.792 387.721 239.676 387.721 239.56C387.721 239.444 387.837 239.328 387.953 239.328C388.069 239.289 388.185 239.405 388.185 239.56Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M387.915 239.443C387.838 239.443 387.799 239.405 387.799 239.327V234.682C387.799 234.604 387.838 234.565 387.915 234.565C387.992 234.565 388.031 234.643 388.031 234.682V239.327C388.07 239.366 387.992 239.443 387.915 239.443Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M392.25 252.529C392.25 254.581 389.463 256.246 386.056 256.246C382.61 256.246 379.861 254.581 379.861 252.529C379.861 250.477 382.649 248.813 386.056 248.813C389.463 248.774 392.25 250.477 392.25 252.529Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M386.559 250.942V232.553H385.514V250.981C385.514 251.175 385.746 251.291 386.056 251.291C386.327 251.291 386.559 251.136 386.559 250.942Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M393.064 231.507L391.863 223.338H380.249L379.049 231.507C379.049 231.623 379.049 231.701 379.049 231.817C379.049 234.14 382.185 236.037 386.056 236.037C389.928 236.037 393.064 234.14 393.064 231.817C393.064 231.701 393.025 231.623 393.064 231.507Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M391.823 223.338C391.823 225.274 389.23 226.823 386.016 226.823C382.803 226.823 380.209 225.274 380.209 223.338C380.209 221.403 382.803 219.854 386.016 219.854C389.23 219.854 391.823 221.403 391.823 223.338Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M391.516 217.26C391.516 218.228 392.135 218.654 392.909 218.189C393.684 217.725 394.303 216.602 394.303 215.634C394.303 214.666 393.684 214.241 392.909 214.705C392.174 215.131 391.516 216.292 391.516 217.26Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M398.87 213.195V214.24L392.444 217.957V216.873L398.87 213.195Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M584.391 216.486L603.284 209.672C603.671 209.44 604.29 209.44 604.639 209.672L633.133 222.099C633.52 222.332 633.288 227.558 632.901 227.791L614.24 238.476C613.853 238.708 613.234 238.708 612.885 238.476L584.623 222.177C584.236 221.983 584.043 216.718 584.391 216.486Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M627.79 223.764C627.945 223.842 627.945 223.997 627.79 224.074L614.744 231.585C614.589 231.662 614.356 231.662 614.202 231.585L587.566 216.254C587.411 216.176 587.411 216.021 587.566 215.944L600.613 208.433C600.768 208.356 601 208.356 601.155 208.433L627.79 223.764Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M624.461 227.055C624.461 227.055 614.511 232.862 614.356 232.785L590.547 219.235L600.419 213.544C600.574 213.466 600.806 213.466 600.961 213.544L624.461 227.055Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M607.581 225.08C608.356 225.506 608.356 226.242 607.581 226.668C606.807 227.094 605.607 227.094 604.832 226.668C604.058 226.242 604.058 225.506 604.832 225.08C605.607 224.654 606.846 224.654 607.581 225.08Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M632.941 221.829L604.679 205.53C604.292 205.297 603.672 205.297 603.324 205.53L584.625 216.215C584.237 216.447 584.237 216.796 584.625 216.989L612.886 233.288C613.273 233.52 613.893 233.52 614.241 233.288L632.902 222.603C633.328 222.371 633.328 222.022 632.941 221.829ZM627.791 224.074L614.745 231.585C614.59 231.662 614.357 231.662 614.203 231.585L587.567 216.254C587.412 216.176 587.412 216.021 587.567 215.944L600.614 208.433C600.769 208.356 601.001 208.356 601.156 208.433L627.791 223.764C627.946 223.842 627.946 223.997 627.791 224.074Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M607.31 224.848C607.93 225.197 607.93 225.777 607.31 226.126C606.691 226.474 605.723 226.474 605.103 226.126C604.484 225.777 604.484 225.197 605.103 224.848C605.723 224.5 606.691 224.5 607.31 224.848Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M627.405 217.725C627.405 217.454 627.753 217.221 628.218 217.221C628.682 217.221 629.031 217.454 629.031 217.725V221.054C629.031 221.325 628.682 221.558 628.218 221.558C627.753 221.558 627.405 221.325 627.405 221.054V217.725Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M627.752 215.789C627.752 215.634 627.945 215.518 628.216 215.518C628.449 215.518 628.681 215.634 628.681 215.789V217.221C628.681 217.376 628.487 217.492 628.216 217.492C627.984 217.492 627.752 217.376 627.752 217.221V215.789Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="tv" ref={tvRef}>
          <path
            d="M499.606 248.154L485.282 234.372L484.934 233.52V214.473L485.282 214.744L499.606 228.526V248.154Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M493.025 246.954L487.566 241.65L492.29 240.295L497.71 245.599L493.025 246.954Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M498.987 246.452L485.166 232.979V215.441L498.987 228.914V246.452Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M199.14 282.843L66.7361 206.962V402.355L199.14 478.275V282.843Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M199.14 462.402L66.7361 386.482V402.355L199.14 478.275V462.402Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M9.1311 522.138L11.4153 523.687L139.793 450.09L137.509 448.542L9.1311 522.138Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M100.342 420.512L127.365 436.114V422.254L100.342 406.691V420.512Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M100.342 420.512L127.365 436.114V422.254L100.342 406.691V420.512Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M101.814 419.08L125.856 432.94V423.571L101.814 409.711V419.08Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M101.814 419.08L125.856 432.94V423.571L101.814 409.711V419.08Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M115.403 425.119L124.385 430.307V424.423L115.403 419.235V425.119Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M115.403 425.119L124.385 430.307V424.423L115.403 419.235V425.119Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M103.283 418.228L112.226 423.416V417.531L103.283 412.343V418.228Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M103.283 418.228L112.226 423.416V417.531L103.283 412.343V418.228Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M103.283 418.228L112.226 423.416V417.531L103.283 412.343V418.228Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M137.508 358.182L139.792 359.73V450.09L137.508 448.542V358.182Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M251.445 671.461L399.335 586.56L150.593 443.548L2.70312 528.41L251.445 671.461Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M398.91 586.985L251.445 671.461V673.9L398.91 589.424V586.985Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M2.70312 528.41L251.445 671.461V673.9L2.70312 530.888V528.41Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M173.629 601.387L196.974 595.541L190.857 582.185L172.312 584.585L173.629 601.387Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M195.697 583.695C192.6 582.03 189.812 581.991 187.799 583.269C186.367 584.159 180.25 587.798 178.74 589.308C177.153 590.896 176.223 593.567 176.34 597.051C176.611 605.026 182.069 614.318 188.573 617.802C191.787 619.544 194.69 619.506 196.704 618.073C198.02 617.144 203.324 613.853 204.717 612.847C206.808 611.376 208.047 608.472 207.892 604.446C207.698 596.432 202.201 587.14 195.697 583.695Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M332.863 451.291V548.968L150.168 443.973V346.258L332.863 451.291Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M274.596 540.102L249.819 525.855V520.977L274.596 535.224V540.102Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M274.596 537.043L249.819 522.796V485.166L274.596 499.451V537.043Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M274.596 542.734L249.819 528.449V522.719L274.596 536.966V542.734Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M299.296 554.349L274.519 540.102V535.224L299.296 549.471V554.349Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M272.622 518.035C272.622 510.834 267.937 502.278 262.169 498.909C259.536 497.4 257.136 497.245 255.316 498.174C252.916 499.18 251.406 501.968 251.406 506.11C251.406 513.311 256.091 521.867 261.859 525.197C264.492 526.707 266.892 526.861 268.712 525.932C271.112 524.964 272.622 522.177 272.622 518.035Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M271.189 516.912C271.189 522.912 267.279 525.545 262.478 522.796C257.678 520.009 253.767 512.885 253.767 506.846C253.767 500.845 257.678 498.213 262.478 500.961C267.279 503.749 271.189 510.872 271.189 516.912Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M299.296 551.329L274.519 537.044V499.452L299.296 513.699V551.329Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M299.296 556.981L274.519 542.696V536.966L299.296 551.213V556.981Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M223.262 530.965L221.559 528.371C221.559 528.371 221.675 527.365 220.862 525.119C220.049 522.874 219.507 521.828 220.358 520.783C221.017 520.009 223.068 517.221 224.462 515.866C224.927 515.441 225.507 515.17 225.933 515.208C226.514 515.247 227.327 515.363 227.637 515.595C228.256 516.06 229.03 517.376 229.572 518.576C230.734 521.054 229.34 523.067 229.34 523.067L223.262 530.965Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M297.323 532.32C297.323 525.119 292.639 516.563 286.87 513.195C284.238 511.685 281.837 511.53 280.018 512.459C277.618 513.466 276.108 516.254 276.108 520.396C276.108 527.597 280.792 536.192 286.561 539.521C289.193 541.031 291.594 541.186 293.413 540.257C295.813 539.211 297.323 536.424 297.323 532.32Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M295.89 531.159C295.89 537.198 291.98 539.792 287.18 537.043C282.379 534.256 278.469 527.132 278.469 521.093C278.469 515.053 282.379 512.46 287.18 515.208C291.98 517.996 295.89 525.119 295.89 531.159Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M275.022 470.648L299.799 484.934L274.596 499.452L249.819 485.166L275.022 470.648Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M299.722 484.933L324.5 499.18L299.296 513.698L274.519 499.451L299.722 484.933Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M293.681 516.021V512.15L298.017 514.628V518.46L293.681 516.021Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M299.297 556.943L300.303 556.44L300.729 557.446L324.5 543.741V499.181L299.297 513.699V556.943Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M268.867 501.542V497.671L273.203 500.148V504.02L268.867 501.542Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M222.718 480.171L219.853 481.836L219.969 481.875L219.853 481.914V482.378L222.021 483.656L224.847 481.991V481.449L222.718 480.171Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M219.273 484.585C219.273 486.482 220.512 488.766 222.022 489.656C223.571 490.547 224.81 489.734 224.848 487.837C224.848 485.94 223.609 483.656 222.061 482.765C220.551 481.836 219.273 482.649 219.273 484.585Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M284.972 478.197C284.972 478.197 285.824 479.784 286.289 481.798C286.753 483.811 286.327 486.211 286.327 486.211L285.785 485.824C285.785 485.824 285.631 486.327 285.05 487.256C284.353 488.379 284.005 488.573 283.888 488.573C283.695 488.534 283.153 476.919 283.153 476.919L284.972 478.197Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M283.153 476.881C283.153 476.881 284.16 479.165 284.547 482.146C284.895 485.088 283.927 488.611 283.927 488.611L283.618 488.766L282.805 477.036L283.153 476.881Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M282.805 476.997C282.805 476.997 283.811 479.281 284.198 482.262C284.547 485.204 283.579 488.727 283.579 488.727L279.785 486.405C279.785 486.405 279.514 482.417 280.52 480.017C281.566 477.655 282.805 476.997 282.805 476.997Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M237.238 492.909C237.161 492.87 237.084 492.831 237.045 492.793L229.302 476.494L228.644 476.92L236.232 492.947C236.193 492.986 236.154 493.064 236.116 493.141L221.365 484.508V485.901L236.193 494.535C236.425 495.116 236.813 495.696 237.277 495.929C237.974 496.316 238.516 495.967 238.516 495.116C238.477 494.303 237.897 493.296 237.238 492.909Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M234.023 494.031C234.023 495.464 234.953 497.206 236.114 497.864C237.275 498.561 238.243 497.942 238.243 496.47C238.243 495.038 237.314 493.296 236.153 492.638C234.953 491.98 234.023 492.599 234.023 494.031Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M224.772 489.502C224.733 497.09 229.689 506.188 235.806 509.75C241.923 513.35 246.955 510.098 246.994 502.471C247.033 494.883 242.077 485.785 235.961 482.224C229.805 478.623 224.811 481.875 224.772 489.502ZM226.553 490.547C226.592 484.159 230.773 481.449 235.922 484.469C241.071 487.489 245.213 495.077 245.175 501.426C245.136 507.775 240.955 510.524 235.806 507.504C230.695 504.485 226.553 496.896 226.553 490.547Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M225.467 489.889C225.429 497.013 230.074 505.53 235.804 508.859C241.573 512.228 246.257 509.169 246.296 502.046C246.334 494.922 241.689 486.405 235.959 483.075C230.19 479.707 225.506 482.766 225.467 489.889ZM226.551 490.547C226.59 484.159 230.771 481.449 235.92 484.469C241.069 487.489 245.212 495.077 245.173 501.426C245.134 507.775 240.953 510.524 235.804 507.504C230.694 504.485 226.551 496.896 226.551 490.547Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M288.146 468.247L285.049 466.467V460.311L288.146 462.092V468.247Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M608.859 206.343V204.291H606.42V206.304V207.272V210.33C606.42 210.524 606.536 210.679 606.769 210.834C607.233 211.105 608.007 211.105 608.511 210.834C608.743 210.679 608.859 210.524 608.859 210.33V207.62V206.343Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M602.47 209.711V208.085L607.736 206.459V207.388L605.103 209.053L604.793 210.137L604.019 210.601C603.903 210.679 603.748 210.679 603.632 210.601L602.587 209.982C602.47 209.943 602.47 209.75 602.47 209.711Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M602.548 207.969C602.432 208.046 602.432 208.123 602.548 208.201L603.593 208.82C603.709 208.898 603.864 208.898 603.98 208.82L607.658 206.575C607.775 206.497 607.775 206.42 607.658 206.343L606.613 205.723C606.497 205.646 606.342 205.646 606.226 205.723L602.548 207.969Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M611.261 207.117L608.589 205.607C608.551 205.568 608.512 205.568 608.473 205.607C608.357 205.685 608.28 205.839 608.28 205.956C608.28 205.994 608.28 206.072 608.318 206.072C608.318 206.072 610.951 207.582 610.99 207.582C611.028 207.62 611.067 207.62 611.144 207.582C611.261 207.504 611.338 207.349 611.338 207.233C611.338 207.194 611.299 207.117 611.261 207.117Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M608.511 203.787C608.975 204.058 608.975 204.523 608.511 204.794C608.046 205.065 607.233 205.065 606.769 204.794C606.304 204.523 606.304 204.058 606.769 203.787C607.272 203.516 608.046 203.516 608.511 203.787Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M601.388 188.611L598.291 186.83V180.675L601.388 182.456V188.611Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M525.854 195.812L524.189 196.78L434.41 145.367L436.074 144.438L525.854 195.812Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M472.544 171.848C472.544 169.951 471.344 167.744 469.834 166.892C469.137 166.505 468.518 166.466 468.053 166.699C467.434 166.97 467.047 167.705 467.047 168.751C467.047 170.609 468.247 172.854 469.757 173.706C470.454 174.093 471.073 174.132 471.538 173.9C472.157 173.667 472.544 172.932 472.544 171.848Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M471.074 172.235C471.074 173.784 470.068 174.481 468.79 173.745C467.551 173.009 466.506 171.151 466.506 169.602C466.506 168.054 467.513 167.357 468.79 168.093C470.068 168.828 471.074 170.686 471.074 172.235Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M529.147 88.8436L380.25 3.47775L33.5217 202.858L182.418 288.224L529.147 88.8436Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M220.668 265.924L221.443 265.382L72.546 180.017L71.7717 180.559L220.668 265.924Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M259.614 243.857L260.427 243.315L111.53 157.988L110.717 158.491L259.614 243.857Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M299.219 221.132L338.708 198.639L189.811 113.273L150.322 135.805L299.219 221.132Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M33.5217 202.858L182.418 288.224V290.818L33.5217 205.452V202.858Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M106.691 296.587L157.639 326.01V254.31L106.691 224.848V296.587Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M109.439 293.877L154.813 320.048V286.714L109.439 260.543V293.877Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M109.439 293.993L154.813 320.164V319.428L109.439 293.219V293.993Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M135.65 304.91L152.568 314.705V287.953L135.65 278.158V304.91Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M137.236 303.981V279.088L152.567 287.953V312.847L137.236 303.981Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M131.894 306.459L132.513 306.807V273.938L131.894 273.59V306.459Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M112.769 291.941L129.687 301.736V275.023L112.769 265.228V291.941Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M114.358 291.012V266.118L129.689 275.023V299.877L114.358 291.012Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M109.672 293.644L110.253 293.993V261.124L109.672 260.775V293.644Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M109.439 263.911L154.813 290.082V256.749L109.439 230.578V263.911Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M135.108 275.294L152.026 285.05V258.337L135.108 248.542V275.294Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M136.695 274.364V249.471L152.026 258.337V283.23L136.695 274.364Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M136.695 274.364V249.471L152.026 258.337V283.23L136.695 274.364Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M137.236 303.981V279.862L152.567 288.727V312.847L137.236 303.981Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M112.189 262.324L129.146 272.119V245.367L112.189 235.611V262.324Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M113.775 261.395V236.501L129.145 245.367V270.26L113.775 261.395Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M114.358 291.011V266.505L129.689 275.409V299.877L114.358 291.011Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M70.4922 398.212L74.17 395.773V248.309C74.17 241.031 72.0407 235.379 71.9633 235.108L62.4395 210.098L58.9939 211.414L68.5177 236.424C68.5564 236.463 70.4922 241.728 70.4922 248.309V398.212Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M150.167 346.258V443.973L139.714 449.974V380.443L13.1948 452.839V522.409L2.70312 528.41V445.367L150.167 346.258Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M150.168 344.322L151.832 345.29L334.604 450.09L332.901 451.019L150.168 346.258L2.66473 445.406L1 444.438L150.168 344.322Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M1 529.92L2.66473 530.888V445.406L1 444.438V529.92Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M143.858 456.052C144.748 456.556 146.219 456.556 147.11 456.052L165.305 445.638C166.196 445.135 166.196 444.283 165.305 443.78L154.93 437.818C154.04 437.314 152.568 437.314 151.678 437.818L133.482 448.232C132.592 448.735 132.592 449.587 133.482 450.09L143.858 456.052Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M133.906 461.046C133.906 461.356 134.1 461.705 134.564 461.937L144.475 467.589C145.327 468.092 146.721 468.092 147.572 467.589L164.878 457.678C165.304 457.446 165.497 457.136 165.536 456.827L167.007 444.825L166.233 443.741L154.657 437.082H152.644L150.902 437.779L132.125 448.658L131.97 449.432L133.906 461.046Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M158.103 460.775C156.632 461.627 155.47 463.756 155.47 465.537C155.47 466.428 155.741 467.047 156.206 467.357C156.671 467.705 157.406 468.209 158.142 467.783C159.613 466.931 161.123 463.718 161.123 461.937C161.123 461.046 160.426 460.93 159.961 460.621C159.497 460.35 158.839 460.35 158.103 460.775Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M158.955 461.24C157.483 462.092 156.322 464.221 156.322 466.002C156.322 467.783 157.483 468.557 158.955 467.744C160.426 466.892 161.587 464.763 161.587 462.982C161.587 461.163 160.387 460.388 158.955 461.24Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M160.66 463.524C160.66 462.363 159.885 461.859 158.917 462.401C157.95 462.943 157.175 464.337 157.175 465.537C157.175 466.699 157.95 467.202 158.917 466.66C159.885 466.079 160.66 464.686 160.66 463.524Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M159.537 464.144C159.537 463.718 159.266 463.563 158.918 463.757C158.57 463.95 158.299 464.453 158.299 464.841C158.299 465.266 158.57 465.421 158.918 465.228C159.266 465.073 159.537 464.57 159.537 464.144Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M200.227 473.513C201.079 471.151 203.789 463.757 203.905 463.292C204.06 462.75 204.37 460.427 204.37 460.427L205.415 461.008C205.415 461.008 205.221 463.021 204.989 463.757C204.795 464.415 201.931 472.235 201.079 474.558C201.118 474.674 201.156 474.829 201.156 474.945C201.156 475.448 200.808 475.681 200.382 475.448C199.956 475.216 199.608 474.597 199.608 474.055C199.685 473.629 199.917 473.397 200.227 473.513Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M188.303 468.093C188.264 475.681 193.22 484.779 199.337 488.34C205.493 491.941 210.487 488.689 210.525 481.062C210.564 473.474 205.647 464.415 199.492 460.814C193.336 457.252 188.342 460.505 188.303 468.093ZM190.084 469.138C190.123 462.789 194.304 460.04 199.453 463.06C204.602 466.079 208.745 473.668 208.706 480.017C208.667 486.366 204.486 489.115 199.337 486.095C194.188 483.114 190.084 475.526 190.084 469.138Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M188.999 468.48C188.96 475.603 193.606 484.12 199.335 487.45C205.065 490.818 209.788 487.76 209.827 480.636C209.866 473.513 205.22 464.995 199.49 461.627C193.722 458.337 189.037 461.356 188.999 468.48ZM190.083 469.138C190.121 462.789 194.302 460.04 199.451 463.06C204.601 466.079 208.743 473.668 208.704 480.017C208.666 486.366 204.484 489.115 199.335 486.095C194.186 483.114 190.083 475.526 190.083 469.138Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M198.872 474.713C198.872 474.713 200.188 474.171 200.227 474.132C200.265 474.132 200.343 474.132 200.42 474.171C200.614 474.287 200.769 474.597 200.769 474.829C200.769 474.945 200.73 475.061 200.652 475.061L199.336 475.565L198.872 474.713Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M143.858 456.052C144.748 456.556 146.219 456.556 147.11 456.052L165.305 445.638C166.196 445.135 166.196 444.283 165.305 443.78L154.93 437.818C154.04 437.314 152.568 437.314 151.678 437.818L133.482 448.232C132.592 448.735 132.592 449.587 133.482 450.09L143.858 456.052Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M138.244 452.839L150.284 447.264L151.794 446.412L158.995 450.052L148.619 455.472L146.49 456.439L144.671 456.827L143.122 456.168L138.515 453.342L138.244 452.839Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M149.859 452.374C149.666 451.523 149.124 450.903 148.62 451.019L142.31 452.336C142 452.413 141.768 452.762 141.69 453.187C141.652 453.187 141.613 453.187 141.536 453.187L140.335 453.458C140.064 453.536 139.91 453.962 139.987 454.426C140.103 454.891 140.413 455.239 140.684 455.162L141.884 454.891C141.923 454.891 141.961 454.852 142 454.813C142.271 455.201 142.581 455.433 142.929 455.355L149.24 454.039C149.782 454 150.053 453.226 149.859 452.374Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M198.948 476.184C199.374 476.416 199.723 476.223 199.723 475.681C199.723 475.41 199.645 475.139 199.49 474.906C200.381 472.351 203.013 464.686 203.284 464.066C203.594 463.331 204.02 463.06 204.446 462.053C204.523 461.898 204.601 461.666 204.756 461.317L219.583 488.031L220.474 488.418L220.706 487.798L233.985 495.58C234.179 496.161 234.605 496.703 235.069 497.012C235.766 497.4 236.308 497.051 236.308 496.199C236.308 495.348 235.766 494.341 235.069 493.954C234.992 493.915 234.914 493.877 234.837 493.838L226.978 473.784L227.094 473.706L228.643 476.997L229.301 476.571L226.629 470.919L227.442 468.635L226.552 467.744L225.933 469.525L225.274 471.461L225.236 471.538L205.104 459.769C205.84 457.717 207.504 452.994 208.434 450.4L207.311 450.671L207.04 451.523L203.401 461.55C203.401 461.55 202.665 462.556 202.355 463.215C202.084 463.795 199.49 471.925 198.794 474.248C198.484 474.209 198.252 474.403 198.252 474.829C198.213 475.332 198.523 475.913 198.948 476.184ZM221.055 486.637L225.816 473.048L234.024 493.954C233.985 493.993 233.946 494.07 233.908 494.148L221.055 486.637ZM206.111 461.782L224.771 472.7L220.087 486.056L219.622 485.785L206.111 461.782Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M217.181 485.901C217.181 487.798 218.42 490.083 219.969 490.973C221.517 491.863 222.756 491.05 222.756 489.153C222.756 487.256 221.517 484.972 219.969 484.082C218.459 483.153 217.22 484.004 217.181 485.901Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M218.072 492.715L218.188 487.721C218.188 487.721 219.504 487.179 219.543 487.14C219.582 487.101 219.659 487.14 219.737 487.179C219.93 487.295 220.124 487.605 220.085 487.837C220.085 487.953 220.046 488.031 219.969 488.108C219.969 488.108 219.466 488.302 219.117 488.418L218.962 492.909L218.072 492.715Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M217.223 491.709L214.397 493.373L214.474 493.412L214.397 493.451V493.915L216.526 495.193L219.352 493.528V492.986L217.223 491.709Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M230.151 476.532L229.996 475.797L227.17 477.462L227.286 478.197L230.151 476.532Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M224.618 465.189L230.696 468.17L230.58 468.247H230.696V468.751L228.566 470.028L223.146 466.505L223.108 466.118L224.618 465.189Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M269.68 565.111C268.015 561.24 251.446 543.702 237.005 533.985C233.869 532.049 201.465 505.607 177.772 492.599C156.943 481.062 117.88 462.518 117.88 462.518C117.88 462.518 94.3802 465.46 75.4874 478.313C56.5946 491.166 56.0139 500.458 56.0139 500.458C56.0139 500.458 59.3821 502.161 66.3507 503.207C80.7139 504.562 104.64 511.298 125.468 522.835C149.161 535.843 162.75 556.556 167.861 563.756C169.061 565.46 174.597 569.912 177.694 571.848C192.135 581.565 206.111 599.219 207.814 603.091C209.479 606.962 204.136 618.344 204.136 618.344C204.136 618.344 227.752 616.718 230.424 615.557C233.521 614.202 238.05 611.646 244.632 608.085C251.329 604.484 263.757 598.406 267.241 594.38C268.983 592.405 270.067 589.463 270.3 588.185C271.655 581.604 272.855 572.429 269.68 565.111Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M219.777 610.95C220.125 611.801 220.125 612.731 219.815 613.544C219.506 614.395 218.848 615.092 217.918 615.479C217.144 615.789 216.331 615.828 215.596 615.596C214.628 615.325 213.815 614.628 213.389 613.621C213.079 612.847 213.04 611.995 213.273 611.259C213.582 610.33 219.39 610.059 219.777 610.95Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M67.7827 541.186L91.9407 538.243L86.3658 522.138L68.5958 522.951L65.1501 537.159L67.7827 541.186Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M88.3419 524.074C85.2448 522.409 82.4573 522.37 80.4441 523.648C79.0117 524.538 72.8948 528.178 71.3849 529.687C69.7976 531.275 68.8685 533.946 68.9846 537.43C69.2556 545.406 74.7144 554.697 81.2184 558.181C84.4318 559.924 87.3354 559.885 89.3485 558.452C90.6648 557.523 95.9687 554.233 97.3625 553.226C99.453 551.755 100.692 548.851 100.537 544.825C100.343 536.85 94.846 527.558 88.3419 524.074Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M86.956 555.437C90.6581 553.473 90.8815 546.644 87.4548 540.184C84.0282 533.724 78.2492 530.08 74.5471 532.043C70.8449 534.007 70.6216 540.836 74.0482 547.296C77.4749 553.756 83.2538 557.4 86.956 555.437Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M88.3419 524.074C85.2448 522.409 82.4573 522.37 80.4441 523.648C79.0117 524.538 72.8948 528.178 71.3849 529.687C69.7976 531.275 68.8685 533.946 68.9846 537.43C69.2556 545.406 74.7144 554.697 81.2184 558.181C84.4318 559.924 87.3354 559.885 89.3485 558.452C90.6648 557.523 95.9687 554.233 97.3625 553.226C99.453 551.755 100.692 548.851 100.537 544.825C100.343 536.85 94.846 527.558 88.3419 524.074Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M207.852 603.091C206.187 599.219 192.211 581.604 177.732 571.848C174.596 569.912 169.099 565.46 167.899 563.757C162.788 556.556 149.161 531.894 127.829 520.202C118.344 514.937 109.865 510.292 100.225 507.504C90.8177 504.794 73.4348 503.865 66.35 503.207C59.3814 502.161 56.0132 500.458 56.0132 500.458L58.7232 504.446L54.1936 519.002C54.1936 519.002 55.6648 532.94 67.7825 541.147C68.8665 540.566 67.3179 539.87 67.2017 536.347C66.892 527.055 72.8154 522.796 80.4034 526.861C87.9915 530.926 94.3794 541.767 94.6892 551.058C94.844 555.665 95.3473 554.426 96.47 556.749C122.254 571.074 163.485 595.851 173.628 601.387C174.403 601.813 174.48 598.213 174.403 596.006C174.093 586.714 180.016 582.456 187.604 586.521C195.192 590.586 201.58 601.426 201.89 610.717C201.968 613.66 201.348 617.183 202.858 617.686C203.748 618.151 204.213 618.344 204.213 618.344C204.213 618.344 209.517 606.962 207.852 603.091Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M178.546 598.367C178.817 604.484 183.114 611.763 188.07 614.55C189.463 615.325 190.78 615.712 192.019 615.673C193.296 615.634 194.38 615.131 195.232 614.24C196.432 612.924 197.013 610.872 196.897 608.278C196.626 602.162 192.328 594.883 187.373 592.096C185.979 591.321 184.663 590.934 183.424 590.973C182.146 591.012 181.062 591.515 180.211 592.405C179.01 593.722 178.43 595.812 178.546 598.367ZM188.805 611.801C187.605 611.143 186.405 610.098 185.321 608.782L188.147 605.104C188.418 605.336 188.689 605.568 188.96 605.723C189.27 605.917 189.579 605.994 189.85 606.033L191.786 612.692C190.896 612.692 189.889 612.382 188.805 611.801ZM186.25 601.542C186.405 602.394 186.753 603.284 187.257 604.059L184.74 608.124C182.727 605.491 181.372 602.162 181.062 599.258L186.25 601.542ZM186.25 600.226L181.062 598.367C180.985 595.503 182.03 593.567 183.85 593.141L186.986 598.987C186.598 599.219 186.327 599.645 186.25 600.226ZM191.748 603.246C191.631 602.394 191.244 601.426 190.702 600.613L191.67 597.671C193.567 600.226 194.845 603.362 195.193 606.149L191.748 603.246ZM191.709 604.678L195.271 607.272C195.348 610.137 194.341 612.072 192.561 612.537L190.935 605.801C191.322 605.568 191.593 605.181 191.709 604.678ZM187.566 593.877C188.766 594.573 190.005 595.619 191.089 596.935L189.812 599.568C189.579 599.335 189.308 599.18 189.076 599.026C188.728 598.832 188.418 598.716 188.108 598.716L184.74 593.025C185.553 593.025 186.521 593.296 187.566 593.877Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M224.385 600.652C223.843 602.433 219.275 601.542 213.816 600.342C208.318 599.142 201.427 594.186 200.459 591.941C199.53 589.695 205.725 588.457 209.093 589.463C212.461 590.508 224.927 598.909 224.385 600.652Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M269.177 584.779C268.867 586.405 268.945 587.798 269.332 587.876C269.719 587.953 270.261 586.676 270.571 585.05C270.881 583.424 270.803 582.03 270.416 581.952C270.029 581.875 269.487 583.153 269.177 584.779Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M140.412 529.997C145.329 527.481 161.086 521.325 169.371 515.712C178.817 509.285 186.754 502.549 189.038 500.651C202.82 509.672 223.455 523.842 231.624 529.997C229.882 533.482 216.254 544.748 204.988 552.065C194.652 558.84 178.933 565.731 171.268 566.97C169.719 565.653 168.403 564.453 167.9 563.718C163.951 558.22 154.969 544.477 140.412 529.997Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M147.226 537.16C145.097 534.837 142.851 532.436 140.412 530.036C140.451 529.997 140.49 529.997 140.567 529.959C142.929 532.398 145.174 534.798 147.226 537.16Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M168.789 564.221C176.299 563.021 191.708 556.246 201.851 549.587C212.885 542.425 226.357 531.352 228.061 527.907C220.512 522.254 202.238 509.633 188.843 500.806C188.882 500.768 188.959 500.729 188.998 500.651C202.78 509.672 223.415 523.842 231.584 529.997C229.842 533.482 216.214 544.748 204.948 552.065C194.611 558.84 178.893 565.731 171.228 566.97C169.679 565.653 168.363 564.453 167.86 563.718C167.666 563.447 167.434 563.098 167.201 562.789C167.705 563.253 168.247 563.718 168.789 564.221Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M124.346 544.089C115.751 538.592 84.8953 512.343 75.2166 504.02C69.2933 498.909 108.047 505.723 127.985 520.473C147.187 534.72 162.325 556.672 167.358 563.756C167.783 564.337 168.713 565.266 169.913 566.312C159.847 563.447 141.457 555.046 124.346 544.089Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M124.926 542.464C116.989 537.392 92.212 516.331 83.3077 508.666C79.5523 505.452 96.8578 507.078 111.647 511.26C118.499 513.195 123.416 517.531 127.984 520.086C132.979 522.835 129.301 521.325 133.559 525.235C149.781 540.102 163.021 557.795 164.686 562.092C164.918 562.711 165.886 561.976 166.97 562.944C157.679 560.35 140.722 552.568 124.926 542.464Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M124.926 542.464C116.989 537.392 92.212 516.331 83.3077 508.666C79.5523 505.452 96.8578 507.078 111.647 511.26C118.499 513.195 123.416 517.531 127.984 520.086C132.979 522.835 129.301 521.325 133.559 525.235C149.781 540.102 163.021 557.795 164.686 562.092C164.918 562.711 165.886 561.976 166.97 562.944C157.679 560.35 140.722 552.568 124.926 542.464Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M168.363 564.918L166.156 561.705C166.156 561.705 165.188 561.24 164.298 559.111C163.407 556.981 162.827 556.207 161.007 556.478C159.187 556.71 149.702 558.027 148.967 559.304C148.27 560.582 148.038 561.472 148.347 563.911C148.657 566.35 150.283 567.202 150.283 567.202L168.363 564.918Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M267.977 575.448C270.223 574.713 268.984 567.938 268.093 565.615C267.203 563.331 262.054 556.13 260.931 555.472C259.808 554.775 257.408 556.517 257.95 559.498C258.453 562.479 265.732 576.184 267.977 575.448Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M122.795 548.696C122.795 549.006 123.027 549.355 123.26 549.509L130.616 553.536C130.848 553.691 131.08 553.536 131.08 553.226L131.041 552.103C131.041 551.794 130.809 551.445 130.577 551.29L123.26 547.264C123.027 547.109 122.795 547.264 122.795 547.574V548.696Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M71.6166 538.824C71.8876 544.941 76.1849 552.22 81.1404 555.007C82.5341 555.781 83.8504 556.168 85.0893 556.13C86.3669 556.091 87.4509 555.588 88.3026 554.697C89.5028 553.381 90.0835 551.329 89.9673 548.735C89.6963 542.618 85.399 535.34 80.4435 532.553C79.0498 531.778 77.7335 531.391 76.4946 531.43C75.2171 531.468 74.133 531.972 73.2813 532.862C72.0424 534.179 71.4617 536.23 71.6166 538.824ZM81.8373 552.258C80.6371 551.6 79.437 550.555 78.3529 549.239L81.1791 545.561C81.4501 545.793 81.7211 546.025 81.9921 546.18C82.3018 546.374 82.6116 546.451 82.8826 546.49L84.8183 553.149C83.9279 553.149 82.9213 552.839 81.8373 552.258ZM79.2821 541.96C79.4369 542.812 79.7854 543.702 80.2887 544.477L77.7722 548.542C75.7591 545.909 74.404 542.58 74.0943 539.676L79.2821 541.96ZM79.2821 540.644L74.0943 538.786C74.0169 535.921 75.0622 533.985 76.8818 533.559L80.0177 539.405C79.6305 539.637 79.3595 540.102 79.2821 540.644ZM84.7796 543.702C84.6634 542.851 84.2763 541.883 83.7343 541.07L84.7021 538.127C86.5992 540.683 87.8768 543.818 88.2252 546.606L84.7796 543.702ZM84.7409 545.096L88.3026 547.69C88.38 550.555 87.3735 552.491 85.5926 552.955L83.9666 546.219C84.3537 546.025 84.6247 545.638 84.7409 545.096ZM80.5984 534.333C81.7985 535.03 83.0374 536.076 84.1214 537.392L82.8438 540.024C82.6116 539.792 82.3406 539.637 82.1083 539.482C81.7598 539.289 81.4501 539.173 81.1404 539.173L77.7722 533.482C78.5852 533.443 79.5531 533.753 80.5984 534.333Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M72.002 496.161C74.983 491.554 95.3082 478.468 95.3082 478.468C95.3082 478.468 112.227 469.757 118.343 468.402C123.763 466.892 143.856 474.79 148.541 476.958L149.431 477.384C149.431 477.384 142.076 481.372 129.88 489.231C117.724 497.09 101.503 507.775 101.503 507.775C94.6113 505.839 82.2614 504.872 75.7186 504.136C73.2021 503.865 69.7565 499.606 72.002 496.161Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M72.002 496.161C74.983 491.554 95.3082 478.468 95.3082 478.468C95.3082 478.468 112.227 469.757 118.343 468.402C123.763 466.892 143.856 474.79 148.541 476.958L149.431 477.384C149.431 477.384 142.076 481.372 129.88 489.231C117.724 497.09 101.503 507.775 101.503 507.775C94.6113 505.839 82.2614 504.872 75.7186 504.136C73.2021 503.865 69.7565 499.606 72.002 496.161Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M72.002 496.161C74.983 491.554 95.3082 478.468 95.3082 478.468C95.3082 478.468 112.227 469.757 118.343 468.402C121.015 467.667 126.783 469.37 132.474 471.577C128.138 470.106 124.189 469.215 122.137 469.757C115.982 471.112 99.1022 479.823 99.1022 479.823C99.1022 479.823 78.777 492.909 75.796 497.516C74.2474 499.916 75.4476 502.703 77.151 504.252C76.6477 504.213 76.1832 504.136 75.7186 504.097C73.2021 503.865 69.7565 499.606 72.002 496.161Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M129.88 489.231C142.036 481.372 149.43 477.384 149.43 477.384C158.103 481.256 181.912 495.812 189.036 500.652C186.751 502.549 178.854 509.285 169.369 515.712C161.122 521.325 144.63 528.255 139.713 530.772C139.713 530.772 133.906 525.661 124.808 519.932C114.161 513.234 110.019 510.911 101.502 507.775C101.502 507.775 117.723 497.09 129.88 489.231Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M112.575 524.848C114.665 520.474 117.956 514.666 117.956 514.666C118.653 515.054 119.35 515.402 120.047 515.789C120.047 515.789 117.646 522.293 116.175 526.823C115.44 529.107 114.627 531.972 114.007 534.256C112.652 533.172 111.259 532.049 109.787 530.849C110.639 528.952 111.684 526.707 112.575 524.848Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M169.371 515.712C176.649 510.795 182.998 505.646 186.56 502.704L219.429 521.287C224.462 524.81 228.759 527.868 231.585 529.997C229.843 533.482 216.215 544.748 204.949 552.065C194.613 558.84 178.894 565.731 171.229 566.97C169.68 565.654 168.364 564.453 167.861 563.718C163.912 558.182 154.93 544.399 140.373 529.959C145.367 527.481 161.124 521.364 169.371 515.712Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M209.053 449.006C209.053 448.851 209.092 448.735 209.169 448.697L214.55 445.832C214.628 445.793 214.744 445.793 214.86 445.87C215.092 446.025 215.325 446.374 215.325 446.683C215.325 446.877 215.247 446.993 215.17 447.032L209.789 449.897C209.711 449.935 209.634 449.897 209.518 449.858C209.247 449.703 209.053 449.316 209.053 449.006Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M209.129 448.774C209.207 448.735 209.284 448.735 209.4 448.813C209.632 448.929 209.826 449.277 209.826 449.548C209.826 449.703 209.787 449.819 209.671 449.858L205.103 452.374L204.561 451.29L209.129 448.774Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M198.328 454.891C198.328 454.736 198.367 454.62 198.483 454.581L204.677 451.135C204.755 451.097 204.871 451.097 204.987 451.174C205.258 451.329 205.452 451.677 205.452 451.987C205.452 452.142 205.374 452.297 205.297 452.336L199.102 455.742C199.025 455.781 198.948 455.742 198.831 455.704C198.56 455.588 198.367 455.201 198.328 454.891Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M132.088 450.439L143.354 456.865C144.554 457.562 146.489 457.562 147.69 456.865L166.737 445.986C167.937 445.29 167.937 444.205 166.737 443.509L155.471 437.082C154.271 436.385 152.335 436.385 151.135 437.082L132.088 447.961C130.887 448.619 130.887 449.742 132.088 450.439ZM133.481 448.271L151.677 437.856C152.568 437.353 154.039 437.353 154.929 437.856L165.305 443.818C166.195 444.322 166.195 445.173 165.305 445.677L147.109 456.091C146.218 456.594 144.747 456.594 143.857 456.091L133.481 450.129C132.591 449.587 132.591 448.774 133.481 448.271Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M570.725 432.398C570.88 432.94 572.119 433.985 573.513 434.798C574.906 435.572 575.835 435.611 575.603 434.875C575.371 434.14 574.132 433.056 572.816 432.475C571.538 431.933 570.609 431.894 570.725 432.398Z"
          fill="#1A1D24"
        />
        <path
          d="M274.595 540.102L249.818 525.855V520.977L274.595 535.224V540.102Z"
          fill="#1A1D24"
        />
        <path
          d="M274.595 537.043L249.818 522.796V485.166L274.595 499.451V537.043Z"
          fill="#1A1D24"
        />
        <path
          d="M274.595 542.734L249.818 528.449V522.719L274.595 536.966V542.734Z"
          fill="#1A1D24"
        />
        <path
          d="M272.621 518.035C272.621 510.834 267.937 502.278 262.168 498.909C259.536 497.4 257.135 497.245 255.316 498.174C252.915 499.18 251.406 501.968 251.406 506.11C251.406 513.311 256.09 521.867 261.858 525.197C264.491 526.707 266.891 526.861 268.711 525.932C271.111 524.964 272.621 522.177 272.621 518.035Z"
          fill="#1A1D24"
        />
        <path
          d="M271.189 516.912C271.189 522.912 267.279 525.545 262.478 522.796C257.678 520.009 253.768 512.885 253.768 506.846C253.768 500.845 257.678 498.212 262.478 500.961C267.279 503.749 271.189 510.872 271.189 516.912Z"
          fill="#1A1D24"
        />
        <path
          d="M275.021 470.648L299.799 484.934L274.595 499.452L249.818 485.166L275.021 470.648Z"
          fill="#1A1D24"
        />
        <path
          d="M299.297 554.349L274.519 540.102V535.224L299.297 549.471V554.349Z"
          fill="#1A1D24"
        />
        <path
          d="M299.297 551.329L274.519 537.043V499.451L299.297 513.698V551.329Z"
          fill="#1A1D24"
        />
        <path
          d="M299.297 556.981L274.519 542.696V536.966L299.297 551.213V556.981Z"
          fill="#1A1D24"
        />
        <path
          d="M297.323 532.32C297.323 525.119 292.638 516.563 286.87 513.195C284.237 511.685 281.837 511.53 280.017 512.46C277.617 513.466 276.107 516.254 276.107 520.396C276.107 527.597 280.791 536.192 286.56 539.521C289.192 541.031 291.593 541.186 293.412 540.257C295.813 539.211 297.323 536.424 297.323 532.32Z"
          fill="#1A1D24"
        />
        <path
          d="M295.89 531.159C295.89 537.198 291.979 539.792 287.179 537.043C282.378 534.256 278.468 527.132 278.468 521.093C278.468 515.053 282.378 512.46 287.179 515.208C291.979 517.996 295.89 525.119 295.89 531.159Z"
          fill="#1A1D24"
        />
        <path
          d="M299.723 484.933L324.5 499.18L299.297 513.698L274.519 499.451L299.723 484.933Z"
          fill="#1A1D24"
        />
        <path
          d="M299.296 556.943L300.303 556.439L300.729 557.446L324.499 543.741V499.18L299.296 513.698V556.943Z"
          fill="#1A1D24"
        />
        <path
          d="M268.866 501.542V497.671L273.202 500.148V504.02L268.866 501.542Z"
          fill="#1A1D24"
        />
        <path
          d="M293.681 516.021V512.15L298.017 514.628V518.46L293.681 516.021Z"
          fill="#1A1D24"
        />
        <path
          d="M284.972 478.197C284.972 478.197 285.823 479.784 286.288 481.798C286.753 483.811 286.327 486.211 286.327 486.211L285.785 485.824C285.785 485.824 285.63 486.327 285.049 487.256C284.352 488.379 284.004 488.573 283.888 488.573C283.694 488.534 283.152 476.92 283.152 476.92L284.972 478.197Z"
          fill="#1A1D24"
        />
        <path
          d="M283.152 476.881C283.152 476.881 284.159 479.165 284.546 482.146C284.895 485.088 283.927 488.611 283.927 488.611L283.617 488.766L282.804 477.035L283.152 476.881Z"
          fill="#1A1D24"
        />
        <path
          d="M282.805 476.997C282.805 476.997 283.811 479.281 284.199 482.262C284.547 485.204 283.579 488.727 283.579 488.727L279.785 486.405C279.785 486.405 279.514 482.417 280.521 480.017C281.566 477.655 282.805 476.997 282.805 476.997Z"
          fill="#1A1D24"
        />
        <path
          d="M274.595 540.102L249.818 525.855V520.977L274.595 535.224V540.102Z"
          fill="#1A1D24"
        />
        <path
          d="M274.595 537.043L249.818 522.796V485.166L274.595 499.451V537.043Z"
          fill="#1A1D24"
        />
        <path
          d="M274.595 542.734L249.818 528.449V522.719L274.595 536.966V542.734Z"
          fill="#1A1D24"
        />
        <path
          d="M272.621 518.035C272.621 510.834 267.937 502.278 262.168 498.909C259.536 497.4 257.135 497.245 255.316 498.174C252.915 499.18 251.406 501.968 251.406 506.11C251.406 513.311 256.09 521.867 261.858 525.197C264.491 526.707 266.891 526.861 268.711 525.932C271.111 524.964 272.621 522.177 272.621 518.035Z"
          fill="#1A1D24"
        />
        <path
          d="M271.189 516.912C271.189 522.912 267.279 525.545 262.478 522.796C257.678 520.009 253.768 512.885 253.768 506.846C253.768 500.845 257.678 498.212 262.478 500.961C267.279 503.749 271.189 510.872 271.189 516.912Z"
          fill="#1A1D24"
        />
        <path
          d="M275.021 470.648L299.799 484.934L274.595 499.452L249.818 485.166L275.021 470.648Z"
          fill="#1A1D24"
        />
        <path
          d="M299.297 554.349L274.519 540.102V535.224L299.297 549.471V554.349Z"
          fill="#1A1D24"
        />
        <path
          d="M299.297 551.329L274.519 537.043V499.451L299.297 513.698V551.329Z"
          fill="#1A1D24"
        />
        <path
          d="M299.297 556.981L274.519 542.696V536.966L299.297 551.213V556.981Z"
          fill="#1A1D24"
        />
        <path
          d="M297.323 532.32C297.323 525.119 292.638 516.563 286.87 513.195C284.237 511.685 281.837 511.53 280.017 512.46C277.617 513.466 276.107 516.254 276.107 520.396C276.107 527.597 280.791 536.192 286.56 539.521C289.192 541.031 291.593 541.186 293.412 540.257C295.813 539.211 297.323 536.424 297.323 532.32Z"
          fill="#1A1D24"
        />
        <path
          d="M295.89 531.159C295.89 537.198 291.979 539.792 287.179 537.043C282.378 534.256 278.468 527.132 278.468 521.093C278.468 515.053 282.378 512.46 287.179 515.208C291.979 517.996 295.89 525.119 295.89 531.159Z"
          fill="#1A1D24"
        />
        <path
          d="M299.723 484.933L324.5 499.18L299.297 513.698L274.519 499.451L299.723 484.933Z"
          fill="#1A1D24"
        />
        <path
          d="M299.296 556.943L300.303 556.439L300.729 557.446L324.499 543.741V499.18L299.296 513.698V556.943Z"
          fill="#1A1D24"
        />
        <path
          d="M268.866 501.542V497.671L273.202 500.148V504.02L268.866 501.542Z"
          fill="#1A1D24"
        />
        <path
          d="M293.681 516.021V512.15L298.017 514.628V518.46L293.681 516.021Z"
          fill="#1A1D24"
        />
        <path
          d="M284.972 478.197C284.972 478.197 285.823 479.784 286.288 481.798C286.753 483.811 286.327 486.211 286.327 486.211L285.785 485.824C285.785 485.824 285.63 486.327 285.049 487.256C284.352 488.379 284.004 488.573 283.888 488.573C283.694 488.534 283.152 476.92 283.152 476.92L284.972 478.197Z"
          fill="#1A1D24"
        />
        <path
          d="M283.152 476.881C283.152 476.881 284.159 479.165 284.546 482.146C284.895 485.088 283.927 488.611 283.927 488.611L283.617 488.766L282.804 477.035L283.152 476.881Z"
          fill="#1A1D24"
        />
        <path
          d="M282.805 476.997C282.805 476.997 283.811 479.281 284.199 482.262C284.547 485.204 283.579 488.727 283.579 488.727L279.785 486.405C279.785 486.405 279.514 482.417 280.521 480.017C281.566 477.655 282.805 476.997 282.805 476.997Z"
          fill="#1A1D24"
        />
        <path
          d="M274.595 540.102L249.818 525.855V520.977L274.595 535.224V540.102Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M274.595 537.043L249.818 522.796V485.166L274.595 499.451V537.043Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M274.595 542.734L249.818 528.449V522.719L274.595 536.966V542.734Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M272.621 518.035C272.621 510.834 267.937 502.278 262.168 498.909C259.536 497.4 257.135 497.245 255.316 498.174C252.915 499.18 251.406 501.968 251.406 506.11C251.406 513.311 256.09 521.867 261.858 525.197C264.491 526.707 266.891 526.861 268.711 525.932C271.111 524.964 272.621 522.177 272.621 518.035Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M271.189 516.912C271.189 522.912 267.279 525.545 262.478 522.796C257.678 520.009 253.768 512.885 253.768 506.846C253.768 500.845 257.678 498.212 262.478 500.961C267.279 503.749 271.189 510.872 271.189 516.912Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M275.021 470.648L299.799 484.934L274.595 499.452L249.818 485.166L275.021 470.648Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M299.297 554.349L274.519 540.102V535.224L299.297 549.471V554.349Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M299.297 551.329L274.519 537.043V499.451L299.297 513.698V551.329Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M299.297 556.981L274.519 542.696V536.966L299.297 551.213V556.981Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M297.323 532.32C297.323 525.119 292.638 516.563 286.87 513.195C284.237 511.685 281.837 511.53 280.017 512.46C277.617 513.466 276.107 516.254 276.107 520.396C276.107 527.597 280.791 536.192 286.56 539.521C289.192 541.031 291.593 541.186 293.412 540.257C295.813 539.211 297.323 536.424 297.323 532.32Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M295.89 531.159C295.89 537.198 291.979 539.792 287.179 537.043C282.378 534.256 278.468 527.132 278.468 521.093C278.468 515.053 282.378 512.46 287.179 515.208C291.979 517.996 295.89 525.119 295.89 531.159Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M299.723 484.933L324.5 499.18L299.297 513.698L274.519 499.451L299.723 484.933Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M299.296 556.943L300.303 556.439L300.729 557.446L324.499 543.741V499.18L299.296 513.698V556.943Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M268.866 501.542V497.671L273.202 500.148V504.02L268.866 501.542Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M293.681 516.021V512.15L298.017 514.628V518.46L293.681 516.021Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M284.972 478.197C284.972 478.197 285.823 479.784 286.288 481.798C286.753 483.811 286.327 486.211 286.327 486.211L285.785 485.824C285.785 485.824 285.63 486.327 285.049 487.256C284.352 488.379 284.004 488.573 283.888 488.573C283.694 488.534 283.152 476.92 283.152 476.92L284.972 478.197Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M283.152 476.881C283.152 476.881 284.159 479.165 284.546 482.146C284.895 485.088 283.927 488.611 283.927 488.611L283.617 488.766L282.804 477.035L283.152 476.881Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M282.805 476.997C282.805 476.997 283.811 479.281 284.199 482.262C284.547 485.204 283.579 488.727 283.579 488.727L279.785 486.405C279.785 486.405 279.514 482.417 280.521 480.017C281.566 477.655 282.805 476.997 282.805 476.997Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <g id="dryer" ref={dryerRef}>
          <path
            d="M299.296 554.349L274.519 540.102V535.224L299.296 549.47V554.349Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M299.296 551.329L274.519 537.043V499.452L299.296 513.699V551.329Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M299.296 556.981L274.519 542.695V536.966L299.296 551.213V556.981Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
          <path
            d="M297.323 532.32C297.323 525.119 292.638 516.563 286.87 513.195C284.237 511.685 281.837 511.531 280.017 512.46C277.617 513.466 276.107 516.254 276.107 520.396C276.107 527.597 280.791 536.192 286.56 539.521C289.192 541.031 291.593 541.186 293.412 540.257C295.813 539.212 297.323 536.424 297.323 532.32Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
          <path
            d="M295.89 531.159C295.89 537.199 291.98 539.792 287.179 537.044C282.378 534.256 278.468 527.133 278.468 521.093C278.468 515.054 282.378 512.46 287.179 515.209C291.98 517.996 295.89 525.12 295.89 531.159Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
          <path
            d="M299.722 484.933L324.5 499.18L299.296 513.698L274.519 499.451L299.722 484.933Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
          <path
            d="M293.681 516.021V512.15L298.017 514.627V518.46L293.681 516.021Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
          <path
            d="M299.296 556.943L300.303 556.44L300.729 557.446L324.5 543.741V499.181L299.296 513.699V556.943Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
        </g>
        <g id="washer" ref={washerRef}>
          <path
            d="M274.595 540.101L249.818 525.854V520.976L274.595 535.223V540.101Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
          <path
            d="M274.595 537.044L249.818 522.797V485.166L274.595 499.452V537.044Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
          <path
            d="M274.595 542.734L249.818 528.448V522.719L274.595 536.966V542.734Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
          <path
            d="M272.621 518.035C272.621 510.834 267.937 502.278 262.168 498.91C259.536 497.4 257.136 497.245 255.316 498.174C252.916 499.181 251.406 501.968 251.406 506.111C251.406 513.312 256.09 521.867 261.859 525.197C264.491 526.707 266.892 526.862 268.711 525.933C271.112 524.965 272.621 522.177 272.621 518.035Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
          <path
            d="M271.189 516.912C271.189 522.912 267.279 525.545 262.478 522.796C257.677 520.009 253.767 512.885 253.767 506.846C253.767 500.845 257.677 498.212 262.478 500.961C267.279 503.749 271.189 510.872 271.189 516.912Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
          <path
            d="M275.021 470.648L299.799 484.934L274.595 499.452L249.818 485.166L275.021 470.648Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
          <path
            d="M268.867 501.542V497.671L273.203 500.149V504.02L268.867 501.542Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeLinejoin="bevel"
          />
        </g>
        <path
          d="M525.119 368.209L643.895 300.264V369.873L525.119 438.089V368.209Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M643.857 272.235L799.297 361.627V459.343L643.857 369.951V272.235Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M645.561 271.383L643.857 272.235L799.297 361.627V461.782L801 460.93V360.775L645.561 271.383Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M796.045 418.654L643.896 330.965V300.264L796.045 387.953V418.654Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M690.354 358.414L667.087 371.848L643.819 358.414L548.891 413.157L525.623 399.761L620.552 345.019L643.819 331.585L690.354 358.414Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M525.623 398.135L548.891 411.53V413.156L525.623 399.761V398.135Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M525.623 438.011L548.891 451.406V413.156L525.623 399.761V438.011Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M702.006 391.941L725.08 405.259V443.47L702.006 430.191V391.941Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M702.006 391.941L725.08 405.259V414.976L702.006 401.658V391.941Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M702.006 410.95L725.08 424.268V433.985L702.006 420.667V410.95Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M702.006 390.354V391.941L725.08 405.259L748.347 391.863L725.273 378.546L702.006 390.354Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M690.354 356.827L667.087 370.222L643.819 356.827L548.891 411.53L525.623 398.135L620.552 343.393L643.819 329.997L690.354 356.827Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M548.891 413.156L643.819 358.414V396.664L606.382 418.228V381.333L571.5 401.465V438.36L548.891 451.368V413.156Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M548.891 413.156L643.819 358.414V396.664L606.382 418.228V381.333L571.5 401.465V438.36L548.891 451.368V413.156Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M643.818 358.414L667.086 371.848V410.059L643.818 396.664V358.414Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M643.818 367.938L667.086 381.333V391.05L643.818 377.616V367.938Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M643.818 386.947L667.086 400.342V410.059L643.818 396.664V386.947Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M702.006 390.353L725.08 403.671L748.347 390.237L725.273 376.919L702.006 390.353Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M714.859 353.923L737.507 366.892L748.928 360.311L726.241 347.342L714.859 353.923Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M714.859 353.923L715.053 338.166L726.435 331.585L726.241 347.342L714.859 353.923Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M724.577 349.2C724.577 349.51 724.152 349.781 723.61 349.781C723.068 349.781 722.603 349.51 722.603 349.2C722.603 348.89 723.029 348.619 723.61 348.619C724.113 348.58 724.577 348.851 724.577 349.2Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M714.859 354.039L737.507 367.009L748.928 360.427L726.241 347.458L714.859 354.039Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M714.859 345.483L737.507 358.453L748.928 351.871L726.241 338.902L714.859 345.483Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M714.859 345.096L737.507 358.027L748.928 351.445L726.241 338.514L714.859 345.096Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M725.698 341.534H722.408V346.025C722.408 346.528 723.143 346.916 724.072 346.916C725.002 346.916 725.737 346.528 725.737 346.025L725.698 341.534Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M725.698 341.534C725.698 342.076 724.963 342.541 724.033 342.541C723.104 342.541 722.369 342.115 722.369 341.534C722.369 340.992 723.104 340.528 724.033 340.528C724.963 340.566 725.698 340.992 725.698 341.534Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M713.506 335.65L738.477 350.052L750.207 343.277L725.275 328.836L713.506 335.65Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M713.506 335.65V353.923L738.477 368.364V350.09L713.506 335.65ZM715.713 338.476L736.967 350.748V365.266L715.713 352.994V338.476Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="oven" ref={ovenRef}>
          <path
            d="M702.006 430.191L667.085 410.059V401.542L702.006 421.674V430.191Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M667.085 370.222L702.006 390.353L725.273 376.919L690.352 356.826L667.085 370.222Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M689.966 352.8L689.153 357.523L721.132 375.99L722.603 375.138L722.951 371.112L689.966 352.8Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M667.085 370.222L702.006 390.353L703.438 389.502L668.556 369.37L667.085 370.222Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M689.966 352.8L721.944 371.228L722.602 370.725L690.624 352.297L689.966 352.8Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M702.006 421.751L667.085 401.62V370.222L702.006 390.354V421.751Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M666.544 374.364L666.505 374.325L667.976 373.512L699.954 391.941V392.328L698.483 393.18L666.505 374.751V374.364H666.544Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M696.819 398.29L671.19 383.501V396.935L696.819 411.724V398.29Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M671.731 396.741V383.811L696.819 398.29V411.221L671.731 396.741Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M704.174 372.777C701.774 374.171 701.774 376.416 704.174 377.81C706.575 379.204 710.485 379.204 712.885 377.81C715.285 376.416 715.285 374.171 712.885 372.777C710.485 371.422 706.575 371.422 704.174 372.777Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M694.689 378.429C692.521 379.668 692.521 381.72 694.689 382.959C696.857 384.198 700.38 384.198 702.548 382.959C704.716 381.72 704.716 379.668 702.548 378.429C700.38 377.19 696.857 377.19 694.689 378.429Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M697.631 380.133C697.089 380.443 697.089 380.946 697.631 381.256C698.173 381.565 699.064 381.565 699.606 381.256C700.148 380.946 700.148 380.443 699.606 380.133C699.064 379.823 698.173 379.823 697.631 380.133Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M707.465 374.674C706.884 375.023 706.884 375.565 707.465 375.913C708.084 376.262 709.052 376.262 709.633 375.913C710.214 375.565 710.214 375.023 709.633 374.674C709.013 374.326 708.046 374.326 707.465 374.674Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M671.266 369.215C671.034 369.331 671.034 369.564 671.266 369.719L673.511 371.074C673.744 371.19 674.092 371.19 674.324 371.074L684.081 365.499C684.313 365.344 684.313 365.15 684.081 364.995L681.835 363.64C681.603 363.485 681.254 363.485 681.022 363.64L671.266 369.215Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M682.107 371.383C679.939 372.622 679.939 374.674 682.107 375.913C684.275 377.152 687.798 377.152 689.967 375.913C692.135 374.674 692.135 372.622 689.967 371.383C687.798 370.144 684.275 370.144 682.107 371.383Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M685.05 373.087C684.508 373.396 684.508 373.9 685.05 374.21C685.592 374.519 686.482 374.519 687.024 374.21C687.566 373.9 687.566 373.396 687.024 373.087C686.482 372.777 685.592 372.777 685.05 373.087Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M688.533 360.93L702.78 361.008L702.741 368.093C702.741 369.138 702.044 370.183 700.651 370.996C697.863 372.584 693.372 372.584 690.585 370.996C689.191 370.183 688.494 369.138 688.494 368.093L688.533 360.93Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M690.354 357.833C693.257 356.169 698.019 356.169 700.923 357.833C702.471 358.724 703.207 359.924 703.091 361.085C703.168 362.247 702.471 363.447 700.923 364.337C698.019 366.002 693.257 366.002 690.354 364.337C688.805 363.447 688.07 362.247 688.186 361.085C688.108 359.924 688.805 358.724 690.354 357.833Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M694.614 359.265C694.033 359.614 694.033 360.117 694.614 360.466C695.194 360.814 696.124 360.814 696.666 360.466C697.246 360.117 697.246 359.614 696.666 359.265C696.124 358.917 695.194 358.917 694.614 359.265Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M597.748 339.25L552.607 365.266L547.07 362.053L547.109 355.278L586.366 332.669L597.748 339.25Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M557.949 350.942L558.104 350.826L557.949 350.71C557.717 350.555 557.717 350.323 557.949 350.168L562.827 347.264C563.06 347.109 563.447 347.109 563.718 347.264L569.486 350.748C569.719 350.903 569.719 352.452 569.486 352.607L564.608 355.51C564.376 355.665 563.989 355.665 563.718 355.51L557.949 352.026C557.717 351.871 557.717 351.639 557.949 351.484L558.027 351.445L557.949 351.407C557.717 351.329 557.717 351.097 557.949 350.942Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M550.324 354.581L550.478 354.465L550.324 354.349C550.091 354.194 550.091 353.962 550.324 353.807L556.092 350.322C556.324 350.168 556.711 350.168 556.982 350.322L561.86 353.226C562.093 353.381 561.783 357.02 561.59 357.175L556.092 360.504C555.86 360.659 555.473 360.659 555.24 360.504L550.595 357.717C550.401 357.601 550.13 356.052 550.207 355.588C550.091 355.433 550.091 355.278 550.324 355.162L550.401 355.123L550.324 355.084C550.091 354.968 550.091 354.697 550.324 354.581Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M597.748 339.25L597.593 322.913L586.211 316.331L586.366 332.669L597.748 339.25Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M573.901 337.431C573.746 336.192 574.52 334.875 576.185 333.946C579.282 332.165 584.276 332.165 587.335 333.946C588.999 334.914 589.774 336.192 589.619 337.431C589.657 337.624 589.657 337.818 589.619 338.011C589.657 338.205 589.657 338.398 589.619 338.592C589.774 339.831 588.999 341.147 587.335 342.076C584.237 343.857 579.243 343.857 576.185 342.076C574.52 341.108 573.746 339.831 573.901 338.592C573.862 338.398 573.862 338.205 573.901 338.011C573.901 337.779 573.901 337.624 573.901 337.431Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M597.748 330.81L552.607 356.826L541.225 350.245L586.366 324.229L597.748 330.81Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M597.748 330.423L552.607 356.439L541.225 349.858L586.366 323.842L597.748 330.423Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M558.492 341.418L558.647 341.302L558.492 341.186C558.26 341.031 558.26 340.799 558.492 340.644L563.37 337.74C563.602 337.585 563.989 337.585 564.26 337.74L570.029 341.224C570.261 341.379 569.951 345.019 569.758 345.135L565.112 347.922C564.88 348.077 564.493 348.077 564.299 347.922L558.763 344.631C558.569 344.515 558.337 343.005 558.453 342.541C558.26 342.386 558.26 342.192 558.492 342.076L558.569 342.038L558.492 341.999C558.26 341.805 558.26 341.573 558.492 341.418Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M546.142 344.283L546.375 344.128L546.142 343.973C545.794 343.78 545.794 343.431 546.142 343.238L552.995 339.134C553.343 338.94 553.885 338.94 554.234 339.134L562.403 344.012C562.751 344.206 562.325 349.355 562.015 349.509L555.473 353.42C555.163 353.613 554.621 353.613 554.311 353.42L546.53 348.774C546.259 348.619 545.949 346.49 546.104 345.793C545.833 345.599 545.871 345.29 546.181 345.096L546.297 345.019L546.181 344.941C545.794 344.825 545.794 344.477 546.142 344.283Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M552.336 347.845V366.118L598.871 339.289V321.015L552.336 347.845ZM576.339 335.572L596.161 324.151V338.669L576.339 350.09V335.572ZM554.272 348.348L574.094 336.927V351.445L554.272 362.866V348.348Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M552.336 347.845V366.118L598.871 339.289V321.015L552.336 347.845ZM576.339 335.572L596.161 324.151V338.669L576.339 350.09V335.572ZM554.272 348.348L574.094 336.927V351.445L554.272 362.866V348.348Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M598.872 321.016L552.336 347.845L540.606 341.07L587.102 314.24L598.872 321.016Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M552.336 365.924L540.606 359.111V341.07L552.336 347.845V365.924Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="toaster" ref={toasterRef}>
          <path
            d="M659.381 351.213V345.754L673.821 342.309V347.806C673.86 347.961 673.744 348.58 673.55 348.697L664.375 354.194C664.027 354.388 663.446 354.388 663.097 354.194L659.613 352.103C659.458 351.987 659.381 351.368 659.381 351.213Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M659.653 345.367C659.305 345.561 659.305 345.909 659.653 346.141L663.138 348.232C663.486 348.426 664.067 348.426 664.415 348.232L673.591 342.734C673.939 342.541 673.939 342.192 673.591 341.96L670.106 339.87C669.758 339.676 669.177 339.676 668.829 339.87L659.653 345.367Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M663.447 346.064L664.415 346.645L672.003 342.076L671.035 341.496L663.447 346.064Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M661.548 344.902L662.516 345.483L670.104 340.954L669.136 340.373L661.548 344.902Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M526.397 344.089C526.397 342.192 527.597 339.986 529.107 339.134C529.804 338.747 530.423 338.708 530.888 338.94C531.507 339.211 531.894 339.947 531.894 340.992C531.894 342.851 530.694 345.096 529.184 345.948C528.487 346.335 527.868 346.374 527.403 346.141C526.784 345.87 526.397 345.135 526.397 344.089Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M527.869 344.438C527.869 345.986 528.875 346.683 530.153 345.948C531.392 345.212 532.437 343.354 532.437 341.805C532.437 340.257 531.431 339.56 530.153 340.295C528.875 341.031 527.869 342.889 527.869 344.438Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M570.377 437.082L549.975 448.851V413.776L570.377 402.007V437.082Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="stand-mixer" ref={mixerRef}>
          <path
            d="M636.619 341.379L646.607 335.379C647.149 335.03 648.039 335.03 648.62 335.379L649.394 335.843C649.665 335.766 649.936 335.727 650.207 335.65C650.207 333.714 650.207 331.081 650.207 330.578C650.207 329.765 649.975 329.533 649.588 329.726C648.272 330.268 645.291 331.507 645.174 331.546C644.671 331.662 644.09 331.585 643.665 331.352L642.658 330.733C642.426 330.578 642.31 330.423 642.271 330.23C642.271 330.152 642.271 328.023 642.271 328.023L656.053 322.603V325.545C656.053 325.623 656.053 325.7 656.053 325.739L656.402 336.424C656.479 336.463 656.557 336.501 656.634 336.54C658.608 337.702 658.996 339.482 657.873 340.876L658.608 341.341C659.15 341.689 659.15 342.696 658.608 343.005L648.62 349.006C648.078 349.355 647.188 349.355 646.607 349.006L645.6 348.425C645.058 348.077 645.058 347.07 645.6 346.761L651.756 343.083C651.64 343.083 651.098 343.509 650.943 343.509C649.704 343.393 648.543 343.005 647.613 342.463C646.452 341.767 645.833 340.876 645.755 339.986L639.6 343.664C639.058 344.012 638.167 344.012 637.586 343.664L636.58 343.044C636.038 342.734 636.038 341.728 636.619 341.379Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M637.934 336.85C637.934 340.837 640.257 345.793 645.096 345.793C649.936 345.793 652.259 340.837 652.259 336.85C652.22 332.862 637.934 332.862 637.934 336.85Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M640.025 334.024C637.237 335.611 637.237 338.244 640.025 339.831C642.812 341.418 647.303 341.418 650.09 339.831C652.878 338.244 652.878 335.611 650.09 334.024C647.303 332.398 642.812 332.398 640.025 334.024Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M644.631 336.424V334.333H645.599V336.424C645.947 336.617 646.218 336.966 646.218 337.392V339.056C646.218 339.637 645.715 340.14 645.134 340.14C644.554 340.14 644.05 339.637 644.05 339.056V337.392C644.05 336.966 644.283 336.579 644.631 336.424Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M643.586 331.352C643.973 331.585 644.593 331.662 645.096 331.546C645.173 331.546 646.18 331.12 647.264 330.656C647.264 331.159 646.916 332.824 646.916 332.824C646.916 333.056 646.761 333.249 646.528 333.443C646.451 333.83 646.412 334.256 646.412 334.256C646.412 334.45 646.296 334.604 646.064 334.759C645.599 335.069 644.786 335.069 644.322 334.759C644.089 334.604 643.973 334.45 643.973 334.256C643.973 334.256 643.896 333.791 643.818 333.443C643.586 333.249 643.47 333.056 643.47 332.824C643.47 332.785 643.122 331.469 643.122 331.043L643.586 331.352Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M642.58 327.597C642.038 327.946 642.038 328.449 642.58 328.797L643.587 329.417C644.129 329.765 645.019 329.765 645.6 329.417L655.589 323.416C656.131 323.068 656.131 322.564 655.589 322.216L654.582 321.635C654.04 321.287 653.149 321.287 652.569 321.635L642.58 327.597Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M635.959 329.223L639.056 327.442V321.248L635.959 323.029V329.223Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M635.959 329.223V323.029L639.056 321.248V327.442L635.959 329.223Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M633.83 305.994L634.14 290.818L645.677 297.554L645.444 300.613L633.83 305.994Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M632.166 306.536L677.307 332.553L682.843 329.339L682.805 322.564L643.548 299.955L632.166 306.536Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M632.166 306.652L677.307 332.669L688.689 326.087L643.548 300.071L632.166 306.652Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M666.545 320.202C666.545 320.048 666.545 319.931 666.545 319.777C666.429 318.809 667.009 317.841 668.287 317.067C670.648 315.712 674.442 315.712 676.804 317.067C678.082 317.802 678.662 318.77 678.546 319.738C678.546 319.893 678.546 320.009 678.546 320.164C678.546 320.319 678.546 320.435 678.546 320.59C678.546 320.744 678.546 320.861 678.546 321.015C678.662 321.983 678.082 322.951 676.804 323.687C674.442 325.042 670.648 325.042 668.287 323.687C667.009 322.951 666.429 321.983 666.545 321.015C666.545 320.861 666.545 320.744 666.545 320.59C666.506 320.512 666.506 320.357 666.545 320.202Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M632.166 298.096L677.307 324.113L688.689 317.531L643.548 291.515L632.166 298.096Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M632.166 297.709L677.307 323.725L688.689 317.144L643.548 291.128L632.166 297.709Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M632.088 288.689V306.962L678.623 333.791V315.518L632.088 288.689ZM656.865 304.6L676.687 316.021V330.539L656.865 319.118V304.6ZM634.798 291.863L654.62 303.284V317.802L634.798 306.381V291.863Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M667.317 312.576C667.317 312.421 667.317 312.305 667.317 312.15C667.201 311.182 667.782 310.214 669.059 309.478C671.421 308.123 675.215 308.123 677.577 309.478C678.854 310.214 679.435 311.182 679.319 312.15C679.319 312.305 679.319 312.421 679.319 312.576C679.319 312.73 679.319 312.847 679.319 313.001C679.319 313.156 679.319 313.272 679.319 313.427C679.435 314.395 678.854 315.363 677.577 316.099C675.215 317.454 671.421 317.454 669.059 316.099C667.782 315.363 667.201 314.395 667.317 313.427C667.317 313.272 667.317 313.156 667.317 313.001C667.317 312.847 667.317 312.73 667.317 312.576Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M659.343 308.162C659.343 308.085 659.343 308.007 659.343 307.93C659.343 307.853 659.343 307.775 659.343 307.698C659.266 307.156 659.614 306.575 660.35 306.149C661.705 305.375 663.873 305.375 665.228 306.149C665.964 306.575 666.273 307.117 666.235 307.698C666.235 307.775 666.235 307.853 666.235 307.93C666.235 308.007 666.235 308.085 666.235 308.162C666.235 308.201 666.273 308.24 666.273 308.278C666.273 310.214 664.725 311.763 662.789 311.763C660.892 311.763 659.305 310.214 659.305 308.278C659.305 308.24 659.305 308.201 659.343 308.162Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M648.232 300.38C649.819 299.49 652.374 299.49 653.923 300.38C654.774 300.884 655.162 301.542 655.084 302.2C655.084 302.277 655.123 302.355 655.084 302.471C655.084 302.549 655.084 302.665 655.084 302.742C655.084 302.82 655.084 302.936 655.084 303.013C655.162 303.671 654.774 304.329 653.923 304.794C652.335 305.684 649.78 305.684 648.232 304.794C647.38 304.291 646.993 303.633 647.07 303.013C647.07 302.897 647.07 302.82 647.07 302.742C647.07 302.626 647.07 302.549 647.07 302.471C647.07 302.394 647.07 302.316 647.07 302.2C646.993 301.542 647.38 300.845 648.232 300.38Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M632.088 288.689V306.962L678.623 333.791V315.518L632.088 288.689ZM656.865 304.6L676.687 316.021V330.539L656.865 319.118V304.6ZM634.798 291.863L654.62 303.284V317.802L634.798 306.381V291.863Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M632.088 288.689L678.623 315.518L690.353 308.743L643.818 281.914L632.088 288.689Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="microwave" ref={microwaveRef}>
          <path
            d="M713.545 354.001V358.22L716.6 356.2"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M713.505 354L678.623 333.908L677.655 337.624L713.505 358.22V354Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M678.623 315.518V333.792L713.505 353.923V335.65L678.623 315.518ZM686.327 322.526L711.414 337.005V349.936L686.327 335.456V322.526Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M679.747 331.894V319.002L684.703 321.829V334.721L679.747 331.894Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M680.61 324.378V320.778L683.401 322.2V325.8L680.61 324.378Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M725.274 328.836L690.354 308.743L678.623 315.518L713.505 335.65L725.274 328.836Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M712.305 351.794V336.385L685.553 320.861V336.54L712.305 351.794Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M627.868 404.175L607.465 415.944V380.907L627.868 369.138V404.175Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M628.951 403.594L642.85 395.812V360.775L628.951 368.518V403.594Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="dishwasher" ref={dishwasherRef}>
          <path
            d="M571.499 438.36L606.38 418.228V408.898L571.499 429.029V438.36Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M571.499 429.107L606.38 408.975V381.217L571.499 401.31V429.107Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M586.561 405.646L577.424 410.911V403.013L586.561 397.748V405.646Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M573.241 405.22L605.258 386.753L606.69 387.605V387.992L574.712 406.459L573.241 405.607V405.22Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M588.03 410.95L578.893 416.215V403.516L588.03 398.251V410.95Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M586.561 397.4L577.424 402.665L578.895 403.478L588.032 398.213L586.561 397.4Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
        </g>
        <path
          d="M536.076 380.946V374.752L539.174 372.971V379.126L536.076 380.946Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="kitchen-sink" ref={kitchenSinkRef}>
          <path
            d="M591.167 365.46C590.973 365.576 590.973 365.731 591.167 365.847L606.498 374.713C606.691 374.829 606.962 374.829 607.156 374.713L638.515 356.633C638.708 356.517 638.708 356.362 638.515 356.246L623.184 347.38C622.99 347.264 622.719 347.264 622.526 347.38L591.167 365.46Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M595.115 368.828C595.115 368.828 606.846 375.681 607.039 375.565L636.23 358.917L624.616 352.22C624.422 352.104 624.151 352.104 623.958 352.22L595.115 368.828Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M612.963 363.95C611.531 364.763 611.531 366.079 612.963 366.931C614.395 367.744 616.68 367.744 618.112 366.931C619.545 366.118 619.545 364.802 618.112 363.95C616.718 363.137 614.395 363.137 612.963 363.95Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M614.239 364.995C613.543 365.421 613.543 366.079 614.239 366.467C614.936 366.892 616.098 366.892 616.833 366.467C617.53 366.041 617.53 365.383 616.833 364.995C616.136 364.608 614.975 364.608 614.239 364.995Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M587.295 365.266L606.033 376.068C606.498 376.339 607.195 376.339 607.659 376.068L640.954 356.865C641.38 356.594 641.38 356.207 640.954 355.936L622.216 345.135C621.79 344.864 621.055 344.864 620.59 345.135L587.295 364.298C586.831 364.608 586.831 364.995 587.295 365.266ZM591.167 365.46L622.526 347.38C622.719 347.264 622.99 347.264 623.184 347.38L638.515 356.246C638.709 356.362 638.709 356.517 638.515 356.633L607.156 374.713C606.962 374.829 606.691 374.829 606.498 374.713L591.167 365.847C590.973 365.731 590.973 365.576 591.167 365.46Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M597.011 349.742C597.011 349.703 596.972 349.703 597.011 349.742V347.303H599.914V349.703V355.743V359.343C599.914 359.537 599.76 359.769 599.489 359.924C598.908 360.233 598.017 360.233 597.437 359.924C597.166 359.769 597.011 359.537 597.011 359.343V356.13V349.742Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M598.251 351.368L598.29 351.329C598.716 351.368 599.142 351.29 599.452 351.097C599.8 350.903 599.916 350.632 599.839 350.4L599.878 350.361C599.878 350.361 599.878 350.361 601.039 351.058C602.2 351.755 607.543 348.813 610.098 350.4C612.653 351.987 612.731 351.987 612.769 352.026C613.118 352.22 613.195 352.491 613.079 352.723L613.157 352.762V354C613.157 354.155 613.041 354.349 612.808 354.465C612.305 354.736 611.531 354.736 611.027 354.465C610.795 354.349 610.679 354.155 610.64 354V353.807C610.64 353.807 609.711 352.878 608.511 352.181C605.994 350.671 600.613 353.575 599.452 352.839C598.252 352.103 598.251 352.103 598.251 352.103V351.368Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M594.187 350.632L597.322 348.851C597.361 348.813 597.4 348.813 597.477 348.851C597.593 348.929 597.71 349.122 597.71 349.277C597.71 349.355 597.71 349.393 597.671 349.432C597.671 349.432 594.574 351.213 594.535 351.213C594.496 351.252 594.419 351.252 594.341 351.213C594.225 351.135 594.109 350.942 594.109 350.787C594.109 350.71 594.148 350.632 594.187 350.632Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M597.398 346.722C596.817 347.032 596.817 347.574 597.398 347.883C597.979 348.193 598.869 348.193 599.45 347.883C600.031 347.574 600.031 347.032 599.45 346.722C598.908 346.374 597.979 346.374 597.398 346.722Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
        </g>
        <g id="fridge" ref={fridgeRef}>
          <path
            d="M718.73 397.864C718.691 399.839 727.015 406.188 738.629 412.885C751.482 420.28 763.523 425.351 765.536 424.19C765.574 424.151 765.613 424.151 765.613 424.113C765.652 424.074 765.729 424.074 765.768 424.035V468.944C765.729 468.983 765.652 468.983 765.613 469.022C765.574 469.06 765.536 469.06 765.536 469.099C763.523 470.26 751.482 465.189 738.629 457.794C727.092 451.135 718.807 444.864 718.73 442.812C718.73 442.579 718.73 397.67 718.73 397.864Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M796.818 459.227L765.808 477.074V476.029V387.914L796.818 370.067V459.227Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M719.505 451.523L765.808 477.074V473.28L719.505 447.729V451.523Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M750.283 343.238L796.818 370.067L765.807 387.953C765.769 387.992 765.691 387.992 765.653 388.031C765.614 388.069 765.575 388.069 765.575 388.108C763.562 389.269 751.522 384.198 738.668 376.803C725.815 369.409 717.027 362.44 719.04 361.279C719.079 361.24 719.117 361.24 719.156 361.24C719.195 361.201 719.234 361.201 719.272 361.163L750.283 343.238Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M733.171 373.474C734.874 374.558 736.733 375.642 738.63 376.765C751.483 384.159 763.523 389.231 765.536 388.069C765.575 388.031 765.614 388.031 765.614 387.992C765.653 387.953 765.73 387.953 765.769 387.914V446.877C765.73 446.916 765.575 446.993 765.536 447.032C763.523 448.193 751.483 443.121 738.63 435.727C736.694 434.604 734.874 433.52 733.171 432.475V373.474Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M734.526 392.56L734.952 392.289L736.346 393.102V430.655L735.92 430.926V430.849V430.926L734.526 430.075V392.87V392.56Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M718.73 361.705C718.691 363.369 724.498 368.054 733.17 373.474V432.436C724.576 427.094 718.807 422.409 718.73 420.706C718.73 420.512 718.73 361.511 718.73 361.705Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M729.687 389.773L729.725 389.734L730.112 389.463L731.506 390.276V427.829L731.119 428.1V427.984V428.1L729.725 427.287V390.044L729.687 389.773Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M718.73 421.48C718.691 423.455 727.015 429.804 738.629 436.501C751.482 443.896 763.523 448.968 765.536 447.806C765.574 447.767 765.729 447.69 765.768 447.651V474.248C765.729 474.287 765.652 474.287 765.613 474.326C765.574 474.364 765.536 474.364 765.536 474.403C763.523 475.565 751.482 470.493 738.629 463.098C727.092 456.439 718.807 450.168 718.73 448.116C718.73 447.961 718.73 421.287 718.73 421.48Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M720.394 427.597L721.4 426.861C724.73 429.804 730.963 434.101 738.667 438.515C743.468 441.263 748.113 443.702 752.217 445.599C754.811 446.761 756.631 447.574 756.631 447.574V447.961L755.624 448.697C750.707 446.645 744.281 443.47 737.66 439.637C729.995 435.224 723.762 430.965 720.394 427.984V427.597Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M754.347 423.726C750.708 422.1 746.45 420.009 742.036 417.531V397.129C746.488 399.568 750.708 401.697 754.347 403.323V423.726Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M743.546 402.781C746.798 404.562 749.934 406.11 752.76 407.427V421.441C749.934 420.125 746.798 418.576 743.546 416.796V402.781Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M752.799 406.226C749.973 404.91 746.837 403.361 743.585 401.581V399.18C746.837 400.961 749.973 402.51 752.799 403.826V406.226Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M745.288 415.363V403.71C747.921 405.104 750.476 406.343 752.799 407.427V419.196C750.437 418.073 747.921 416.796 745.288 415.363Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M733.132 373.474C723.376 367.395 717.298 362.246 719.001 361.279C719.04 361.24 719.079 361.24 719.117 361.24L736.771 371.422L733.132 373.474Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M736.81 371.344L765.691 387.992H765.653C765.614 388.03 765.575 388.03 765.575 388.069C763.562 389.231 751.522 384.159 738.668 376.764C736.733 375.642 734.874 374.558 733.171 373.474L736.81 371.344Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
        </g>
        <path
          d="M126.707 243.973V259.15L118.306 263.989L129.147 270.261V245.367L126.707 243.973Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M563.641 249.277C562.789 249.277 562.092 248.58 562.092 247.728V241.534C562.092 240.682 562.789 239.986 563.641 239.986C564.492 239.986 565.189 240.682 565.189 241.534V247.728C565.189 248.58 564.492 249.277 563.641 249.277Z"
          fill="#1A1D24"
        />
        <path
          d="M337.547 200.574L338.36 198.368L189.463 113.002L188.65 115.208L337.547 200.574Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M376.531 176.416L377.344 175.874L228.448 90.5082L227.635 91.0502L376.531 176.416Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M415.48 154.349L416.293 153.807L267.396 68.4797L266.622 68.983L415.48 154.349Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M454.465 131.856L455.278 131.314L306.382 45.9865L305.569 46.4898L454.465 131.856Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M493.451 109.479L494.226 108.937L345.329 23.6094L344.555 24.1127L493.451 109.479Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M529.144 88.8435L182.416 288.224V290.818L529.144 91.4374V88.8435Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="ac-duct" ref={acDuctRef}>
          <path
            d="M333.947 207.194L307.389 222.448V233.869L333.947 218.615V207.194Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M332.592 209.75L308.744 223.493V225.197L332.592 211.492V209.75Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M332.592 210.988L308.744 224.693V226.435L332.592 212.692V210.988Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M332.592 212.188L308.744 225.932V227.636L332.592 213.931V212.188Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M332.592 213.428L308.744 227.171V228.875L332.592 215.131V213.428Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M332.592 214.628L308.744 228.372V230.114L332.592 216.37V214.628Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M332.592 215.866L308.744 229.61V231.313L332.592 217.57V215.866Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M332.592 217.105L308.744 230.81V231.314L332.592 217.57V217.105Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M194.109 128.371V152.607L272.429 197.748V173.513L194.109 128.371Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M272.428 197.748L292.172 186.327V162.092L272.428 173.513V197.748Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M328.294 202.549L312.305 193.412L287.373 207.775L303.246 216.912L328.294 202.549Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M275.682 183.888C275.682 179.204 278.701 173.706 282.457 171.538C284.625 170.299 286.522 170.454 287.761 171.693C289.89 172.777 291.942 173.745 293.916 174.713C305.647 180.326 317.726 186.172 318.268 203.013C318.423 203.439 318.539 203.904 318.539 204.368C318.539 207.659 314.087 210.369 308.55 210.369C303.053 210.369 298.601 207.698 298.601 204.368C298.601 204.059 298.639 203.749 298.717 203.439C298.562 199.413 297.091 197.98 285.438 192.367C283.502 191.438 281.334 190.392 279.05 189.231C277.037 188.921 275.682 186.985 275.682 183.888Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M194.109 128.371L272.429 173.513L292.174 162.092L213.854 116.95L194.109 128.371Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M609.787 161.201L628.487 171.964V174.752L609.787 163.989V161.201Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M629.377 174.21V171.422L610.678 160.659L609.787 161.201L628.487 171.964V174.752L629.377 174.21Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M625.777 174.635C627.103 174.635 628.177 173.561 628.177 172.235C628.177 170.909 627.103 169.835 625.777 169.835C624.451 169.835 623.377 170.909 623.377 172.235C623.377 173.561 624.451 174.635 625.777 174.635Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M620.861 171.732C622.187 171.732 623.262 170.657 623.262 169.332C623.262 168.006 622.187 166.931 620.861 166.931C619.536 166.931 618.461 168.006 618.461 169.332C618.461 170.657 619.536 171.732 620.861 171.732Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M615.906 168.828C617.232 168.828 618.307 167.754 618.307 166.428C618.307 165.102 617.232 164.028 615.906 164.028C614.581 164.028 613.506 165.102 613.506 166.428C613.506 167.754 614.581 168.828 615.906 168.828Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M610.988 166.041C612.314 166.041 613.388 164.966 613.388 163.64C613.388 162.315 612.314 161.24 610.988 161.24C609.662 161.24 608.588 162.315 608.588 163.64C608.588 164.966 609.662 166.041 610.988 166.041Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="electric-meter" ref={electricMeterRef}>
          <path
            d="M93.4898 373.783L84.3145 368.479V354.542L93.4898 359.846V373.783Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M87.6439 352.684L84.3145 354.542L93.4898 359.846L96.8193 357.949L87.6439 352.684Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M96.8197 371.887L93.4902 373.745V359.846L96.8197 357.949V371.887Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M92.7923 365.382C92.7923 362.711 91.0502 359.537 88.9209 358.298C87.953 357.756 87.0626 357.678 86.3657 358.027C85.4753 358.414 84.8945 359.42 84.8945 360.969C84.8945 363.64 86.6367 366.815 88.766 368.054C89.7339 368.596 90.6243 368.673 91.3212 368.325C92.2116 367.938 92.7923 366.892 92.7923 365.382Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M90.7801 365.383C90.7801 367.628 89.3477 368.596 87.5668 367.551C85.786 366.505 84.3535 363.873 84.3535 361.666C84.3535 359.421 85.786 358.453 87.5668 359.498C89.3477 360.543 90.7801 363.176 90.7801 365.383Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M90.5852 356.014C90.9723 356.014 91.3208 355.704 91.3208 355.278V238.36C91.3208 237.972 91.011 237.624 90.5852 237.624C90.198 237.624 89.8496 237.934 89.8496 238.36V355.278C89.8496 355.704 90.1593 356.014 90.5852 356.014Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M625.972 247.148C625.43 247.148 625.004 246.722 625.004 246.18V242.308C625.004 240.257 624.191 238.011 623.456 237.276C622.256 236.075 621.326 233.907 621.326 232.32V229.92C621.326 229.378 621.752 228.952 622.294 228.952C622.836 228.952 623.262 229.378 623.262 229.92V232.32C623.262 233.404 623.998 235.069 624.811 235.882C626.011 237.082 626.94 239.869 626.94 242.27V246.141C626.94 246.722 626.514 247.148 625.972 247.148Z"
          fill="#1A1D24"
        />
        <path
          d="M544.786 205.259L544.592 207.311L546.179 208.704V205.065L544.786 205.259Z"
          fill="#1A1D24"
        />
        <g id="bathroom-sink" ref={bathroomSinkRef}>
          <path
            d="M619.469 227.635L621.133 257.523L612.19 262.633C611.997 262.75 611.726 262.75 611.532 262.633L598.021 254.852L600.537 224.964L619.469 227.635Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M584.393 216.486L603.286 209.672C603.673 209.44 604.292 209.44 604.641 209.672L633.135 222.099C633.522 222.332 633.289 227.558 632.902 227.791L614.242 238.476C613.855 238.708 613.235 238.708 612.887 238.476L584.625 222.177C584.238 221.983 584.044 216.718 584.393 216.486Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M627.792 223.764C627.947 223.842 627.947 223.996 627.792 224.074L614.745 231.584C614.59 231.662 614.358 231.662 614.203 231.584L587.568 216.253C587.413 216.176 587.413 216.021 587.568 215.944L600.614 208.433C600.769 208.356 601.002 208.356 601.156 208.433L627.792 223.764Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M624.463 227.055C624.463 227.055 614.513 232.862 614.358 232.785L590.549 219.235L600.421 213.543C600.576 213.466 600.808 213.466 600.963 213.543L624.463 227.055Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M607.583 225.08C608.357 225.506 608.357 226.242 607.583 226.668C606.809 227.094 605.608 227.094 604.834 226.668C604.06 226.242 604.06 225.506 604.834 225.08C605.608 224.655 606.847 224.655 607.583 225.08Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M632.941 221.828L604.679 205.53C604.292 205.297 603.672 205.297 603.324 205.53L584.625 216.215C584.237 216.447 584.237 216.796 584.625 216.989L612.886 233.288C613.273 233.52 613.893 233.52 614.241 233.288L632.902 222.603C633.328 222.37 633.328 222.022 632.941 221.828ZM627.791 224.074L614.745 231.584C614.59 231.662 614.357 231.662 614.203 231.584L587.567 216.253C587.412 216.176 587.412 216.021 587.567 215.944L600.614 208.433C600.769 208.356 601.001 208.356 601.156 208.433L627.791 223.764C627.946 223.842 627.946 223.996 627.791 224.074Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M608.861 206.343V204.291H606.422V206.304V207.272V210.33C606.422 210.524 606.538 210.679 606.77 210.833C607.235 211.104 608.009 211.104 608.512 210.833C608.745 210.679 608.861 210.524 608.861 210.33V207.62V206.343Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M611.261 207.117L608.589 205.607C608.551 205.568 608.512 205.568 608.473 205.607C608.357 205.684 608.28 205.839 608.28 205.955C608.28 205.994 608.28 206.072 608.318 206.072C608.318 206.072 610.951 207.581 610.99 207.581C611.028 207.62 611.067 207.62 611.144 207.581C611.261 207.504 611.338 207.349 611.338 207.233C611.338 207.194 611.299 207.117 611.261 207.117Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M602.472 209.711V208.085L607.737 206.459V207.388L605.105 209.053L604.795 210.137L604.021 210.601C603.905 210.679 603.75 210.679 603.634 210.601L602.588 209.982C602.472 209.943 602.472 209.75 602.472 209.711Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M602.55 207.968C602.434 208.046 602.434 208.123 602.55 208.201L603.595 208.82C603.711 208.898 603.866 208.898 603.982 208.82L607.66 206.575C607.776 206.497 607.776 206.42 607.66 206.342L606.615 205.723C606.499 205.646 606.344 205.646 606.228 205.723L602.55 207.968Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M607.312 224.848C607.931 225.197 607.931 225.777 607.312 226.126C606.692 226.474 605.725 226.474 605.105 226.126C604.486 225.777 604.486 225.197 605.105 224.848C605.725 224.5 606.692 224.5 607.312 224.848Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M608.512 203.787C608.977 204.058 608.977 204.523 608.512 204.794C608.048 205.065 607.235 205.065 606.77 204.794C606.306 204.523 606.306 204.058 606.77 203.787C607.274 203.516 608.048 203.516 608.512 203.787Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M627.405 217.725C627.405 217.454 627.753 217.221 628.218 217.221C628.682 217.221 629.031 217.454 629.031 217.725V221.054C629.031 221.325 628.682 221.557 628.218 221.557C627.753 221.557 627.405 221.325 627.405 221.054V217.725Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M627.755 215.789C627.755 215.634 627.948 215.518 628.219 215.518C628.451 215.518 628.684 215.634 628.684 215.789V217.221C628.684 217.376 628.49 217.492 628.219 217.492C627.987 217.492 627.755 217.376 627.755 217.221V215.789Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
        </g>
        <path
          d="M525.854 195.812L524.189 196.78V294.573L525.854 293.644V195.812Z"
          fill="#1A1D24"
          stroke="#F5F4F5"
          strokeOpacity="0.4"
          strokeWidth="0.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <g id="toilet" ref={toiletRef}>
          <path
            d="M549.626 242.541C549.626 242.541 552.143 235.03 545.871 230.617C539.599 226.203 566.428 218.538 566.428 218.538L569.409 220.009L572.39 234.953C572.39 234.953 572.584 235.417 572.236 235.611C571.887 235.766 559.344 243.315 555.859 245.328C554.853 245.909 554.311 245.406 554.311 245.406L549.626 242.541Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M579.551 199.529L573.047 203.4L572.272 225.081L578.273 221.558L579.551 199.529Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M552.646 191.166L554.155 214.24L572.274 225.08L573.048 203.4L552.646 191.166Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M543.741 216.137C530.501 224.655 544.748 233.172 552.723 232.243C568.944 230.346 569.758 216.641 563.834 213.273C562.518 212.537 560.272 212.382 558.259 212.15C553.459 211.608 547.884 213.466 543.741 216.137Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M543.703 215.247C537.586 218.925 536.734 224.384 541.806 227.403C546.877 230.462 555.937 229.92 562.053 226.242C568.17 222.564 569.022 217.144 563.95 214.086C558.879 211.066 549.82 211.569 543.703 215.247Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M563.603 212.653C558.686 209.711 549.898 210.214 543.936 213.776C538.012 217.338 537.161 222.603 542.077 225.584C546.994 228.526 555.782 228.023 561.744 224.461C567.707 220.899 568.52 215.596 563.603 212.653Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
          <path
            d="M581.023 197.632C581.023 197.516 580.946 197.4 580.829 197.322L560.93 185.359C560.659 185.204 560.233 185.204 559.962 185.359L552.645 189.773C552.49 189.889 552.413 190.005 552.452 190.121H552.413C552.413 190.121 552.452 191.36 552.452 191.438C552.452 191.554 552.529 191.67 552.645 191.747L572.544 203.71C572.815 203.865 573.241 203.865 573.551 203.71L580.868 199.297C580.984 199.219 581.062 199.103 581.062 198.987C581.023 198.871 581.023 197.632 581.023 197.632Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
          />
        </g>
        <path
          d="M472.544 171.848C472.544 169.951 471.344 167.744 469.834 166.892C469.137 166.505 468.518 166.466 468.053 166.699C467.434 166.97 467.047 167.705 467.047 168.751C467.047 170.609 468.247 172.854 469.757 173.706C470.454 174.093 471.073 174.132 471.538 173.9C472.157 173.667 472.544 172.932 472.544 171.848Z"
          fill="#1A1D24"
        />
        <path
          d="M471.074 172.235C471.074 173.784 470.068 174.48 468.79 173.745C467.551 173.009 466.506 171.151 466.506 169.602C466.506 168.054 467.512 167.357 468.79 168.093C470.068 168.828 471.074 170.686 471.074 172.235Z"
          fill="#1A1D24"
        />
        <g id="roof" ref={roofRef}>
          <path
            d="M413.854 79.3972V85.398L302.123 149.161V143.199L413.854 79.3972Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M413.854 81.1008V87.0629L302.123 150.826V144.864L413.854 81.1008Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M529.145 88.8436L533.21 86.3659L384.313 1L380.248 3.47774L529.145 88.8436Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M355.781 88.6113L529.145 88.8436L380.249 3.47775L206.885 3.28418L355.781 88.6113Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M355.781 87.3338L368.634 87.2177L219.738 1.85181L206.885 1.96795L355.781 87.3338Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M346.527 99.2965V97.8254L355.78 87.3337L368.633 87.2175V88.65L529.144 88.8435L526.473 90.9341L356.477 90.7018L186.016 286.985L182.416 288.224L346.527 99.2965Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M327.017 50.4773L354.66 50.516L330.889 36.8884H303.247L327.017 50.4773Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M312.227 31.275L329.222 41.0311L327.945 47.419L306.342 35.0303L312.227 31.275Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M329.224 41.0311H349.046L332.011 31.275H312.228L329.224 41.0311Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M329.223 41.031H349.045L353.11 47.4576L327.945 47.4189L329.223 41.031Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M182.418 288.224L355.783 88.6113L206.886 3.28418L33.5217 202.858L182.418 288.224Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M178.506 290.547L182.417 288.224L33.5199 202.858L29.6484 205.181L178.506 290.547Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M346.528 97.8255L355.781 87.3339L206.884 1.96802L197.631 12.4984L346.528 97.8255Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M185.708 255.936L323.106 97.7866L205.104 30.1521L67.7441 188.302L185.708 255.936Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            d="M185.127 253.265L319.777 98.29L204.136 32.0105L69.5254 186.985L185.127 253.265Z"
            fill="#1A1D24"
            stroke="#F5F4F5"
            strokeOpacity="0.4"
            strokeWidth="0.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ visible: number }>`
  position: absolute;
  transform: scale(0.84);
  transform-origin: 100% 10%;
  top: 3.9vw;
  right: 4.4vw;
  z-index: 0;
  width: 55.7vw;
  height: 46.9vw;
  opacity: ${(props) => (props.visible === 0 ? 1 : 0)};
  transition: opacity 0.5s;
  g path {
    fill: inherit;
    stroke: inherit;
    stroke-opacity: inherit;
  }
  #kitchen-floor path {
    fill: ${Colors.gunMetal100};
    stroke: #f5f4f5;
    stroke-opacity: 0.4;
  }
  #roof path {
    fill: ${Colors.gunMetal100};
    stroke: #f5f4f5;
    stroke-opacity: 0.4;
  }

  ${media.mobile} {
    top: 0vw;
    left: 0w;
    right: unset;
    width: 100%;
    position: relative;

    .house {
      position: absolute;
      top: 12vw;
      left: -8vw;
      width: 100%;
      z-index: 0;
    }
  }
`;

const WaterLine = styled(WaterLineSVG)`
  position: absolute;
  right: 0;
  bottom: 3.8vw;
  stroke-dasharray: 75% 270%;
  stroke-dashoffset: 75%;
  width: 33.2vw;
  height: 29.4vw;

  ${media.mobile} {
    top: 31vw;
    bottom: unset;
    right: unset;
    left: 23vw;
    width: 46.3vw;
    height: auto;
    z-index: 1;
  }
`;

const ElectricLine = styled(ElectricLineSVG)`
  position: absolute;
  right: 1.3vw;
  bottom: 2.4vw;
  stroke-dasharray: 10% 10% 10% 10% 10% 345%;
  stroke-dashoffset: 50%;
  width: 49.7vw;
  height: 32vw;

  ${media.mobile} {
    top: 29.5vw;
    left: -1.5vw;
    width: 68.5vw;
    height: auto;
    bottom: unset;
    right: unset;
    z-index: 1;
  }
`;

export default HouseAnimation;
