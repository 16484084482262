import React from "react";
import styled from "styled-components";
import Colors from "styles/Colors";
import media from "styles/media";
import HeadingText from "components/HeadingText";

import { ReactComponent as CommLightSVG } from "assets/svg/WarrantyCommLight.svg";
import { ReactComponent as CommDarkSVG } from "assets/svg/WarrantyCommDark.svg";

const Integrations: React.FC<{}> = () => {
  return (
    <Wrapper id="integrations">
      <Heading>
        <HeadingText
          headingColor={Colors.gunMetal100}
          headingSize={1}
          headingText={"The Source7 API is easy to integrate into any application"}
        />
      </Heading>
      <ImagesWrapper>
        <CommDark />
        <CommLight />
      </ImagesWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  background: ${Colors.bone80};
  padding: 14.5vw 30vw 14.7vw 24.6vw;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  ${media.mobile} {
    padding: 18.9vw 16.5vw 108.8vw 18.9vw;
  }
`;

const Heading = styled.div`
  margin-bottom: 3.3vw;
  width: 29vw;

  ${media.mobile} {
    width: 90%;
  }
`;

const ImagesWrapper = styled.div`
  position: absolute;
  height: 29vw;
  width: 29vw;
  right: 11vw;
  top: 10vw;

  ${media.mobile} {
    top: 68vw;
    height: 80vw;
    width: 67vw;
    right: 13vw;
  }
`;

const CommLight = styled(CommLightSVG)`
  position: absolute;
  height: auto;
  width: 15vw;
  bottom: 0vw;
  right: 0vw;

  path {
    stroke: #7b7b7d;
  }

  ${media.mobile} {
    height: auto;
    width: 40vw;
    bottom: 0vw;
    right: 0vw;
  }
`;

const CommDark = styled(CommDarkSVG)`
  position: absolute;
  height: auto;
  width: 15vw;
  top: 0vw;
  left: 0vw;

  ${media.mobile} {
    height: auto;
    width: 40vw;
    top: 0vw;
    left: 0vw;
  }
`;

export default Integrations;
