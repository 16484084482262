import styled from "styled-components";
import Colors from "styles/Colors";
import media from "styles/media";
import { Link } from "react-router-dom";

export const mobile = {
  H1: `
    font-style: normal;
    font-weight: 500;
    font-size: 8.7vw;
    line-height: 110%;
    letter-spacing: -0.02em;
  `,

  H2: `
    font-style: normal;
    font-weight: 500;
    font-size: 8.5vw;
    line-height: 110%;
    letter-spacing: -0.02em;
  `,

  H2small: `
    font-style: normal;
    font-weight: 500;
    font-size: 5.5vw;
    line-height: 110%;
    letter-spacing: -0.02em;
  `,

  H3: `
    font-style: normal;
    font-weight: 500;
    font-size: 7.5vw;
    line-height: 110%;
    letter-spacing: -0.02em;
  `,

  H4: ` 
    font-style: normal;
    font-weight: normal;
    font-size: 6.4vw;
    line-height: 110%;
    letter-spacing: -0.02em;
  `,

  H5: `
    font-style: normal;
    font-weight: normal;
    font-size: 4.8vw;
    line-height: 110%;
    /* identical to box height, or 20px */
    letter-spacing: -0.02em;
  `,

  Sub1: `
    font-style: normal;
    font-weight: 500;
    font-size: 3.2vw;
    line-height: 110%;
    letter-spacing: -0.02em;
  `,

  Body1: `
    font-size: 4vw;
    font-weight: 300;
    
  `,

  Body: `
    font-style: normal;
    font-weight: normal;
    font-size: 3.7vw;
    line-height: 150%;
    /* identical to box height, or 21px */
    letter-spacing: -0.02em;
  `,
};

export const Heading1 = styled.h1`
  color: ${Colors.gunMetal100};
  font: 5.6vw Roobert; //80px
  line-height: 110%;
  letter-spacing: -0.02em;

  ${media.mobile} {
    ${mobile.H1}
  }
`;

export const Heading2 = styled.h2`
  color: ${Colors.gunMetal100};
  font: 4.4vw Roobert; //64px
  line-height: 110%;
  letter-spacing: -0.02em;

  ${media.mobile} {
    ${mobile.H2}
  }
`;

export const Heading2Small = styled.h2`
  color: ${Colors.gunMetal100};
  font: 3.6vw Roobert; //64px
  line-height: 110%;
  letter-spacing: -0.02em;

  ${media.mobile} {
    ${mobile.H2small}
  }
`;

export const Heading3 = styled.h3`
  color: ${Colors.gunMetal100};
  font: 3.3vw Roobert; //48px
  line-height: 120%;
  letter-spacing: -0.02em;

  ${media.mobile} {
    ${mobile.H3}
  }
`;

export const Heading4 = styled.h4`
  color: ${Colors.gunMetal100};
  font: 2.5vw Roobert; //36px
  line-height: 110%;
  letter-spacing: -0.02em;

  ${media.mobile} {
    font-size: 5.5vw;
  }
`;

export const Heading5 = styled.h5`
  color: ${Colors.gunMetal100};
  font: 1.7vw Roobert; //24px;
  line-height: 110%;
  letter-spacing: -0.02em;

  ${media.mobile} {
    font-size: 4.8vw;
  }
`;

export const Subtitle1 = styled.h6`
  color: ${Colors.gunMetal100};
  font: 18px Roobert;
  line-height: 100%;

  ${media.mobile} {
    font-size: 3vw;
  }
`;

export const Subtitle2 = styled.h6`
  color: ${Colors.gunMetal100};
  font: 600 14px Roobert;
  line-height: 100%;
  letter-spacing: -0.04em;
`;

export const BodyCopy1 = styled.p`
  color: ${Colors.gunMetal100};
  font: 1.3vw Roobert;
  line-height: 150%;

  ${media.mobile} {
    ${mobile.Body1}
  }
  ${media.fullWidth} {
    font-size: 1.3vw;
  }
`;

export const BodyCopy2 = styled.p`
  color: ${Colors.gunMetal100};
  font: 16px Roobert;
  line-height: 150%;
  font-weight: 100;

  ${media.mobile} {
    font-size: 4.3vw;
    font-weight: 300;
  }
  ${media.fullWidth} {
    font-size: 1.1vw;
  }
`;

export const MenuItem = styled(Link)`
  color: ${Colors.gunMetal100};
  font: 1.2vw Roobert;
  line-height: 100%;
`;

export const MenuItemSmall = styled(Link)`
  color: ${Colors.gunMetal100};
  font: 0.8vw "Roobert";
  line-height: 100%;
  text-decoration: none;
`;

export const ButtonR = styled.span`
  color: ${Colors.gunMetal100};
  font: 1.2vw Roobert;
  line-height: 100%;
  letter-spacing: -0.02rem;
  ${media.fullWidth} {
    font-size: 1.2vw;
  }
`;

export const ButtonL = styled.span`
  color: ${Colors.gunMetal100};
  font: 23px Roobert;
  line-height: 100%;
  letter-spacing: -0.02em;
  ${media.fullWidth} {
    font-size: 1.6vw;
  }
`;
